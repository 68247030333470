import type { AxiosResponse } from 'axios';

import { instance } from '.';

export interface CreateScoresParams {
  scores: Pick<
    Score,
    | 'community_of_interest_score'
    | 'education_score'
    | 'lived_experience_score'
    | 'professional_experience_score'
    | 'score_type'
    | 'total_score'
  >[];
  send_email: boolean;
  user_info_id: string;
}

interface CreateScoresResponse extends Score {}

export interface GetScoresResponse extends Score {}

interface Score {
  badge_criteria_id: string;
  community_of_interest_score: number;
  created_at: string;
  earned_at: string;
  education_score: number;
  id: string;
  image: {
    url: string | null;
  };
  is_active: boolean;
  lived_experience_score: number;
  professional_experience_score: number;
  score_level: string;
  score_type: ScoreType;
  total_score: number;
  user_info_id: string;
}

export const createScores = (user_info_id: string, params: CreateScoresParams) =>
  instance
    .post<
      ApiResponse<CreateScoresResponse[]>,
      AxiosResponse<ApiResponse<CreateScoresResponse[]>>,
      CreateScoresParams
    >(`/admin/user_infos/${user_info_id}/scores`, params)
    .then(res => res.data);

export const getScores = (
  page?: string,
  pageSize?: string,
  { user_info_id, ...getParams }: { user_info_id?: string } = {}
) =>
  instance
    .get<ApiResponse<GetScoresResponse[], IndexPageMeta>>(
      `/admin/user_infos/${user_info_id}/scores`,
      {
        params: {
          ...getParams,
          page,
          per_page: pageSize
        }
      }
    )
    .then(res => res.data);
