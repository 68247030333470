import { ToastContainer } from 'react-toastify';

export const ErrorToast = () => (
  <ToastContainer
    autoClose={7000}
    closeOnClick
    draggable
    hideProgressBar={false}
    newestOnTop={false}
    pauseOnFocusLoss
    pauseOnHover
    position="bottom-center"
    rtl={false}
  />
);
