import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, MenuProps, Row, Space } from 'antd';
import { ReactNode } from 'react';

interface StyledTitleProps {
  actionsColumns?: MenuProps['items'];
  buttonsContents?: ReactNode | ReactNode[];
  title: JSX.Element | string;
}

export const StyledTitle = ({ actionsColumns, buttonsContents, title }: StyledTitleProps) => (
  <Row style={{ height: '70px', width: '100%' }}>
    <Col
      flex="1"
      style={{
        alignItems: 'center',
        display: 'flex',
        paddingLeft: 15
      }}
    >
      <div style={{ color: '#757575', fontSize: 'xx-large' }}>{title}</div>{' '}
    </Col>

    {buttonsContents !== undefined && (
      <Space>
        {(Array.isArray(buttonsContents) ? buttonsContents : [buttonsContents]).map(
          (content, index) => (
            <Col
              key={index}
              flex="0.08"
            >
              <Button
                size="large"
                type="primary"
              >
                {content}
              </Button>
            </Col>
          )
        )}
      </Space>
    )}

    {actionsColumns !== undefined && (
      <Dropdown menu={{ items: actionsColumns }}>
        <Button
          size="large"
          style={{
            position: 'relative',
            top: '50%',
            transform: 'translateY(-50%)'
          }}
          type="primary"
        >
          Actions <EllipsisOutlined />
        </Button>
      </Dropdown>
    )}
  </Row>
);
