import { instance } from '.';

export interface GetPathwayCompletionsResponse {
  completed_at: string;
  created_at: string;
  id: string;
  last_progress_at: string;
  pathway: {
    id: string;
    name: string;
  };
  pathway_id: string;
  started_at: string;
  total_completed_resources: number;
  updated_at: string;
  user: {
    email: string;
    first_name: string;
    id: string;
    last_name: string;
  };
  user_id: string;
}

export const getPathwayCompletions = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetPathwayCompletionsResponse[], IndexPageMeta>>(
      '/admin/pathway_completions',
      {
        params: {
          ...getParams,
          page,
          per_page: pageSize
        }
      }
    )
    .then(res => res.data);
