import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  InfoCircleTwoTone,
  MinusCircleOutlined,
  PlusOutlined,
  WarningTwoTone
} from '@ant-design/icons';
import { Button, Checkbox, Col, Form, FormProps, Row, Select, Space, Tooltip } from 'antd';
import { GetCourseResponse, getOrganizations, getTags } from 'api';
import {
  InputForm,
  InputItem,
  SearchInputItem,
  SelectItem,
  SwitchItem,
  TagsInputItem
} from 'components/forms';
import { ChangeEventHandler, MouseEventHandler, useState } from 'react';

import { FinancialDisclosures } from '../../components/forms/items/financial_disclosures';

interface CourseFormProps {
  initialData?: GetCourseResponse;
  name: string;
  onFinish: NonNullable<FormProps['onFinish']>;
}

const { Item } = Form;

export const CourseForm = ({ initialData, name, onFinish }: CourseFormProps) => {
  const [showMore, setShowMore] = useState(false);
  const [scormcloudId, setScormcloudId] = useState(initialData?.scormcloud_id ?? '');

  let scormcloudDisabled = false;
  let linkDisabled = false;

  if (initialData !== undefined) {
    if (initialData.bipoc_communities.length > 0) {
      initialData.bipoc_communities = ['BIPOC'];
    }
    if (initialData.lgbq_communities.length > 0) {
      initialData.lgbq_communities = ['LGBQ'];
    }
    if (initialData.tgnc_communities.length > 0) {
      initialData.tgnc_communities = ['TGNC'];
    }
    if (initialData.scormcloud_id !== null) {
      scormcloudDisabled = true;
    }
    if (initialData.url !== null && initialData.scormcloud_id !== null) {
      // Only disable for scormcloud courses
      linkDisabled = true;
    }
  }

  const toggleShowMore: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    setShowMore(!showMore);
  };

  const updateScormcloudId: ChangeEventHandler<HTMLInputElement> = e => {
    setScormcloudId(e.target.value);
  };

  return (
    <InputForm
      initialValues={initialData ?? { organization_name: 'Violet' }}
      name={name}
      onFinish={onFinish}
    >
      <SwitchItem
        label="Publish"
        name="published"
      />
      <InputItem
        label="Full title"
        name="full_title"
        required
      />
      <InputItem
        label="Short title"
        name="short_title"
        required
      />
      <InputItem
        label="Author"
        name="author"
      />
      <InputItem
        label="Publishing organization"
        name="organization_name"
        required
      />
      <InputItem
        label="Description"
        name="description"
        required
        textArea
      />
      {(linkDisabled || scormcloudDisabled) && (
        <div className="center-item">
          <div className="warning-block">
            <WarningTwoTone
              className="warning-icon"
              twoToneColor="orange"
            />
            Editing the {linkDisabled && 'link'} {linkDisabled && scormcloudDisabled && 'and '}
            {scormcloudDisabled && 'Scormcloud ID'} is disabled.{' '}
            <button
              className="text-btn"
              onClick={toggleShowMore}
            >
              {showMore ? 'Show less.' : 'Tell me more.'}
              {showMore ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            </button>
            {showMore && (
              <div className="more-details">
                A users' progress and completion of a course is tracked by the scormcloud ID. If the
                scormcloud ID or URL is changed, the association between a user and their course
                progress and completion breaks. If you need to change the link or scormcloud ID,
                please contact the Violet product or engineering team for guidance.
              </div>
            )}
          </div>
        </div>
      )}
      <InputItem
        disabled={linkDisabled}
        label="Link"
        name="link"
        required
      />

      {scormcloudId.length < 6 && (
        <div className="center-item">
          <div className="info-block">
            <InfoCircleTwoTone
              className="info-icon"
              twoToneColor="#1971c3"
            />
            <strong style={{ marginRight: '0.25rem' }}>Is this Scormcloud ID correct?</strong>
            <span style={{ fontWeight: 'normal', paddingLeft: '1.5rem' }}>
              Scormcloud IDs are 6 characters long. {scormcloudId.length} characters detected.
              Please double-check for any missing numbers.
            </span>
          </div>
        </div>
      )}

      {scormcloudId.length > 6 && (
        <div className="center-item">
          <div className="info-block">
            <InfoCircleTwoTone
              className="info-icon"
              twoToneColor="#1971c3"
            />
            <strong style={{ marginRight: '0.25rem' }}>Is this Scormcloud ID correct?</strong>
            <span style={{ fontWeight: 'normal', paddingLeft: '1.5rem' }}>
              Scormcloud IDs are 6 characters long. {scormcloudId.length} characters detected.
              Please double-check there aren't any extra zeros, spaces, or numbers.
            </span>
          </div>
        </div>
      )}

      {scormcloudId.includes(' ') && (
        <div className="center-item">
          <div className="info-block">
            <InfoCircleTwoTone
              className="info-icon"
              twoToneColor="#1971c3"
            />
            <strong style={{ marginRight: '0.25rem' }}>
              There is a space in the Scormcloud ID.
            </strong>
            <span style={{ fontWeight: 'normal', paddingLeft: '1.5rem' }}>
              Please remove it before saving. Scormcloud IDs should only contain numbers.
            </span>
          </div>
        </div>
      )}
      <InputItem
        disabled={scormcloudDisabled}
        label="Scormcloud ID"
        name="scormcloud_id"
        onChange={updateScormcloudId}
      />

      <SelectItem
        label="Content medium"
        name="content_medium"
        required
      >
        <Select.Option value="Video">Video</Select.Option>
        <Select.Option value="e-learning">e-learning</Select.Option>
        <Select.Option value="Written">Written</Select.Option>
        <Select.Option value="Live">Live</Select.Option>
        <Select.Option value="Audio">Audio</Select.Option>
      </SelectItem>
      <SelectItem
        label="Format category"
        name="format_category"
        required
      >
        <Select.Option value="course">Course</Select.Option>
        <Select.Option value="guide">Guide</Select.Option>
        <Select.Option value="case_vignette">Case vignette</Select.Option>
        <Select.Option value="publication">Publication</Select.Option>
        <Select.Option value="discussion">Discussion</Select.Option>
      </SelectItem>
      <SwitchItem
        label="Group_training"
        name="group_training"
      />
      <TagsInputItem
        getMethod={getTags}
        getMethodArgs={['professional_groups']}
        label="Professional groups"
        name="professional_groups"
      />
      <Row>
        <Col
          span={6}
          style={{
            paddingRight: '0.5rem',
            paddingTop: '0.55rem',
            textAlign: 'right'
          }}
        >
          Communities:
        </Col>
        <Item name="bipoc_communities">
          <Checkbox.Group options={['BIPOC']} />
        </Item>
        <Item name="lgbq_communities">
          <Checkbox.Group options={['LGBQ']} />
        </Item>
        <Item name="tgnc_communities">
          <Checkbox.Group options={['TGNC']} />
        </Item>
      </Row>
      <InputItem
        label="Year"
        name="year"
      />
      <InputItem
        label="CE provider"
        name="ce_provider"
      />
      <TagsInputItem
        getMethod={getTags}
        getMethodArgs={['ce_accrediting_associations']}
        label="CE accrediting associations"
        name="ce_accrediting_associations"
      />
      <InputItem
        label="Hours"
        name="hours"
        required
      />
      <InputItem
        label="CE credits"
        name="ce_credits"
        required
      />
      <SelectItem
        label="CE credit types"
        mode="multiple"
        name="ce_credit_types"
      >
        <Select.Option value="N/A">N/A</Select.Option>
        <Select.Option value="Unknown">Unknown</Select.Option>
        <Select.Option value="CE">CE</Select.Option>
        <Select.Option value="CME">CME</Select.Option>
        <Select.Option value="CME(AMA PRA 1)">CME(AMA PRA 1)</Select.Option>
        <Select.Option value="CNE">CNE</Select.Option>
        <Select.Option value="CNE (AANP)">CNE (AANP)</Select.Option>
        <Select.Option value="CNE (ANCC)">CNE (ANCC)</Select.Option>
        <Select.Option value="Other">Other</Select.Option>
      </SelectItem>

      <Item label="Learning objectives">
        <Form.List name="learning_objectives">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  align="baseline"
                  style={{ display: 'flex', marginBottom: 0 }}
                >
                  <InputItem
                    {...restField}
                    name={[name, 'name']}
                  />
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}

              <Item>
                <Button
                  block
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                  type="dashed"
                >
                  Add learning objective
                </Button>
              </Item>
            </>
          )}
        </Form.List>
      </Item>

      <Item label="External accreditation references">
        <Form.List name="external_accreditation_references">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  align="baseline"
                  style={{ display: 'flex', marginBottom: 0 }}
                >
                  <InputItem
                    {...restField}
                    label="Vendor name"
                    name={[name, 'vendor_name']}
                  />
                  <InputItem
                    {...restField}
                    label="Resource ID"
                    name={[name, 'vendor_resource_id']}
                  />
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}

              <Item>
                <Button
                  block
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                  type="dashed"
                >
                  Add external reference
                </Button>
              </Item>
            </>
          )}
        </Form.List>
      </Item>

      <Row>
        <Col
          span={6}
          style={{
            paddingRight: '0.5rem',
            paddingTop: '0.55rem',
            textAlign: 'right'
          }}
        >
          Access restriction:
          <Tooltip title="This course will only be visible to active members in the selected organization and will not appear in searches or the all education page.">
            <InfoCircleTwoTone style={{ marginLeft: '0.5rem' }} />
          </Tooltip>
        </Col>
        <SearchInputItem
          aria-label="Organization"
          getMethod={getOrganizations}
          getMethodArgs={[1, 20]}
          name="organization_id"
          paramsMethod={obj => ({
            key: obj.id,
            name: obj.name,
            value: obj.id
          })}
          required
          selectProps={{
            labelInValue: true
          }}
        />
      </Row>

      <FinancialDisclosures form={Form} />
    </InputForm>
  );
};
