import { PlusOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { getLicenseTiers } from 'api';
import { StyledTitle } from 'components/cards';
import { DataTable } from 'components/tables';
import { Link } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { licenseTierColumns } from './license_tier_components';

export const LicenseTiersPage = () => (
  <Card
    bordered={false}
    title={
      <StyledTitle
        buttonsContents={
          <Link
            key="2"
            to={paths.licenseTierCreatePath}
          >
            <PlusOutlined /> Create License Tier
          </Link>
        }
        title="License Tiers"
      />
    }
  >
    <DataTable
      columns={licenseTierColumns}
      getMethod={getLicenseTiers}
      perPage={15}
    />
  </Card>
);
