import { DeleteOutlined, DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { Form, FormInstance, Select, Spin } from 'antd';
import {
  getCourseCollections,
  GetCourseCollectionsResponse,
  getCourses,
  GetCoursesResponse
} from 'api';
import { useDebounce } from 'components/debounce';
import { useEffect, useReducer } from 'react';
import styled from 'styled-components';

interface ResourceFormState {
  fetchMethod?: () =>
    | Promise<ApiResponse<GetCourseCollectionsResponse[], IndexPageMeta>>
    | Promise<ApiResponse<GetCoursesResponse[], IndexPageMeta>>;
  optionsData: { name: string; value: string }[];
  reloading: boolean;
  resourceType?: 'Course' | 'CourseCollection';
}

type ResourceFormAction =
  | {
      data: GetCourseCollectionsResponse[] | GetCoursesResponse[];
      type: 'LOADED_DATA';
    }
  | {
      resource_type: 'Course' | 'CourseCollection';
      type: 'UPDATE_RESOURCE_TYPE';
    }
  | {
      type: 'SET_NAME_VALUE';
      value?: string;
    };

const bookmarkReducer = (
  state: ResourceFormState,
  action: ResourceFormAction
): ResourceFormState => {
  switch (action.type) {
    case 'UPDATE_RESOURCE_TYPE':
      return {
        fetchMethod: () =>
          action.resource_type === 'Course'
            ? getCourses('1', '20', {})
            : getCourseCollections('1', '20', {}),
        optionsData: [],
        reloading: true,
        resourceType: action.resource_type
      };
    case 'SET_NAME_VALUE':
      return {
        ...state,
        fetchMethod: () =>
          state.resourceType === 'Course'
            ? getCourses('1', '20', { title: action.value })
            : getCourseCollections('1', '20', { name: action.value }),
        optionsData: [],
        reloading: true
      };
    case 'LOADED_DATA':
      return {
        ...state,
        optionsData:
          state.resourceType === 'Course'
            ? action.data.map(course => ({
                name: (course as GetCoursesResponse).short_title,
                value: course.id
              }))
            : action.data.map(collection => ({
                name: (collection as GetCourseCollectionsResponse).name,
                value: collection.id
              })),
        reloading: false
      };
    default:
      return state;
  }
};

const ActionsContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  margin-left: 10px;

  svg {
    width: 1rem;
    height: auto;
    fill: #b961cd;
  }

  .delete svg {
    fill: #ff4d4f;
  }

  .disabled svg {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const ResourceSelectionForm = ({
  move,
  name,
  page,
  parentForm,
  remove,
  totalItemLength
}: PathwayResourceFormProps) => {
  const [state, dispatch] = useReducer(bookmarkReducer, {
    optionsData: [],
    reloading: false
  });

  const [curlNameValue, setCurNameValue] = useDebounce<string | undefined>('', 500);

  useEffect(() => {
    if (!state.resourceType) return;
    dispatch({ type: 'SET_NAME_VALUE', value: curlNameValue });
  }, [curlNameValue, state.resourceType]);

  useEffect(() => {
    const resourceType =
      page === 'create'
        ? parentForm.getFieldsValue().resources[name]?.resource_type
        : parentForm.getFieldsValue().resources.at(name)?.resource_type;
    if (!state.resourceType && Boolean(resourceType)) {
      state.resourceType = resourceType;
    }
  }, [name, page, parentForm, state]);

  // Refetch options if state says so
  useEffect(() => {
    if (!state.reloading) return;
    if (!state.fetchMethod) return;

    state.fetchMethod().then(result => dispatch({ data: result.data!, type: 'LOADED_DATA' }));
  }, [state, state.reloading]);

  const updateResourceIdHandler = (newResourceId: string) => {
    parentForm.setFieldValue(['resources', name, 'resource_id'], newResourceId);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ width: '100%' }}>
        <Item
          label="Type"
          name={[name, 'resource_type']}
        >
          <Select<'Course' | 'CourseCollection'>
            onChange={resourceType => {
              parentForm.setFieldValue(['resources', name, 'resource_id'], undefined);
              dispatch({
                resource_type: resourceType,
                type: 'UPDATE_RESOURCE_TYPE'
              });
            }}
          >
            <Select.Option value="CourseCollection">Collection</Select.Option>
            <Select.Option value="Course">Course</Select.Option>
          </Select>
        </Item>

        <Item
          label="Name:"
          name={[name, 'resource_id']}
        >
          <Select
            allowClear
            disabled={!state.resourceType}
            filterOption={() => true}
            notFoundContent={state.reloading ? <Spin size="small" /> : undefined}
            onChange={updateResourceIdHandler}
            onSearch={name => setCurNameValue(name)}
            showSearch
          >
            {state.optionsData.map(({ name, value }, index) => (
              <Select.Option
                key={index}
                value={value}
              >
                {name}
              </Select.Option>
            ))}
          </Select>
        </Item>
      </div>

      <ActionsContainer>
        {remove && (
          <DeleteOutlined
            className="delete"
            onClick={() => remove(name)}
          />
        )}
        {move && (
          <>
            <UpCircleOutlined
              className={name === 0 ? 'disabled' : ''}
              disabled={name === 0}
              onClick={() => move(name, name - 1)}
            />
            <DownCircleOutlined
              className={name === totalItemLength - 1 ? 'disabled' : ''}
              disabled={name === totalItemLength - 1}
              onClick={() => move(name, name + 1)}
            />
          </>
        )}
      </ActionsContainer>
    </div>
  );
};

type PathwayResourceFormProps = {
  move?: (from: number, to: number) => void;
  name: number;
  page: 'create' | 'edit';
  remove?: (index: number[] | number) => void;
  totalItemLength: number;
} & {
  parentForm: FormInstance<{
    resources: {
      pathway_id: string;
      position: number;
      resource_id: string;
      resource_type: 'Course' | 'CourseCollection';
    }[];
  }>;
};

const { Item } = Form;
