import { Card, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { getBadgeGroup, GetBadgeGroupResponse, useFetch } from 'api';
import { ApiImage } from 'components/api_image';
import { CardHeader } from 'components/cards';
import { CardEntryList } from 'components/cards/card_entry_list';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom-v5-compat';
import { userPath } from 'utils/paths';

const badgeColumns: ColumnsType<GetBadgeGroupResponse['badge_criteria'][0]> = [
  {
    dataIndex: 'image',
    key: 'image',
    render: (value: GetBadgeGroupResponse['badge_criteria'][0]['image']) => (
      <ApiImage image={value} />
    ),
    title: 'Image',
    width: 60
  },
  {
    dataIndex: 'score_level',
    key: 'score_level',
    title: 'Score level'
  },
  {
    dataIndex: 'low_score',
    key: 'low_score',
    title: 'Low score'
  },
  {
    dataIndex: 'high_score',
    key: 'high_score',
    title: 'High score'
  }
];

export const BadgeGroupPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(getBadgeGroup, [id]);

  return (
    <div className="site-card-border-less-wrapper">
      {data?.data && (
        <Card
          headStyle={{ fontWeight: 'bold' }}
          style={{ minHeight: 'calc(100vh - 111px)' }}
          title={<CardHeader title={`Benchmark threshold ${data.data.score_type}`} />}
        >
          <CardEntryList
            fieldsMap={[
              { key: 'id', title: 'ID' },
              { key: 'score_type', title: 'Community' },
              { key: 'is_active', title: 'Is active' },
              { key: 'start_date', title: 'Start date', type: 'date' },
              { key: 'end_date', title: 'End date', type: 'date' },
              { key: 'updated_at', title: 'Updated at', type: 'date' },
              {
                key: 'updated_by',
                render: (data: GetBadgeGroupResponse['updated_by']) =>
                  data !== undefined ? (
                    <Link
                      to={{
                        pathname: userPath(data.id)
                      }}
                    >
                      {data.email}
                    </Link>
                  ) : (
                    <>--</>
                  ),
                title: 'Updated by'
              }
            ]}
            values={data.data as Required<typeof data.data>}
          />

          <Card
            bordered={false}
            title="Benchmark thresholds"
          >
            <Table
              bordered
              columns={badgeColumns}
              dataSource={data.data.badge_criteria}
              pagination={false}
              style={{ display: 'inline-block', width: '50%' }}
            />
          </Card>
        </Card>
      )}
    </div>
  );
};
