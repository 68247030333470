import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export const LoadingSpin = () => (
  <div
    style={{
      alignItems: 'center',
      display: 'flex',
      fontSize: 48,
      height: '100vh',
      justifyContent: 'center'
    }}
  >
    <Spin
      indicator={
        <LoadingOutlined
          spin
          style={{
            fontSize: 48
          }}
        />
      }
      size="large"
      tip="Loading..."
    />
  </div>
);
