/* eslint-disable react/no-multi-comp */
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Divider,
  Form,
  FormProps,
  Input,
  Select,
  SelectProps,
  Space
} from 'antd';
import { FormInstance, useForm } from 'antd/lib/form/Form';
import { getOrganizationLicenseTiers, getOrganizations, GetUserResponse, useFetch } from 'api';
import { InputForm, InputItem, SearchInputItem, SelectItem, SwitchItem } from 'components/forms';
import { useMemo, useState } from 'react';

export interface UserFormProps {
  initialData:
    | {
        data: {
          create_hubspot_contact: true;
          delayed_invite_date: null;
          is_clinical: true;
          organization_memberships: [
            {
              employee_id: '';
              member_role: 'member';
              status: 'activated';
            }
          ];
          role: 'provider';
          send_invite_emails: true;
          status: 'activated' | 'created' | 'deactivated' | 'inferred';
        };
        page: 'create';
      }
    | {
        data: Omit<GetUserResponse, 'organization_memberships'> & {
          organization_memberships: (Omit<
            GetUserResponse['organization_memberships'][0],
            'organization_id' | 'organization'
          > & {
            organization_id: {
              label: GetUserResponse['organization_memberships'][0]['organization']['name'];
              value: GetUserResponse['organization_memberships'][0]['id'];
            };
          })[];
        };
        page: 'edit';
      };
  name: string;
  onFinish: NonNullable<FormProps['onFinish']>;
}

const { Item } = Form;

export const UserForm = ({ initialData, name, onFinish }: UserFormProps) => {
  const [disableHubspotInput, setDisableHubspotInput] = useState<boolean | undefined>(
    initialData.page === 'create' ? initialData.data.create_hubspot_contact : undefined
  );

  const [showSendInviteEmailsInput, setShowSendInviteEmailsInput] = useState<boolean | undefined>(
    initialData.page === 'create' ? initialData.data.send_invite_emails : undefined
  );

  const [form] = useForm();

  return (
    <InputForm
      form={form}
      initialValues={initialData.data}
      name={name}
      onFinish={onFinish}
    >
      <InputItem
        label="First name"
        name="first_name"
      />
      <InputItem
        label="Last name"
        name="last_name"
      />
      <InputItem
        label="E-mail"
        name="email"
        required={!['inferred'].includes(initialData.data.status)}
      />
      {initialData.page === 'edit' && (
        <InputItem
          label="Auth0 ID"
          name="auth0_id"
        />
      )}

      <SelectItem
        label="Role"
        name="role"
        required
      >
        <Select.Option value="provider">Provider/Organization User</Select.Option>
        <Select.Option value="admin">Violet Admin</Select.Option>
        <Select.Option value="api_client">API Client</Select.Option>
      </SelectItem>

      <InputItem
        label="NPI"
        name={['user_info', 'npi']}
      />

      <Item label="Organizations">
        <Form.List name="organization_memberships">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div
                  key={key}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 0
                  }}
                >
                  <OrganizationMembershipForm
                    initialOrgLicense={
                      initialData.page === 'create'
                        ? null
                        : initialData.data.organization_memberships[key]?.organization_license_tier
                    }
                    initialStatus={
                      initialData.page === 'create'
                        ? 'activated'
                        : initialData.data.organization_memberships[key]?.status
                    }
                    name={name}
                    orgId={
                      (initialData as Extract<UserFormProps['initialData'], { page: 'edit' }>).data
                        .organization_memberships[key]?.organization_id?.value
                    }
                    parentForm={form}
                    remove={remove}
                    restField={restField}
                  />
                  <Divider />
                </div>
              ))}
              <Item>
                <Button
                  block
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                  type="dashed"
                >
                  Add organization
                </Button>
              </Item>
            </>
          )}
        </Form.List>
      </Item>

      <SwitchItem
        label="Is clinical"
        name="is_clinical"
      />

      {initialData.page === 'create' && (
        <>
          <Divider />

          <SwitchItem
            label="Send invite email series"
            name="send_invite_emails"
            switchProps={{
              onChange: checked => {
                setShowSendInviteEmailsInput(checked);
              }
            }}
            tooltip="If this input is unchecked then all invite emails (including reminders) that would be sent upon user creation will be suspended"
          />

          {Boolean(showSendInviteEmailsInput) && (
            <Item
              label="Delayed invite date"
              name="delayed_invite_date"
            >
              <DatePicker showTime />
            </Item>
          )}
        </>
      )}

      <Divider />

      {(initialData.page === 'create' || initialData.data.hubspot_id === null) && (
        <SwitchItem
          label="Create Hubspot contact"
          name="create_hubspot_contact"
          switchProps={{
            onChange: checked => {
              setDisableHubspotInput(checked);
            }
          }}
          valuePropName="checked"
        />
      )}

      <InputItem
        inputProps={{
          disabled: disableHubspotInput
        }}
        label="Hubspot contact ID"
        name="hubspot_id"
      />
    </InputForm>
  );
};

interface OrganizationMembershipFormProps {
  initialOrgLicense:
    | Extract<
        UserFormProps['initialData'],
        { page: 'edit' }
      >['data']['organization_memberships'][0]['organization_license_tier']
    | null;
  initialStatus: UserFormProps['initialData']['data']['organization_memberships'][0]['status'];
  name: number;
  orgId?: string;
  parentForm: FormInstance;
  remove: (index: number) => void;
  restField: {
    fieldKey?: number;
  };
}

const OrganizationMembershipForm = ({
  initialOrgLicense,
  initialStatus,
  name,
  orgId,
  parentForm,
  remove,
  restField
}: OrganizationMembershipFormProps) => {
  const [organizationId, setOrganizationId] = useState(orgId);
  const [orgLicense, setOrgLicense] = useState(initialOrgLicense);
  const orgLicensesFetchParams = useMemo(
    () => [undefined, undefined, { organization_id: organizationId }],
    [organizationId]
  ) as [undefined, undefined, { organization_id?: string }];
  const { data: orgLicensesData } = useFetch(getOrganizationLicenseTiers, orgLicensesFetchParams, {
    skip: organizationId === undefined
  });

  // Reset license tier selection
  const onOrganizationChange: SelectProps<{
    disabled: undefined;
    key: string;
    label: string;
    title: undefined;
    value: string;
  }>['onChange'] = orgId => {
    setOrganizationId(orgId.value);
    setOrgLicense(null);
    parentForm.setFieldValue(
      ['organization_memberships', name, 'organization_license_tier_id'],
      null
    );
  };

  const onOrgLicenseTierChange = (orgLicenseTierId: string) => {
    const fullOrgLicenseTier = orgLicensesData?.data?.find(
      orgLicense => orgLicense.id === orgLicenseTierId
    );
    setOrgLicense(fullOrgLicenseTier ?? null);
  };

  return (
    <>
      <Space
        align="baseline"
        style={{ display: 'flex', marginBottom: 0 }}
      >
        <SearchInputItem
          getMethod={getOrganizations}
          getMethodArgs={[1, 20, { direct_contract: true }]}
          label=""
          name={[name, 'organization_id']}
          paramsMethod={obj => ({
            key: obj.id,
            name: obj.name,
            value: obj.id
          })}
          required
          selectProps={{
            labelInValue: true,
            onChange: onOrganizationChange
          }}
        />
        <Item
          {...restField}
          name={[name, 'employee_id']}
        >
          <Input placeholder="Employee ID" />
        </Item>
        {name > 0 && <MinusCircleOutlined onClick={() => remove(name)} />}
      </Space>
      <SelectItem
        {...restField}
        label="Member role"
        name={[name, 'member_role']}
      >
        <Select.Option value="member">Member</Select.Option>
        <Select.Option value="superuser">Superuser</Select.Option>
        <Select.Option value="network_manager">Network Manager</Select.Option>
      </SelectItem>
      <SelectItem
        {...restField}
        initialValue={initialStatus}
        label="Membership status"
        name={[name, 'status']}
      >
        <Select.Option value="activated">Activated</Select.Option>
        <Select.Option value="deactivated">Deactivated</Select.Option>
      </SelectItem>
      <SelectItem
        help={
          orgLicense?.available_licenses === 0 && initialOrgLicense?.id !== orgLicense.id
            ? 'This will result in creation of a License Cohort with the capacity of 1'
            : undefined
        }
        initialValue={orgLicense?.id}
        label="License Tier"
        name={[name, 'organization_license_tier_id']}
        onChange={onOrgLicenseTierChange}
        required
        selectProps={{
          disabled: organizationId === undefined
        }}
        style={{ marginBottom: 0 }}
        validateStatus={
          orgLicense?.available_licenses === 0 && initialOrgLicense?.id !== orgLicense.id
            ? 'warning'
            : undefined
        }
      >
        {orgLicensesData?.data?.map(orgLicenseTier => (
          <Select.Option
            key={orgLicenseTier.id}
            value={orgLicenseTier.id}
          >
            <div
              style={{
                display: 'flex'
              }}
            >
              <div style={{ display: 'inline-block', flex: 1 }}>
                {orgLicenseTier.license_tier.name}
              </div>
              <div
                style={{
                  color: orgLicenseTier.available_licenses > 0 ? 'green' : 'red',
                  display: 'inline-block'
                }}
              >
                <b>{orgLicenseTier.available_licenses}</b> available licenses
              </div>
            </div>
          </Select.Option>
        ))}
      </SelectItem>{' '}
    </>
  );
};
