import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Breadcrumb, Col, ConfigProvider, Layout, Row, Switch, theme } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { ReactComponent as Logo } from 'assets/logotype.svg';
import { SignOut } from 'components/auth/sign_out';
import { ErrorToast } from 'components/error_toast';
import { LoadingSpin } from 'components/loading_spin';
import { SideMenu } from 'components/side_menu';
import { createContext, useEffect, useState } from 'react';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import { Link } from 'react-router-dom-v5-compat';
import { AdminRoutes } from 'routes';
import { store, useStore } from 'store';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { isAdmin } from 'utils/user';

const { darkAlgorithm, defaultAlgorithm } = theme;
const { Content, Footer, Sider } = Layout;
const collapseWidthBreakpoint = 750;

const checkShouldBeCollapsed = () => window.innerWidth < collapseWidthBreakpoint;

export const DarkModeContext = createContext(false);

// General admin panel insides layout
const AppLayout = ({ route }: RouteConfigComponentProps) => {
  const [isCollapsed, setIsCollapsed] = useState(checkShouldBeCollapsed());
  const [isDarkMode, setIsDarkMode] = useState(false);

  const { getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect, logout, user } =
    useAuth0();

  const { isSignedIn, userData } = useStore('isSignedIn', 'userData');

  // @ts-expect-error
  const breadcrumbs = useBreadcrumbs(AdminRoutes);

  // Collapse side menu on window width rescaling below breakpoint
  useEffect(() => {
    function handleResize() {
      setIsCollapsed(checkShouldBeCollapsed());
    }

    updateTheme(localStorage.getItem('isDarkMode') === 'dark');
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  useEffect(() => {
    if (isSignedIn || isLoading) return;
    if (!isAuthenticated) {
      loginWithRedirect({
        authorizationParams: { redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI }
      });
      return;
    }

    getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI
      }
    }).then(token => {
      store.dispatch('signIn', {
        errCallback: () => {
          store.dispatch('signOut');
          logout({
            clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
            logoutParams: {
              returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI
            }
          });
        },
        token
      });
    });
  }, [isSignedIn, isLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect, logout]);

  const updateTheme = (darkMode: boolean) => {
    setIsDarkMode(darkMode);
    document.documentElement.setAttribute('data-theme', darkMode ? 'dark' : 'light');
    localStorage.setItem('isDarkMode', darkMode ? 'dark' : 'light');
  };

  if (!userData || isLoading) return <LoadingSpin />;

  if (!isAdmin(userData)) {
    store.dispatch('signOut');
    logout({
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      logoutParams: {
        returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI
      }
    });
  }

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
        token: {
          colorPrimary: '#b961cd'
        }
      }}
    >
      <DarkModeContext.Provider value={isDarkMode}>
        <Layout style={{ minHeight: '100vh' }}>
          <Sider
            collapsed={isCollapsed}
            collapsible
            theme="light"
            trigger={null}
          >
            <div
              style={{
                display: 'flex',
                height: 64,
                justifyContent: 'center',
                justifyItems: 'center'
              }}
            >
              <Logo
                style={{
                  height: '70%',
                  marginTop: '10px',
                  verticalAlign: 'center',
                  width: '70%'
                }}
              />
            </div>
            <SideMenu />
          </Sider>
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: '0 24px'
              }}
            >
              <Row>
                <Col
                  flex="1"
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    paddingLeft: 15
                  }}
                >
                  <Breadcrumb
                    separator=">"
                    style={{
                      fontSize: 'large'
                    }}
                  >
                    {breadcrumbs.map(b => (
                      <Breadcrumb.Item key={b.key}>
                        <Link to={b.key}>{b.breadcrumb}</Link>
                      </Breadcrumb.Item>
                    ))}
                  </Breadcrumb>
                </Col>
                <Col flex="0.08">
                  <Avatar src={user?.picture} />
                </Col>
                <Col
                  flex="0.08"
                  style={{ color: isDarkMode ? 'white' : 'black' }}
                >
                  {userData.email}
                </Col>
                <Col
                  flex="0.1"
                  offset="1"
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    padding: '2px'
                  }}
                >
                  <span style={{ marginRight: '2px' }}>☀️</span>
                  <Switch
                    defaultChecked={isDarkMode}
                    onChange={updateTheme}
                  />
                  <span style={{ marginLeft: '2px' }}>🌒</span>
                </Col>
                <Col offset="1">{isSignedIn && <SignOut logoutAuth0={logout} />}</Col>
              </Row>
            </Header>
            <Content className="site-layout-background">
              {route && renderRoutes(route.routes)}
              <ErrorToast />
            </Content>
            <Footer style={{ padding: '15px 50px', textAlign: 'center' }}>
              Violet Admin Panel
            </Footer>
          </Layout>
        </Layout>
      </DarkModeContext.Provider>
    </ConfigProvider>
  );
};

export default AppLayout;
