import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom-v5-compat';

interface ActionsColumnProps {
  buttonText?: JSX.Element | string;
  customItems?: MenuProps['items'];
  customPaths?: Record<string, ReactNode | string>;
  editPath?: string;
  isInline?: boolean;
  record: object;
  showPath?: string;
}

export const ActionsColumn = ({
  buttonText,
  customItems,
  customPaths,
  editPath,
  isInline = false,
  record,
  showPath
}: ActionsColumnProps) => {
  const items: MenuProps['items'] = [];

  if (showPath !== undefined)
    items.push({
      key: 'show',
      label: (
        <Link
          state={record}
          to={showPath}
        >
          Show
        </Link>
      )
    });

  if (editPath !== undefined)
    items.push({
      key: 'edit',
      label: (
        <Link
          state={record}
          to={editPath}
        >
          Edit
        </Link>
      )
    });

  if (customPaths)
    Object.entries(customPaths).forEach(([name, path], index) =>
      items.push({
        key: index,
        label:
          typeof path === 'string' ? (
            <Link
              state={record}
              to={path}
            >
              {name}
            </Link>
          ) : (
            path
          )
      })
    );

  if (customItems) customItems.forEach(item => items.push(item));

  return (
    <Dropdown menu={{ items }}>
      <Button
        size="small"
        style={
          isInline
            ? {
                background: 'none',
                border: '0',
                boxShadow: 'none',
                padding: '0',
                textAlign: 'left'
              }
            : {
                left: '50%',
                msTransform: 'translate(-50%, -50%)',
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%, -50%)'
              }
        }
      >
        {buttonText !== undefined ? buttonText : <EllipsisOutlined style={{ fontSize: '16px' }} />}
      </Button>
    </Dropdown>
  );
};
