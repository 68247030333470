import * as Sentry from '@sentry/react';
import { fetchUser, FetchUserResponse, setToken } from 'api';
import { createStoreon, StoreonModule } from 'storeon';
import * as hooks from 'storeon/react';

interface State {
  isSignedIn: boolean;
  loginFailed: boolean;
  userData: FetchUserResponse | null;
}

interface Events {
  setUser: { data?: FetchUserResponse };
  signIn: { errCallback?: () => void; token: string };
  signOut: void;
}

// Initial state, reducers and business logic are packed in independent modules
const baseStore: StoreonModule<State, Events> = store => {
  // Initial state
  store.on('@init', () => {
    const token = localStorage.getItem('adminToken');
    if (token !== null) store.dispatch('signIn', { token });

    return {
      isSignedIn: token !== null,
      userData: null
    };
  });

  store.on('signIn', (_, { errCallback, token }) => {
    localStorage.setItem('adminToken', token);
    setToken(token);

    fetchUser()
      .then(({ data }) => {
        store.dispatch('setUser', { data });
      })
      .catch(() => {
        if (errCallback) errCallback();
        else store.dispatch('signOut');
      });
    return {
      isSignedIn: true,
      userData: null
    };
  });

  store.on('signOut', _ => {
    localStorage.removeItem('adminToken');
    setToken(null);
    return {
      isSignedIn: false,
      userData: null
    };
  });

  store.on('setUser', (_, { data }) => {
    Sentry.setUser({
      email: data!.email,
      id: data!.id,
      username: `${data!.first_name} ${data!.last_name}`
    });

    return {
      isSignedIn: true,
      userData: data
    };
  });
};

export const store = createStoreon([baseStore]);

/// type bound hook
/// use it instead of importing from storeon
export const useStore = (...keys: (keyof State)[]) => hooks.useStoreon<State, Events>(...keys);
