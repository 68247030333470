import { store } from 'store';

import { instance } from '.';

export interface FetchUserResponse {
  city: string | null;
  completed_onboarding: boolean;
  created_at: string;
  email: string;
  first_name: string | null;
  id: string;
  is_active: boolean;
  is_clinical: boolean;
  last_name: string | null;
  member_roles: ('member' | 'network_manager' | 'superuser')[];
  organization_memberships: {
    created_at: string;
    employee_id: string;
    id: string;
    is_active: boolean;
    member_role: 'member' | 'network_manager' | 'superuser';
    organization: {
      id: string;
      name: string;
      permissions: OrganizationPermission[];
    };
    organization_id: string;
    organization_license_tier: {
      available_licenses: number;
      created_at: string;
      id: string;
      license_tier: {
        id: string;
        name: string;
      };
      organization: {
        id: string;
        name: string;
      };
      total_licenses: number;
      updated_at: string;
    };
    status: 'activated' | 'deactivated';
    updated_at: string;
    user_id: string;
  }[];
  permissions: UserPermission[];
  role: 'admin' | 'api_client' | 'provider';
  state: string | null;
  status: 'activated' | 'created' | 'deactivated' | 'inferred';
  user_info: {
    acknowledged_data_validity: boolean;
    cultural_competences: {
      community_of_interest: boolean;
      competence_type: ScoreType;
      confidence_level: number;
      formal_education_training: boolean;
      id: string;
      lived_experience: string[] | null;
      professional_experience: boolean;
      user_info_id: string;
    }[];
    data_source: string | null;
    educations: {
      assessed: boolean;
      assessed_at: null;
      bipoc_tag: boolean;
      course_id: string | null;
      course_name: string;
      created_at: string;
      credits: string;
      id: string;
      image: {
        url: string | null;
      };
      lgbq_tag: boolean;
      organization: string;
      tgnc_tag: boolean;
      updated_at: string;
      user: {
        email: string;
        first_name: string;
        id: string;
        last_name: string;
      };
      year: number;
    }[];
    experiences: {
      average_panel_size: number;
      bipoc_percentage: number;
      created_at: string;
      department: string;
      end_date: string;
      id: string;
      job_title: string;
      lgbq_percentage: number;
      organization: string;
      source: 'claims_data' | 'user_entry';
      start_date: string;
      tgnc_percentage: number;
      updated_at: string;
      user_info_id: string;
    }[];
    further_experience: {
      affirmation_influence_recognition: number;
      cultural_beliefs_about_health_acceptance: number;
      cultural_differences_recognition: number;
      free_text: string;
      id: string;
      inequality_within_healthcare_recognition: number;
      patients_decisions_advocation: number;
    } | null;
    gender_identity: string[];
    id: string;
    languages: string[];
    npi: string | null;
    phone_number: string | null;
    privacy_policy: boolean;
    pronouns: string[];
    race_ethnicity: string[];
    sexual_orientation: string[];
    sms_consent: boolean;
    user_id: string;
  };
}

export const fetchUser = () =>
  instance
    .get<ApiResponse<FetchUserResponse>>('/users/profile', {
      data: {}
    })
    .then(res => {
      if (res.status === 401) {
        store.dispatch('signOut');
      }
      return res.data;
    })
    .catch(error => {
      store.dispatch('signOut');
      return Promise.reject(error);
    });

export const setToken = (token: string | null) => {
  instance.defaults.headers['Authorization'] = `Bearer ${token}`;
};
