import { Card, FormProps } from 'antd';
import { createOrganization, CreateOrganizationResponse } from 'api';
import { AxiosResponse } from 'axios';
import { CardHeader } from 'components/cards';
import { useNavigate } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { OrganizationForm } from './organization_form';

export const OrganizationCreatePage = () => {
  const navigate = useNavigate();

  const onFinish: FormProps<{
    hubspot_id: string;
    name: string;
    network?: boolean;
    organization_email_domains?: {
      domain: string;
    }[];
    organization_license_tiers?: {
      license_tier_id: string;
    }[];
    referral_code: string | null;
    sso_connection_id?: string;
    unlimited_license?: boolean;
  }>['onFinish'] = values => {
    const { organization_email_domains, organization_license_tiers, referral_code, ...orgValues } =
      values;

    return createOrganization({
      organization: {
        referral_code: referral_code === '' ? null : referral_code,
        ...orgValues,
        organization_email_domains_attributes: organization_email_domains,
        organization_license_tiers_attributes: organization_license_tiers
      }
    }).then((response: AxiosResponse<CreateOrganizationResponse>) => {
      const { id } = response.data;
      navigate(paths.organizationPath(id));
    });
  };

  return (
    <Card title={<CardHeader title="Create organization" />}>
      <OrganizationForm
        name="course-collection-create"
        onFinish={onFinish}
      />
    </Card>
  );
};
