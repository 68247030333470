import { Card, FormProps } from 'antd';
import { createBadgeGroup } from 'api';
import { CardHeader } from 'components/cards';
import { useNavigate } from 'react-router-dom-v5-compat';
import { toFormData } from 'utils/forms';
import * as paths from 'utils/paths';

import { BadgeGroupForm } from './badge_group_form';

export const BadgeGroupCreatePage = () => {
  const navigate = useNavigate();

  const onFinish: FormProps<{
    badge_criteria: {
      high_score: number;
      image?: { file: File; fileList: File[] };
      low_score: number;
      score_level: string;
    }[];
    is_active: boolean;
    score_type: ScoreType;
  }>['onFinish'] = values => {
    const createData = toFormData({
      badge_criteria_group: {
        ...values,
        badge_criteria: values.badge_criteria.map(criteria => ({
          _delim: 0,
          ...criteria,
          image: criteria.image?.file
        }))
      }
    });

    return createBadgeGroup(createData).then(({ data }) => {
      data?.id !== undefined
        ? navigate(paths.badgeGroupPath(data.id))
        : navigate(paths.badgeGroupsPath);
    });
  };

  const initialData = {
    badge_criteria: [
      { score_level: 'placeholder' },
      { score_level: 'awareness' },
      { score_level: 'proficiency' },
      { score_level: 'excellence' }
    ],
    is_active: true
  };

  return (
    <Card title={<CardHeader title="Create Benchmark threshold batch" />}>
      <BadgeGroupForm
        initialData={initialData}
        name="badge-criteria-batch-create"
        onFinish={onFinish}
      />
    </Card>
  );
};
