import { Card, FormProps, Spin } from 'antd';
import {
  getCourseCollection,
  GetCoursesResponse,
  updateCourseCollection,
  useFetch,
  VendorNames
} from 'api';
import { CardHeader } from 'components/cards';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom-v5-compat';
import { renameAndFillNestedAttributes } from 'utils/helpers';
import * as paths from 'utils/paths';

import { CourseCollectionForm, CourseCollectionFormProps } from './course_collection_form';

export const CourseCollectionEditPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(getCourseCollection, [id]);

  const navigate = useNavigate();

  const onFinish: FormProps<{
    communities: ScoreType[];
    courses: GetCoursesResponse[];
    credits: number | null;
    description: string;
    external_accreditation_references: {
      vendor_name: VendorNames;
      vendor_resource_id: string;
    }[];
    financial_disclosures: {
      disclosure_text: string;
    }[];
    level: 'advanced' | 'beginner' | 'foundation' | 'intermediate' | null;
    name: string;
    organization_id?: {
      key: string;
      label: string;
      value: string;
    } | null;
    published: 'not_published' | 'pinned' | 'published';
    specialties: string[];
  }>['onFinish'] = ({
    courses,
    external_accreditation_references,
    financial_disclosures,
    organization_id,
    ...values
  }) => {
    updateCourseCollection(id, {
      course_collection: {
        course_ids: courses.map(course => course.id),
        organization_id:
          organization_id === undefined
            ? ' '
            : organization_id === null
            ? undefined
            : organization_id.value,
        ...values,
        ...renameAndFillNestedAttributes(
          {
            // @ts-expect-error
            external_accreditation_references,
            // @ts-expect-error
            financial_disclosures
          },
          data?.data
        )
      }
    }).then(() => navigate(paths.courseCollectionsPath));
  };

  if (!data?.data) return <Spin />;

  const initialData: CourseCollectionFormProps['initialData'] = {
    data: data.data,
    page: 'edit'
  };

  return (
    <Card title={<CardHeader title={`Edit collection ${data.data.name}`} />}>
      <CourseCollectionForm
        initialData={initialData}
        name="course-collection-edit"
        onFinish={onFinish}
      />
    </Card>
  );
};
