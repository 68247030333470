import type { DatePickerProps } from 'antd';
import type { AxiosResponse } from 'axios';

import { instance } from '.';

interface CreateLicenseCohortParams {
  license_cohort: {
    organization_license_tier_id: string;
    start_date?: DatePickerProps['value'];
    users_count: number;
  };
}

export interface GetLicenseCohortsResponse {
  created_at: string;
  id: string;
  organization_license_tier: {
    available_licenses: number;
    created_at: string;
    id: string;
    license_tier: {
      id: string;
      name: string;
    };
    organization: {
      id: string;
      name: string;
    };
    total_licenses: number;
    updated_at: string;
  };
  organization_license_tier_id: string;
  start_date: string;
  users_count: number;
}

export const createLicenseCohort = (params: CreateLicenseCohortParams) =>
  instance.post<'', AxiosResponse<''>, CreateLicenseCohortParams>('/admin/license_cohorts', params);

export const getLicenseCohorts = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetLicenseCohortsResponse[], IndexPageMeta>>('/admin/license_cohorts', {
      params: {
        ...getParams,
        page,
        per_page: pageSize
      }
    })
    .then(res => res.data);
