import type { AxiosResponse } from 'axios';
import type { RequireAtLeastOne } from 'type-fest';

import { instance } from '.';

interface LicenseTier {
  created_at: string;
  id: string;
  name: string;
  organization_permissions: OrganizationPermission[];
  updated_at: string;
  user_permissions: UserPermission[];
}

interface CreateLicenseTierParams {
  license_tier: {
    name: LicenseTier['name'];
    organization_permissions?: LicenseTier['organization_permissions'];
    user_permissions?: LicenseTier['user_permissions'];
  };
}

interface CreateLicenseTierResponse extends LicenseTier {}

export interface GetLicenseTierResponse extends LicenseTier {}

export interface GetLicenseTiersResponse extends LicenseTier {}

interface UpdateLicenseTierParams {
  license_tier: RequireAtLeastOne<{
    name: LicenseTier['name'];
    organization_permissions: LicenseTier['organization_permissions'];
    user_permissions: LicenseTier['user_permissions'];
  }>;
}

interface UpdateLicenseTierResponse extends LicenseTier {}

export const createLicenseTier = (params: CreateLicenseTierParams) =>
  instance
    .post<
      ApiResponse<CreateLicenseTierResponse>,
      AxiosResponse<ApiResponse<CreateLicenseTierResponse>>,
      CreateLicenseTierParams
    >('/admin/license_tiers', params)
    .then(res => res.data);

export const deleteLicenseTier = (id: string) => instance.delete<''>(`/admin/license_tiers/${id}`);

export const getLicenseTier = (id: string) =>
  instance
    .get<ApiResponse<GetLicenseTierResponse>>(`/admin/license_tiers/${id}`)
    .then(res => res.data);

export const getLicenseTiers = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetLicenseTiersResponse[], IndexPageMeta>>('/admin/license_tiers', {
      params: {
        ...getParams,
        page,
        per_page: pageSize
      }
    })
    .then(res => res.data);

export const updateLicenseTier = (id: string, params: UpdateLicenseTierParams) =>
  instance
    .patch<
      ApiResponse<UpdateLicenseTierResponse>,
      AxiosResponse<ApiResponse<UpdateLicenseTierResponse>>,
      UpdateLicenseTierParams
    >(`/admin/license_tiers/${id}`, params)
    .then(res => res.data);
