import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  DatePicker,
  DatePickerProps,
  Divider,
  Form,
  FormProps,
  Space,
  Upload
} from 'antd';
import { UploadState } from 'antd/es/upload/interface';
import { getOrganizations, importUsersCsvFile } from 'api';
import { CardHeader } from 'components/cards';
import { InputForm, SearchInputItem, SwitchItem } from 'components/forms';
import { DarkModeContext } from 'layouts/app_layout';
import { useContext, useState } from 'react';
import { BodyRow, HeaderRow, Table, TableWrapper } from 'styled_components/tables';
import { normalizeFileFromUpload, toFormData } from 'utils/forms';

const { Item } = Form;

export const UsersImportCsvPage = () => {
  const [response, setResponse] = useState<ImportUserResponse | null>(null);
  const [showSendInviteEmailsInput, setShowSendInviteEmailsInput] = useState(true);
  const isDarkMode = useContext(DarkModeContext);

  const onFinish: FormProps<{
    delayed_invite_date?: DatePickerProps['value'];
    file: UploadState['fileList'];
    organization_id: {
      disabled: undefined;
      key: string;
      label: string;
      title: undefined;
      value: string;
    };
    send_invite_emails: boolean;
  }>['onFinish'] = values => {
    const { delayed_invite_date, file, organization_id, send_invite_emails } = values;

    const data = toFormData({
      delayed_invite_date: delayed_invite_date?.format('YYYY-MM-DDTHH:mm:ss[Z]'),
      file: file[0].originFileObj,
      organization_id: organization_id.value,
      send_invite_emails
    });

    setResponse(null);
    return importUsersCsvFile(data)
      .then(result => {
        setResponse(result);
      })
      .catch(() => {});
  };

  const initialData = {
    send_invite_emails: true
  };

  const onDownloadErrors = () => {
    if (!response || response.errors.length === 0) {
      return;
    }

    const { errors } = response;

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      `line_number,error,First Name,Last Name,Email,Phone number,Employee ID,NPI,License Tier,Member role,Is clinical,Create Hubspot contact\n` +
      errors
        .map(
          e =>
            `${e.line_number},${e.message},${e.line_data.first_name},${e.line_data.last_name},${e.line_data.email},${e.line_data.phone_number},${e.line_data.employee_id},${e.line_data.npi},${e.line_data.license_tier},${e.line_data.member_role},${e.line_data.is_clinical},${e.line_data.create_hubspot_contact}`
        )
        .join('\n');
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `bulk_import_users_errors.csv`);
    document.body.appendChild(link);

    link.click();
  };

  return (
    <Card title={<CardHeader title="Import CSV file" />}>
      <h1>CSV format:</h1>
      <TableWrapper>
        <Table className={isDarkMode ? 'dark-theme' : 'light-theme'}>
          <thead>
            <HeaderRow className={isDarkMode ? 'dark-theme' : 'light-theme'}>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone number</th>
              <th>Employee ID</th>
              <th>NPI</th>
              <th>License Tier</th>
              <th>Member role</th>
              <th>Is clinical</th>
              <th>Create Hubspot contact</th>
            </HeaderRow>
          </thead>
          <tbody>
            <BodyRow className={isDarkMode ? 'dark-theme' : 'light-theme'}>
              <td>string</td>
              <td>string</td>
              <td>string</td>
              <td>string</td>
              <td>string</td>
              <td>10-digit number</td>
              <td>string</td>
              <td>
                member <u>or</u> network_manager <u>or</u> superuser
              </td>
              <td>boolean</td>
              <td>boolean</td>
            </BodyRow>
          </tbody>
        </Table>
      </TableWrapper>

      <InputForm
        initialValues={initialData}
        name="import-csv"
        onFinish={onFinish}
      >
        <Alert
          description={
            <span>
              If number of users added to a license tier exceeds available licenses count for the
              organization of this license type a new cohort will be created automatically with size
              enough to provide licenses to all users
            </span>
          }
          message="A reminder!"
          style={{ marginBottom: '20px' }}
          type="warning"
        />

        <SearchInputItem
          getMethod={getOrganizations}
          getMethodArgs={[1, 20]}
          label="Organization"
          name="organization_id"
          paramsMethod={obj => ({
            key: obj.id,
            name: obj.name,
            value: obj.id
          })}
          required
          selectProps={{
            labelInValue: true
          }}
        />

        <Item
          getValueFromEvent={normalizeFileFromUpload}
          label="CSV file"
          name="file"
          required
          rules={[
            {
              validator: async (_, fileList: UploadState['fileList']) => {
                if (fileList.length < 1) {
                  return Promise.reject(new Error('Upload a file'));
                }
                return true;
              }
            }
          ]}
          valuePropName="fileList"
        >
          <Upload
            beforeUpload={() => false}
            maxCount={1}
            showUploadList={{
              showRemoveIcon: false
            }}
          >
            <Button icon={<UploadOutlined />}>Upload CSV file</Button>
          </Upload>
        </Item>

        <Divider />

        <SwitchItem
          label="Send invite email series"
          name="send_invite_emails"
          switchProps={{
            onChange: checked => {
              setShowSendInviteEmailsInput(checked);
            }
          }}
          tooltip="If this input is unchecked then all invite emails (including reminders) that would be sent upon each user creation will be suspended"
        />

        {showSendInviteEmailsInput && (
          <Item
            label="Delayed invite date"
            name="delayed_invite_date"
          >
            <DatePicker showTime />
          </Item>
        )}
      </InputForm>

      {response && (
        <Space
          direction="vertical"
          style={{ display: 'flex', margin: '50px' }}
        >
          <Alert
            message={<b>{response.status}</b>}
            type="info"
          />

          {response.info.map((info, i) => (
            <Alert
              key={i}
              message={info}
              type="info"
            />
          ))}

          {response.errors.length > 0 && (
            <TableWrapper>
              <Table className={isDarkMode ? 'dark-theme' : 'light-theme'}>
                <thead>
                  <HeaderRow className={isDarkMode ? 'dark-theme' : 'light-theme'}>
                    <th>line_number</th>
                    <th>message</th>
                    <th>first_name</th>
                    <th>last_name</th>
                    <th>email</th>
                    <th>NPI</th>
                  </HeaderRow>
                </thead>
                <tbody>
                  {response.errors.map((error, i) => (
                    <BodyRow
                      key={i}
                      className={isDarkMode ? 'dark-theme' : 'light-theme'}
                    >
                      <td>{error.line_number}</td>
                      <td>{error.message}</td>
                      <td>{error.line_data.first_name}</td>
                      <td>{error.line_data.last_name}</td>
                      <td>{error.line_data.email}</td>
                      <td>{error.line_data.npi}</td>
                    </BodyRow>
                  ))}
                </tbody>
              </Table>
              <Button
                onClick={onDownloadErrors}
                style={{ marginTop: '1rem' }}
              >
                <DownloadOutlined /> Download errors
              </Button>
            </TableWrapper>
          )}
        </Space>
      )}
    </Card>
  );
};
