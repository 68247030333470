import axios, { AxiosError } from 'axios';
import { useCallback, useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';
import { RequireAtLeastOne } from 'type-fest';

export * from './auth';
export * from './badge_groups';
export * from './bookmarks';
export * from './communication_infos';
export * from './course_activities';
export * from './course_collection_completions';
export * from './course_collections';
export * from './course_ratings';
export * from './course_registrations';
export * from './courses';
export * from './educations';
export * from './feature_flags';
export * from './license_cohorts';
export * from './license_tiers';
export * from './networks';
export * from './npi_registry_data';
export * from './organization_license_tiers';
export * from './organizations';
export * from './pathway_completions';
export * from './pathways';
export * from './scores';
export * from './sessions';
export * from './tags';
export * from './users';

const errorPrefix = (status: number) => {
  switch (status) {
    case 422:
      return 'Invalid data: ';
    case 403:
      return 'Not authorized: ';
    default:
      return 'An error occured: ';
  }
};

const invokeToast = (message: string) => {
  toast.error(message, {
    autoClose: 2000,
    closeOnClick: true,
    draggable: true,
    hideProgressBar: false,
    pauseOnHover: true,
    position: 'bottom-center',
    progress: undefined
  });
};

const API_URI = process.env.REACT_APP_API_URL;

if (API_URI === undefined) {
  console.warn(process.env);
  console.warn('NO API URI SPECIFIED: check .env');
}

export const instance = axios.create({
  baseURL: API_URI,
  headers: {
    accept: 'application/json'
  }
});

instance.interceptors.response.use(
  response => response,
  (error: AxiosError<RequireAtLeastOne<{ error: string; errors: string[] }>, string>) => {
    if (error.response!.data.errors !== undefined) {
      const message =
        errorPrefix(error.response!.status) +
        Object.entries(error.response!.data.errors)
          .map(v => `${v[0]} ${v[1]}`)
          .join(', ');
      invokeToast(message);
    } else if (error.response!.data.error !== undefined) {
      invokeToast(error.response!.data.error);
    } else {
      invokeToast(error.response!.statusText);
    }

    return Promise.reject(error);
  }
);

interface FetchState<Result, Variables> {
  data: Result | null;
  error: Error | null;
  isLoading: boolean;
  loadCount: number;
  params: Variables | null;
}

type FetchAction<Result, Variables> =
  | {
      data: Result;
      params: Variables | null;
      type: 'data';
    }
  | {
      error: Error;
      type: 'error';
    }
  | {
      type: 'loading';
    };

const fetchReducer = <Result, Variables>(
  state: FetchState<Result, Variables>,
  action: FetchAction<Result, Variables>
) => {
  switch (action.type) {
    case 'data':
      return {
        ...state,
        data: action.data,
        error: null,
        isLoading: false,
        params: action.params
      };
    case 'error':
      return {
        ...state,
        error: action.error,
        isLoading: false
      };
    case 'loading':
      return {
        ...state,
        isLoading: true
      };
  }
};

interface UseFetchOptions<Result> {
  initData?: Result;
  skip?: boolean;
}

export const useFetch = <Result, Variables extends unknown[]>(
  fetchFunction: (...args: Variables) => Promise<Result>,
  variables: Variables,
  options?: UseFetchOptions<Result>
) => {
  const [state, dispatch] = useReducer(fetchReducer<Result, Variables>, {
    data: options?.initData ?? null,
    error: null,
    isLoading: !(options?.skip ?? false),
    loadCount: 0,
    params: null
  });

  const skip = options?.skip ?? false;
  const stringifiedVariables = JSON.stringify(variables);

  const fetchData = useCallback(() => {
    if (skip) return Promise.resolve(null);

    dispatch({ type: 'loading' });

    const params = JSON.parse(stringifiedVariables) as typeof variables;

    return fetchFunction(...params).then(
      data => {
        dispatch({ data, params, type: 'data' });
        return data;
      },
      (error: Error) => {
        dispatch({ error, type: 'error' });
        return null;
      }
    );
  }, [fetchFunction, skip, stringifiedVariables]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data: state.data,
    error: state.error,
    isLoading: state.isLoading,
    loadCount: state.loadCount,
    refetch: fetchData
  };
};
