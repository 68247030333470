import { Form, FormItemProps, Input, InputProps } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { ChangeEventHandler } from 'react';

type InputItemProps = FormItemProps & {
  disabled?: boolean;
  inputProps?: InputProps | TextAreaProps;
  onChange?: ChangeEventHandler<HTMLInputElement> | ChangeEventHandler<HTMLTextAreaElement>;
  textArea?: boolean;
  textAreaProps?: TextAreaProps;
};

export const InputItem = ({
  disabled = false,
  inputProps,
  onChange,
  required = false,
  rules,
  textArea,
  ...itemProps
}: InputItemProps) => (
  <Form.Item
    required={required}
    rules={
      required ? [{ message: 'This field is required', required: true }, ...(rules ?? [])] : rules
    }
    {...itemProps}
  >
    {textArea !== undefined ? (
      <Input.TextArea
        disabled={disabled}
        onChange={onChange as ChangeEventHandler<HTMLTextAreaElement> | undefined}
        {...(inputProps as TextAreaProps)}
      />
    ) : (
      <Input
        disabled={disabled}
        onChange={onChange as ChangeEventHandler<HTMLInputElement> | undefined}
        {...(inputProps as InputProps)}
      />
    )}
  </Form.Item>
);
