import { ColumnsType } from 'antd/es/table';
import { GetCourseCollectionResponse } from 'api';
import { BooleanIcon } from 'components/boolean_icon';
import { ActionsColumn } from 'components/tables';
import { Link } from 'react-router-dom';
import * as paths from 'utils/paths';

export const courseColumns: ColumnsType<GetCourseCollectionResponse['courses'][0]> = [
  {
    dataIndex: 'short_title',
    key: 'short_title',
    render: (value: GetCourseCollectionResponse['courses'][0]['short_title'], record) => (
      <Link to={paths.coursePath(record.id.toString())}>{value}</Link>
    ),
    title: 'Short title'
  },
  {
    dataIndex: 'published',
    key: 'published',
    render: (value: GetCourseCollectionResponse['courses'][0]['published']) => (
      <BooleanIcon value={value} />
    ),
    title: 'Published'
  },
  {
    dataIndex: 'scormcloud_id',
    key: 'scormcloud_id',
    render: (data: GetCourseCollectionResponse['courses'][0]['scormcloud_id']) => (
      <BooleanIcon value={Boolean(data)} />
    ),
    title: 'Scormcloud'
  },
  {
    dataIndex: 'author',
    key: 'authorl',
    title: 'Author'
  },
  {
    dataIndex: 'hours',
    key: 'hours',
    title: 'Hours'
  },
  {
    dataIndex: 'bipoc_communities',
    key: 'bipoc_communities',
    render: (data: GetCourseCollectionResponse['courses'][0]['bipoc_communities']) => (
      <BooleanIcon value={data} />
    ),
    title: 'BIPOC'
  },
  {
    dataIndex: 'lgbq_communities',
    key: 'lgbq_communities',
    render: (data: GetCourseCollectionResponse['courses'][0]['lgbq_communities']) => (
      <BooleanIcon value={data} />
    ),
    title: 'LGBQ'
  },
  {
    dataIndex: 'tgnc_communities',
    key: 'tgnc_communities',
    render: (data: GetCourseCollectionResponse['courses'][0]['tgnc_communities']) => (
      <BooleanIcon value={data} />
    ),
    title: 'TGNC'
  },
  {
    render: (_value, record) => (
      <ActionsColumn
        editPath={paths.courseEditPath(record.id.toString())}
        record={record}
        showPath={paths.coursePath(record.id.toString())}
      />
    ),
    title: 'Actions'
  }
];
