import { Link } from 'react-router-dom-v5-compat';
import { userPath } from 'utils/paths';

interface UserLinkProps {
  user: Pick<User, 'email' | 'first_name' | 'id' | 'last_name'>;
}

export const UserLink = ({ user }: UserLinkProps) => (
  <>
    <Link to={userPath(user.id)}>{`${user.first_name} ${user.last_name}`}</Link>
    <p>{user.email}</p>
  </>
);
