import { Card } from 'antd';
import { getCommunicationInfo, useFetch } from 'api';
import { CardEntry, CardHeader } from 'components/cards';
import { CardEntryList } from 'components/cards/card_entry_list';
import { UserLink } from 'components/common';
import { useParams } from 'react-router';

export const CommunicationInfoPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(getCommunicationInfo, [id]);

  return (
    <div className="site-card-border-less-wrapper">
      {data?.data && (
        <Card
          headStyle={{ fontWeight: 'bold' }}
          style={{ minHeight: 'calc(100vh - 111px)' }}
          title={
            <CardHeader
              title={` Communication info ${data.data.email_type} ${data.data.user?.email}`}
            />
          }
        >
          <CardEntry title="Addressee">
            {data.data.user && <UserLink user={data.data.user} />}
          </CardEntry>
          <CardEntryList
            fieldsMap={[
              { key: 'id', title: 'ID' },
              { key: 'email_type', title: 'Email type' },
              { key: 'email_template_id', title: 'Sendgrid template id' },
              { key: 'status', title: 'Status' },
              {
                key: 'email_delivered_at',
                title: 'Delivered at',
                type: 'date'
              },
              { key: 'email_opened_at', title: 'Opened at', type: 'date' },
              {
                key: 'email_link_clicked_at',
                title: 'Link clicked at',
                type: 'date'
              },
              { key: 'sg_machine_open', title: 'SG opened', type: 'date' },
              { key: 'created_at', title: 'Created at', type: 'date' },
              { key: 'updated_at', title: 'Updated at', type: 'date' }
            ]}
            values={data.data as Required<typeof data.data>}
          />
        </Card>
      )}
    </div>
  );
};
