import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

enum TZ_FORMATS {
  'UTC' = '([UTC]ZZ)',
  'abbreviated' = '(z)'
}

interface DateFormatterProps {
  format?: string;
  hideTimezone?: boolean;
  timezoneFormat?: 'abbreviated' | 'UTC';
  value: string;
}

export const DateFormatter = ({
  format,
  hideTimezone = false,
  timezoneFormat = 'UTC',
  value
}: DateFormatterProps) => {
  if (!value) return <span>-</span>;

  const utcValue = dayjs.utc(value).tz(dayjs.tz.guess());
  const datetime = utcValue.format(format ?? 'MM/DD/YYYY HH:mm:ss');
  const tzFormatString = TZ_FORMATS[timezoneFormat];
  const timezone = utcValue.format(tzFormatString);
  return (
    <>
      <span>{datetime}</span> {!hideTimezone && <span style={{ color: 'grey' }}>{timezone}</span>}
    </>
  );
};
