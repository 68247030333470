import { Card, FormProps, Spin } from 'antd';
import { getPathway, updatePathway, useFetch } from 'api';
import { CardHeader } from 'components/cards';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom-v5-compat';
import { toFormData } from 'utils/forms';
import * as paths from 'utils/paths';

import { PathwayFormProps } from './pathway_create_page';
import { PathwayForm } from './pathway_form';

export const PathwayEditPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(getPathway, [id]);

  const navigate = useNavigate();

  const onFinish: FormProps<PathwayFormProps>['onFinish'] = ({
    image,
    resources,
    skills,
    ...values
  }) => {
    const pathwayResources = resources.map((resource, index) => ({
      id: resource.id,
      position: index,
      resource_id: resource.resource_id,
      resource_type: resource.resource_type
    }));
    const initialResources = data?.data?.resources ?? [];
    const resourcesToDelete = initialResources
      .filter(resource => !pathwayResources.some(r => r.id === resource.id))
      .map(resource => ({
        _destroy: true,
        id: resource.id,
        position: 99,
        resource_id: resource.resource_id,
        resource_type: resource.resource_type
      }));
    const resourcesToKeep = pathwayResources
      .filter(resource => !resourcesToDelete.find(r => r.id === resource.id))
      .map((resource, index) => ({
        _destroy: false,
        id: resource.id,
        position: index,
        resource_id: resource.resource_id,
        resource_type: resource.resource_type
      }));

    updatePathway(
      id,
      toFormData({
        pathway: {
          ...values,
          image: image?.file,
          pathway_resources_attributes: [...resourcesToDelete, ...resourcesToKeep].sort(
            (a, b) => a.position - b.position
          ),
          skills: skills.map(skill => skill.name)
        }
      })
    ).then(({ data }) => {
      data?.id !== undefined ? navigate(paths.pathwayPath(data.id)) : navigate(paths.pathwaysPath);
    });
  };

  if (!data?.data) return <Spin />;

  const initialData = {
    ...data.data,
    image: null,
    skills: data.data.skills.map(skill => ({ name: skill }))
  };

  return (
    <Card title={<CardHeader title={`Edit pathway ${data.data.name}`} />}>
      <PathwayForm
        initialData={initialData}
        initialImage={data.data.image}
        name="pathway-edit"
        onFinish={onFinish}
      />
    </Card>
  );
};
