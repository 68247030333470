import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import { render } from 'react-dom';
import { renderRoutes } from 'react-router-config';
import { BrowserRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { routes } from 'routes';
import { store } from 'store';
import { StoreContext } from 'storeon/react';
import 'styles/main.css';

Sentry.init({
  dsn: 'https://d0a3e8b885b540c8a8be8f3167f15ee6@o527013.ingest.sentry.io/6353389',
  enabled: process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'production',
  environment: process.env.REACT_APP_ENV,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0
});

const Root = () => renderRoutes(routes);

render(
  <React.StrictMode>
    <StoreContext.Provider value={store}>
      <Auth0Provider
        authorizationParams={{
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE!,
          redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI!
        }}
        cacheLocation="localstorage"
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
        domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      >
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <CompatRouter>
            <Root />
          </CompatRouter>
        </BrowserRouter>
      </Auth0Provider>
    </StoreContext.Provider>
  </React.StrictMode>,

  document.getElementById('app')
);
