import { AxiosResponse } from 'axios';

import { instance } from '.';

export interface GetNetworkMembersResponse {
  created_at: string;
  direct_contract: boolean;
  id: string;
  name: string;
  user_totals: {
    activated: number;
    active: number;
    created: number;
    deactivated: number;
    inferred: number;
    onboarded: number;
    total: number;
  };
}

interface CreateNetworkMemberParams {
  network_organization_member: {
    network_id: string;
    organization_id: string;
  };
}

export const addNetworkMember = (params: CreateNetworkMemberParams) =>
  instance
    .post<ApiResponse, AxiosResponse<ApiResponse>, CreateNetworkMemberParams>(
      '/admin/networks/organization_members/',
      params
    )
    .then(res => res.data);

export const deleteNetworkMember = (networkId: string, organizationId: string) =>
  instance.delete<''>(`/admin/networks/${networkId}/organization_members/${organizationId}`);

export const getNetworkMembers = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetNetworkMembersResponse[], IndexPageMeta>>(
      '/admin/networks/organization_members',
      {
        params: {
          ...getParams,
          page,
          per_page: pageSize
        }
      }
    )
    .then(res => res.data);
