import { instance } from '.';

interface CourseRegistration {
  completed_at: string;
  course: {
    id: string;
    short_title: string;
  };
  course_scormcloud_id: string;
  created_at: string;
  id: string;
  total_seconds_tracked: number;
  updated_at: string;
  user: User;
}

export interface GetCourseRegistrationResponse extends CourseRegistration {}

export interface GetCourseRegistrationsResponse extends CourseRegistration {}

export const getCourseRegistration = (id: string) =>
  instance
    .get<ApiResponse<GetCourseRegistrationResponse>>(`/admin/course_registrations/${id}`)
    .then(res => res.data);

export const getCourseRegistrations = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetCourseRegistrationsResponse[], IndexPageMeta>>(
      '/admin/course_registrations',
      {
        params: {
          ...getParams,
          page,
          per_page: pageSize
        }
      }
    )
    .then(res => res.data);
