import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, FormProps, Input, InputNumber, Select, Switch, Upload } from 'antd';
import { Store } from 'antd/es/form/interface';
import { InputForm } from 'components/forms';

const { Item } = Form;
const { Option } = Select;

interface BadgeGroupFormProps {
  initialData: Store;
  name: string;
  onFinish: NonNullable<FormProps['onFinish']>;
}

export const BadgeGroupForm = ({ initialData, name, onFinish }: BadgeGroupFormProps) => (
  <InputForm
    initialValues={initialData}
    name={name}
    onFinish={onFinish}
  >
    <Item
      label="Community"
      name="score_type"
      rules={[{ message: 'Choose a community', required: true }]}
    >
      <Select>
        <Option value="lgbq">LGBQ</Option>
        <Option value="bipoc">BIPOC</Option>
        <Option value="tgnc">TGNC</Option>
      </Select>
    </Item>

    <Item
      label="Active"
      name="is_active"
      valuePropName="checked"
    >
      <Switch />
    </Item>

    <Item
      label="Benchmark threshold"
      name="badge_criteria"
    >
      <Form.List name="badge_criteria">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: 8,
                  width: '100%'
                }}
              >
                <div
                  key={`sub-${key}`}
                  style={{
                    display: 'flex',
                    width: '100%'
                  }}
                >
                  <Item
                    {...restField}
                    name={[name, 'score_level']}
                    rules={[
                      {
                        message: 'Missing score level name',
                        required: true
                      }
                    ]}
                    style={{
                      marginRight: index > 0 ? '8px' : undefined,
                      width: '100%'
                    }}
                  >
                    <Input
                      disabled={index === 0}
                      placeholder="Score level name"
                      style={{ width: '100%' }}
                    />
                  </Item>
                  {index > 0 && <MinusCircleOutlined onClick={() => remove(name)} />}
                </div>
                <div
                  key={key}
                  style={{
                    display: 'flex',
                    width: '100%'
                  }}
                >
                  <Item
                    {...restField}
                    name={[name, 'low_score']}
                    rules={
                      index > 0 ? [{ message: 'Missing low score', required: true }] : undefined
                    }
                    style={{ width: '50%' }}
                  >
                    <InputNumber
                      placeholder="Low score"
                      style={{ width: '100%' }}
                    />
                  </Item>
                  <Item
                    {...restField}
                    name={[name, 'high_score']}
                    rules={
                      index > 0 ? [{ message: 'Missing high score', required: true }] : undefined
                    }
                    style={{ width: '50%' }}
                  >
                    <InputNumber
                      placeholder="High score"
                      style={{ width: '100%' }}
                    />
                  </Item>
                </div>
                <Item
                  name={[name, 'image']}
                  valuePropName="file"
                >
                  <Upload
                    beforeUpload={() => false}
                    maxCount={1}
                    multiple
                    name="file"
                    showUploadList={{
                      showRemoveIcon: true
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Upload image</Button>
                  </Upload>
                </Item>
              </div>
            ))}
            <Item>
              <Button
                block
                icon={<PlusOutlined />}
                onClick={() => add()}
                type="dashed"
              >
                Add Benchmark threshold
              </Button>
            </Item>
            <Form.ErrorList errors={errors} />
          </>
        )}
      </Form.List>
    </Item>
  </InputForm>
);
