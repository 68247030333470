import { Card, FormProps, Spin } from 'antd';
import { getCourse, updateCourse, useFetch } from 'api';
import { CardHeader } from 'components/cards';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom-v5-compat';
import { renameAndFillNestedAttributes } from 'utils/helpers';
import * as paths from 'utils/paths';

import { CourseForm } from './course_form';

export const CourseEditPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(getCourse, [id]);

  const navigate = useNavigate();

  const onFinish: FormProps<{
    author: string;
    bipoc_communities: string[];
    ce_accrediting_associations: string[];
    ce_credit_types: string[];
    ce_credits: string;
    ce_provider: string | null;
    content_medium: string;
    description: string;
    external_accreditation_references: (
      | {
          _destroy: true;
          id: string;
        }
      | {
          id?: string;
          vendor_name: string;
          vendor_resource_id: string;
        }
    )[];
    financial_disclosures: (
      | {
          _destroy: true;
          id: string;
        }
      | {
          disclosure_text: string;
        }
    )[];
    format_category: string;
    full_title: string;
    group_training: boolean;
    hours: string;
    learning_objectives: (
      | {
          _destroy: true;
          id: string;
        }
      | {
          id?: string;
          name: string;
        }
    )[];
    lgbq_communities: string[];
    link: string;
    organization_id?: {
      key: string;
      label: string;
      value: string;
    } | null;
    organization_name: string;
    professional_groups: string[];
    published: boolean;
    scormcloud_id: string | null;
    short_title: string;
    tgnc_communities: string[];
    year: number;
  }>['onFinish'] = ({
    external_accreditation_references,
    financial_disclosures,
    learning_objectives,
    organization_id,
    ...values
  }) =>
    updateCourse(id, {
      course: {
        ...values,
        organization_id:
          organization_id === undefined
            ? ' '
            : organization_id === null
            ? undefined
            : organization_id.value,
        ...renameAndFillNestedAttributes(
          {
            // @ts-expect-error
            external_accreditation_references,
            // @ts-expect-error
            financial_disclosures,
            // @ts-expect-error
            learning_objectives
          },
          data?.data
        )
      }
    }).then(({ data }) => {
      data?.id !== undefined && navigate(paths.coursePath(data.id));
    });

  if (!data?.data) return <Spin />;

  return (
    <Card
      title={
        <CardHeader
          title={
            <>
              Edit course{' '}
              <Link to={paths.coursePath(data.data.id)}>{`${data.data.short_title}`}</Link>
            </>
          }
        />
      }
    >
      <CourseForm
        initialData={data.data}
        name="course-edit"
        onFinish={onFinish}
      />
    </Card>
  );
};
