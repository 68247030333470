import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, FormProps, Space, Spin } from 'antd';
import { Store } from 'antd/es/form/interface';
import React, { useState } from 'react';

import { InputFormContext } from './input_form_context';

type InputFormProps = FormProps & {
  form?: FormInstance;
  initialValues?: Store;
  name: string;
  onFinish: NonNullable<FormProps['onFinish']>;
  submitIsDisabled?: boolean;
};

const formItemLayout = {
  labelCol: {
    offset: 0,
    span: 6
  },
  wrapperCol: {
    offset: 0,
    span: 10
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    offset: 8,
    span: 16
  }
};

export const InputForm: React.FC<InputFormProps> = ({
  children,
  form,
  initialValues,
  name,
  onFinish,
  submitIsDisabled = false,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formInstance] = Form.useForm(form);

  const onReset = () => {
    formInstance.resetFields();
  };

  return (
    <InputFormContext.Provider value={formInstance}>
      <Form
        form={formInstance}
        initialValues={initialValues}
        name={name}
        onFinish={values => {
          setIsLoading(true);
          onFinish(values);
          setIsLoading(false);
        }}
        size="large"
        {...formItemLayout}
        scrollToFirstError
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          justifyItems: 'center'
        }}
        {...props}
      >
        <>
          {children}
          <Form.Item {...tailFormItemLayout}>
            <Space>
              <Button
                disabled={isLoading || submitIsDisabled}
                htmlType="submit"
                size="large"
                style={{ marginTop: '10px', width: '150px' }}
                type="primary"
              >
                {(isLoading && (
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/strict-boolean-expressions
                  <Spin
                    indicator={<LoadingOutlined style={{ color: 'white', marginLeft: '2px' }} />}
                  />
                )) ||
                  'Submit'}
              </Button>
              <Button
                danger
                htmlType="button"
                onClick={onReset}
                size="large"
                style={{ marginTop: '10px', width: '120px' }}
                type="primary"
              >
                Clear
              </Button>
            </Space>
          </Form.Item>
        </>
      </Form>
    </InputFormContext.Provider>
  );
};
