import {
  CourseCreatePage,
  CourseEditPage,
  CoursePage,
  CoursesImportCsvPage,
  CoursesPage
} from 'pages/courses';
import { RouteConfig } from 'react-router-config';
import * as paths from 'utils/paths';

export const courseRoutes: RouteConfig[] = [
  {
    component: CoursesPage,
    exact: true,
    path: paths.coursesPath
  },
  {
    breadcrumb: 'Import',
    component: CoursesImportCsvPage,
    path: paths.coursesImportCsvPath
  },
  {
    component: CourseCreatePage,
    path: paths.courseCreatePath
  },
  {
    component: CoursePage,
    exact: true,
    path: paths.coursePath(':id')
  },
  {
    breadcrumb: 'Edit',
    component: CourseEditPage,
    path: paths.courseEditPath(':id')
  }
];
