import { instance } from '.';

export interface GetTagResponse {
  id: string;
  name: string;
  type: string;
}

export const deleteTag = (id: string) => instance.delete<''>(`/admin/tags/${id}`);

export const getTagsFull = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetTagResponse[], IndexPageMeta>>('/admin/tags', {
      params: {
        ...getParams,
        page,
        per_page: pageSize
      }
    })
    .then(res => res.data);
