import { instance } from '.';

export interface GetOrganizationLicenseTiersResponse {
  available_licenses: number;
  created_at: string;
  id: string;
  license_tier: {
    id: string;
    name: string;
  };
  organization: {
    id: string;
    name: string;
  };
  total_licenses: number;
  updated_at: string;
}

export const getOrganizationLicenseTiers = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetOrganizationLicenseTiersResponse[], IndexPageMeta>>(
      '/admin/organization_license_tiers',
      {
        params: {
          ...getParams,
          page,
          per_page: pageSize
        }
      }
    )
    .then(res => res.data);
