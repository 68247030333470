import { Button, Popconfirm } from 'antd';
import type { AxiosResponse } from 'axios';
import type { Key } from 'react';

export interface BatchActionType {
  label: string;
  onClick: (ids: string[]) => Promise<AxiosResponse<''>[]>;
}

interface BatchActionsProps<T> {
  batchActions: BatchActionType[];
  onFinish: () => Promise<ApiResponse<T[], IndexPageMeta> | null>;
  selectedRowKeys: Key[];
}

export const BatchActions = <T,>({
  batchActions,
  onFinish,
  selectedRowKeys
}: BatchActionsProps<T>) => {
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      {batchActions.map(action => (
        <Popconfirm
          key={action.label}
          cancelText="No"
          okText="Yes"
          onConfirm={() => {
            action.onClick(selectedRowKeys as string[]).then(onFinish);
          }}
          title="Are you sure?"
        >
          <Button
            disabled={!hasSelected}
            style={{ marginBottom: '5px' }}
            type="primary"
          >
            {action.label}
          </Button>
        </Popconfirm>
      ))}
    </>
  );
};
