import { PlusOutlined } from '@ant-design/icons';
import { Card, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  getCourseCollections,
  GetCourseCollectionsResponse,
  updateCourseCollection,
  UpdateCourseCollectionParams
} from 'api';
import { StyledTitle } from 'components/cards';
import { ActionsColumn, DataTable } from 'components/tables';
import { useState } from 'react';
import { Link } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { courseCollectionColumns } from './course_collection_components';

export const CourseCollectionsPage = () => {
  const [isUpdating, setIsUpdating] = useState(false);

  const sendUpdateRequest = (
    record: GetCourseCollectionsResponse,
    collectionParams: UpdateCourseCollectionParams['course_collection']
  ) => {
    if (isUpdating) return;

    setIsUpdating(true);
    updateCourseCollection(record.id, {
      course_collection: collectionParams
    }).finally(() => {
      setIsUpdating(false);
      window.location.reload();
    });
  };

  const courseCollectionTableColumns: ColumnsType<GetCourseCollectionsResponse> = [
    ...courseCollectionColumns,
    {
      render: (_value, record) => (
        <ActionsColumn
          customItems={[
            {
              disabled: record.published === 'published' || isUpdating,
              key: 'publish',
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              label: <a>Publish</a>,
              onClick: () => sendUpdateRequest(record, { published: 'published' })
            },
            {
              danger: true,
              disabled: record.published === 'not_published' || isUpdating,
              key: 'unpublish',
              label: (
                <Popconfirm
                  cancelText="Cancel"
                  okText="Yes"
                  onConfirm={() => sendUpdateRequest(record, { published: 'not_published' })}
                  title="Are you sure you want to unpublish this collection? This will reset all completion progress for all users"
                >
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a>Unpublish</a>
                </Popconfirm>
              )
            }
          ]}
          editPath={paths.courseCollectionEditPath(record.id.toString())}
          record={record}
        />
      ),
      title: 'Actions'
    }
  ];
  return (
    <Card
      bordered={false}
      title={
        <StyledTitle
          buttonsContents={[
            <Link
              key="2"
              to={paths.courseCollectionCreatePath}
            >
              <PlusOutlined /> Create collection
            </Link>
          ]}
          title="Collections"
        />
      }
    >
      <DataTable
        columns={courseCollectionTableColumns}
        filters={[
          {
            key: 'name',
            label: 'Name'
          },
          {
            key: 'communities',
            label: 'Communities',
            multiple: true,
            options: [
              { text: 'bipoc', value: 'bipoc' },
              { text: 'lgbq', value: 'lgbq' },
              { text: 'tgnc', value: 'tgnc' }
            ]
          }
        ]}
        getMethod={getCourseCollections}
        perPage={15}
      />
    </Card>
  );
};
