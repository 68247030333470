import { Card, FormProps, Spin } from 'antd';
import { getLicenseTier, updateLicenseTier, useFetch } from 'api';
import { CardHeader } from 'components/cards';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { LicenseTierForm } from './license_tier_form';

export const LicenseTierEditPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(getLicenseTier, [id]);

  const navigate = useNavigate();

  const onFinish: FormProps<{
    name: string;
    organization_permissions: OrganizationPermission[];
    user_permissions: UserPermission[];
  }>['onFinish'] = values =>
    updateLicenseTier(id, { license_tier: values }).then(({ data }) => {
      data?.id !== undefined
        ? navigate(paths.licenseTierPath(data.id))
        : navigate(paths.licenseTiersPath);
    });

  if (!data?.data) return <Spin />;

  return (
    <Card title={<CardHeader title="Edit License Tier" />}>
      <LicenseTierForm
        initialData={data.data}
        name="license-tier-update"
        onFinish={onFinish}
      />
    </Card>
  );
};
