import { Image, ImageProps } from 'antd';

type ApiImageProps = ImageProps & {
  image: {
    url: string | null;
  };
};

export const ApiImage = ({ image, ...props }: ApiImageProps) =>
  image.url !== null ? (
    <Image
      {...props}
      src={`${process.env.REACT_APP_API_BASE_URL}${image.url}`}
    />
  ) : null;
