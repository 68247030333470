import {
  LicenseTierCreatePage,
  LicenseTierEditPage,
  LicenseTierPage,
  LicenseTiersPage
} from 'pages/license_tiers';
import { RouteConfig } from 'react-router-config';
import * as paths from 'utils/paths';

export const licenseTierRoutes: RouteConfig[] = [
  {
    breadcrumb: 'License Tiers',
    component: LicenseTiersPage,
    exact: true,
    path: paths.licenseTiersPath
  },
  {
    component: LicenseTierCreatePage,
    path: paths.licenseTierCreatePath
  },
  {
    component: LicenseTierPage,
    exact: true,
    path: paths.licenseTierPath(':id')
  },
  {
    component: LicenseTierEditPage,
    path: paths.licenseTierEditPath(':id')
  }
];
