import { instance } from '.';

interface CourseRating {
  comment: string | null;
  course: {
    id: string;
    short_title: string;
  };
  created_at: string;
  id: string;
  rating: 1 | 2 | 3 | 4 | 5;
  user: Pick<User, 'email' | 'first_name' | 'id' | 'last_name'>;
}

export interface GetCourseRatingsResponse extends CourseRating {}

export const getCourseRatings = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetCourseRatingsResponse[], IndexPageMeta>>('/admin/course_ratings', {
      params: {
        ...getParams,
        page,
        per_page: pageSize
      }
    })
    .then(res => res.data);
