import { Button, Card, Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { GetNpiRegistryDataResponse, updateNpiRegistryDatum } from 'api';
import { BooleanIcon } from 'components/boolean_icon';
import { CardEntryList } from 'components/cards/card_entry_list';
import { DateFormatter } from 'components/date_formatter';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useState } from 'react';

dayjs.extend(advancedFormat);

interface NpiRegistryDatumProps {
  initialDatum: GetNpiRegistryDataResponse;
}

const taxonomiesColumns: ColumnsType<GetNpiRegistryDataResponse['taxonomies'][0]> = [
  {
    dataIndex: 'is_primary',
    key: 'is_primary',
    render: (value: GetNpiRegistryDataResponse['taxonomies'][0]['is_primary']) => (
      <BooleanIcon value={value} />
    ),
    title: 'Primary Taxonomy'
  },
  {
    dataIndex: 'code',
    key: 'code',
    title: 'Code'
  },
  {
    dataIndex: 'description',
    key: 'description',
    title: 'Description'
  },
  {
    dataIndex: 'state_code',
    key: 'state_code',
    title: 'State'
  },
  {
    dataIndex: 'license_number',
    key: 'license_number',
    title: 'License Number'
  }
];

export const NpiRegistryDatumCard = ({ initialDatum }: NpiRegistryDatumProps) => {
  const [datum, setDatum] = useState(initialDatum);

  return (
    <div className="site-card-border-less-wrapper">
      <Card
        headStyle={{ fontWeight: 'bold' }}
        style={{ minHeight: 'calc(100vh - 111px)' }}
        title={
          <>
            <span
              style={{
                color: datum.is_matched && datum.is_successful ? 'green' : 'gray'
              }}
            >
              NPI Record from{' '}
              <b style={{ fontSize: 20 }}>
                <DateFormatter value={datum.updated_at} />
              </b>
            </span>

            {(!datum.is_successful || !datum.is_matched) && (
              <Popconfirm
                cancelText="No"
                okText="Yes"
                onConfirm={() =>
                  updateNpiRegistryDatum(datum.id, {
                    npi_registry_datum: {
                      is_matched: true,
                      is_successful: true
                    }
                  }).then(({ data }) => {
                    if (data) setDatum(data);
                  })
                }
                title="Are you sure you want to mark this as successful?"
              >
                <Button
                  style={{ marginLeft: '100px' }}
                  type="primary"
                >
                  Mark as successful
                </Button>
              </Popconfirm>
            )}
          </>
        }
      >
        <CardEntryList
          fieldsMap={[
            { key: 'id', title: 'ID' },
            { key: 'is_active', title: 'Is Active' },
            { key: 'npi', title: 'NPI' },
            { key: 'first_name', title: 'First Name' },
            { key: 'last_name', title: 'Last Name' },
            { key: 'is_successful', title: 'Is Successful' },
            { key: 'is_matched', title: 'Is Matched' },
            { key: 'mismatch_on_fields', title: 'Mismatched Fields' },
            { key: 'api_error_messages', title: 'Api Error Messages' },
            { key: 'credential_text', title: 'Credential Text' },
            { key: 'other_credential_text', title: 'Other Credential Text' },
            { key: 'gender_code', title: 'Gender Code' },
            { key: 'country_code', title: 'Country Code' },
            { key: 'state', title: 'State' },
            { key: 'city', title: 'City' },
            { key: 'enumeration_date', title: 'Enumeration Date' },
            { key: 'last_updated', title: 'Last Updated' },
            { key: 'deactivation_date', title: 'Deactivation Date' },
            {
              key: 'deactivation_reason_code',
              title: 'Deactivation Reason Code'
            }
          ]}
          values={datum}
        />
        <Table
          bordered
          columns={taxonomiesColumns}
          dataSource={datum.taxonomies.map(entry => ({
            key: entry.id,
            ...entry
          }))}
          pagination={false}
        />
      </Card>
    </div>
  );
};
