import styled from 'styled-components';

export const BodyRow = styled.tr`
  td {
    padding: 0.5rem;
  }

  &.dark-theme {
    td {
      border: 1px solid #63326e;
    }
  }

  &.light-theme {
    td {
      border: 1px solid #d1abd9;
    }
  }
`;

export const HeaderRow = styled.tr`
  text-align: left;

  &.dark-theme {
    background-color: #331a39;

    th {
      border: 1px solid #63326e;
    }
  }

  &.light-theme {
    background-color: #eee0f1;

    th {
      border: 1px solid #d1abd9;
    }
  }

  th {
    padding: 0.5rem;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
  border: none;
`;

export const TableWrapper = styled.div`
  margin: 1rem 0 2rem;
`;
