import { CourseRegistrationPage, CourseRegistrationsPage } from 'pages/course_registrations';
import { RouteConfig } from 'react-router-config';
import * as paths from 'utils/paths';

export const courseRegistrationRoutes: RouteConfig[] = [
  {
    component: CourseRegistrationsPage,
    exact: true,
    path: paths.courseRegistrationsPath
  },
  {
    component: CourseRegistrationPage,
    exact: true,
    path: paths.courseRegistrationPath(':id')
  }
];
