import { Card, FormProps } from 'antd';
import { createLicenseTier } from 'api';
import { CardHeader } from 'components/cards';
import { useNavigate } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { LicenseTierForm } from './license_tier_form';

export const LicenseTierCreatePage = () => {
  const navigate = useNavigate();

  const onFinish: FormProps<{
    name: string;
    organization_permissions?: OrganizationPermission[];
    user_permissions?: UserPermission[];
  }>['onFinish'] = values =>
    createLicenseTier({
      license_tier: values
    }).then(() => {
      navigate(paths.licenseTiersPath);
    });

  return (
    <Card title={<CardHeader title="Create License Tier" />}>
      <LicenseTierForm
        name="license-tier-create"
        onFinish={onFinish}
      />
    </Card>
  );
};
