import { Card, FormProps } from 'antd';
import { createFeatureFlag } from 'api';
import { CardHeader } from 'components/cards';
import { useNavigate } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { FeatureFlagForm } from './feature_flag_form';

export const FeatureFlagCreatePage = () => {
  const navigate = useNavigate();

  const onFinish: FormProps<{
    enabled: boolean | undefined;
    flag_name: string;
  }>['onFinish'] = values =>
    createFeatureFlag({ feature_flag: values }).then(() => {
      navigate(paths.featureFlagsPath);
    });

  return (
    <Card title={<CardHeader title="Create feature flag" />}>
      <FeatureFlagForm
        name="feature-toggle-create"
        onFinish={onFinish}
      />
    </Card>
  );
};
