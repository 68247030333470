import type { AxiosResponse } from 'axios';
import type { RequireAtLeastOne } from 'type-fest';

import { instance } from '.';

export interface GetNonClinicalExperienceResponse {
  bipoc: boolean;
  category: string;
  created_at: string;
  current: boolean;
  description: string | null;
  end_year: number;
  id: string;
  lgbq: boolean;
  organization: string;
  role: string;
  score: string;
  start_year: number;
  tgnc: boolean;
  updated_at: string;
  user_id: string;
}

interface UpdateNonClinicalExperienceParams {
  non_clinical_experience: RequireAtLeastOne<{
    bipoc: boolean;
    category: string;
    created_at: string;
    current: boolean;
    description: string | null;
    end_year: number;
    lgbq: boolean;
    organization: string;
    role: string;
    score: string;
    start_year: number;
    tgnc: boolean;
    updated_at: string;
  }>;
}

/*
  Add if use case is required
  ---------------------------
  export const deleteNonClinicalExperience = (id: string) =>
     instance.delete<''>(`/admin/non_clinical_experiences/${id}`);
*/

/*
  Add if use case is required
  ---------------------------
  export const getNonClinicalExperience = (id: string) =>
    instance
      .get<ApiResponse<GetNonClinicalExperienceResponse>>(`/admin/non_clinical_experiences/${id}`)
      .then(res => res.data);
*/

export const getNonClinicalExperiences = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetNonClinicalExperienceResponse[], IndexPageMeta>>(
      '/admin/non_clinical_experiences',
      {
        params: {
          ...getParams,
          page,
          per_page: pageSize
        }
      }
    )
    .then(res => res.data);

export const updateNonClinicalExperience = (
  id: string,
  params: UpdateNonClinicalExperienceParams
) =>
  instance.patch<'', AxiosResponse<''>, UpdateNonClinicalExperienceParams>(
    `/admin/non_clinical_experiences/${id}`,
    params
  );
