/* eslint-disable react/no-multi-comp */
import {
  CheckCircleOutlined,
  HolderOutlined,
  InfoCircleTwoTone,
  LinkOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  FormInstance,
  FormProps,
  Input,
  InputNumber,
  Modal,
  ModalProps,
  Row,
  Select,
  Space,
  Tooltip
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import {
  GetCourseCollectionResponse,
  getCourses,
  GetCoursesResponse,
  getOrganizations,
  VendorNames
} from 'api';
import { CardEntry } from 'components/cards';
import {
  InputForm,
  InputItem,
  SearchInputItem,
  SelectItem,
  useInputFormContext
} from 'components/forms';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { FinancialDisclosures } from '../../components/forms/items/financial_disclosures';

interface FormInstanceValues {
  communities: ScoreType[];
  courses: GetCoursesResponse[];
  credits: string;
  description: string;
  external_accreditation_references: {
    id: string;
    vendor_name: VendorNames;
    vendor_resource_id: string;
  }[];
  financial_disclosures: {
    disclosure_text: string;
    id: string;
  }[];
  level: 'advanced' | 'beginner' | 'foundation' | 'intermediate';
  name: string;
  published: 'not_published' | 'pinned' | 'published';
  specialties: string;
}

export interface CourseCollectionFormProps {
  initialData:
    | { data: { courses: never[]; published: 'published' }; page: 'create' }
    | { data: GetCourseCollectionResponse; page: 'edit' };
  name: string;
  onFinish: NonNullable<FormProps['onFinish']>;
}

const { Item } = Form;

export const CourseCollectionForm = ({
  initialData,
  name,
  onFinish
}: CourseCollectionFormProps) => {
  const [baseForm] = useForm<FormInstanceValues>();
  const [courseModalOpen, setCourseModalOpen] = useState(false);
  const [accreditationCount, setAccreditationCount] = useState(
    initialData.page === 'edit' ? initialData.data.external_accreditation_references.length : 0
  );

  return (
    <>
      <InputForm
        form={baseForm}
        initialValues={initialData.data}
        name={name}
        onFinish={onFinish}
      >
        <InputItem
          label="Name"
          name="name"
          required
        />
        <Item
          label="Description"
          name="description"
          required
        >
          <Input.TextArea />
        </Item>
        <InputItem
          label="Author(s)"
          name="authors"
          required
        />

        <Item label="Courses">
          <Form.List name="courses">
            {(fields, { move, remove }) => (
              <Space direction="vertical">
                <DndContext
                  onDragEnd={({ active, over }) => {
                    if (active.id !== over?.id) {
                      move(active.id as number, over?.id as number);
                    }
                  }}
                >
                  <SortableContext
                    items={fields.map(field => field.name)}
                    strategy={verticalListSortingStrategy}
                  >
                    <Space
                      direction="vertical"
                      style={{ width: '100%' }}
                    >
                      {fields.map(({ key, name }) => (
                        <SortableCourse
                          key={key}
                          id={name}
                          name={name}
                          onClickMinus={() => remove(name)}
                        />
                      ))}
                    </Space>
                  </SortableContext>
                </DndContext>

                <Item>
                  <Button
                    block
                    disabled={courseModalOpen}
                    icon={<PlusOutlined />}
                    onClick={() => setCourseModalOpen(true)}
                    type="dashed"
                  >
                    Add course
                  </Button>
                </Item>
              </Space>
            )}
          </Form.List>
        </Item>

        <Row>
          <Col
            className="title-column"
            span={24}
          >
            <h2>Collection Information</h2>
          </Col>
        </Row>

        <Row>
          <Col
            span={6}
            style={{
              paddingRight: '0.5rem',
              paddingTop: '0.55rem',
              textAlign: 'right'
            }}
          >
            Communities:
          </Col>
          <Item name="communities">
            <Checkbox.Group options={['bipoc', 'lgbq', 'tgnc']} />
          </Item>
        </Row>

        <Row>
          <Col
            span={6}
            style={{
              paddingRight: '0.5rem',
              paddingTop: '0.55rem',
              textAlign: 'right'
            }}
          >
            Specialties:
          </Col>
          <Item name="specialties">
            <Checkbox.Group options={['mental_health', 'physical_health']} />
          </Item>
        </Row>

        <SelectItem
          label="Level"
          name="level"
        >
          <Select.Option
            key="foundation"
            value="foundation"
          >
            Foundation
          </Select.Option>
          <Select.Option
            key="beginner"
            value="beginner"
          >
            Beginner
          </Select.Option>
          <Select.Option
            key="intermediate"
            value="intermediate"
          >
            Intermediate
          </Select.Option>
          <Select.Option
            key="advanced"
            value="advanced"
          >
            Advanced
          </Select.Option>
        </SelectItem>

        <Row>
          <Col
            className="title-column"
            span={24}
          >
            <h2>Accreditation</h2>
          </Col>
        </Row>

        <Item
          label="Credits"
          name="credits"
        >
          <InputNumber
            min="0"
            step="0.01"
          />
        </Item>

        {initialData.page === 'create' && accreditationCount > 0 && (
          <div className="center-item">
            <div className="info-block">
              <InfoCircleTwoTone
                className="info-icon"
                twoToneColor="#1971c3"
              />
              <strong style={{ marginRight: '0.25rem' }}>Collection not saving?</strong>
              <span style={{ fontWeight: 'normal', paddingLeft: '1.5rem' }}>
                Try creating it first and then adding the accreditation references on edit.{' '}
                <a
                  href="https://joinviolet.atlassian.net/browse/CRED-1670"
                  rel="noreferrer"
                  style={{ textDecoration: 'underline' }}
                  target="_blank"
                >
                  <LinkOutlined /> This is a known issue.
                </a>
              </span>
            </div>
          </div>
        )}
        <Item label="External accreditation references">
          <Form.List name="external_accreditation_references">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    align="baseline"
                    style={{ display: 'flex', marginBottom: 0 }}
                  >
                    <SelectItem
                      {...restField}
                      aria-label="Vendor name"
                      name={[name, 'vendor_name']}
                      placeholder="Vendor name"
                      style={{ minWidth: '150px' }}
                    >
                      <Select.Option
                        key="weitzman_institute"
                        value="weitzman_institute"
                      >
                        Weitzman Institute
                      </Select.Option>
                      <Select.Option
                        key="NBCC"
                        value="NBCC"
                      >
                        NBCC
                      </Select.Option>
                      <Select.Option
                        key="nysed_psychology"
                        value="nysed_psychology"
                      >
                        NYSED - Psychology
                      </Select.Option>
                      <Select.Option
                        key="nysed_mental_health_counseling"
                        value="nysed_mental_health_counseling"
                      >
                        NYSED - LMHC
                      </Select.Option>
                      <Select.Option
                        key="nysed_social_work"
                        value="nysed_social_work"
                      >
                        NYSED - LCSW/LMSW
                      </Select.Option>
                    </SelectItem>
                    <InputItem
                      {...restField}
                      label="Resource ID"
                      name={[name, 'vendor_resource_id']}
                    />
                    <MinusCircleOutlined
                      onClick={() => {
                        remove(name);
                        setAccreditationCount(accreditationCount - 1);
                      }}
                    />
                  </Space>
                ))}

                <Item>
                  <Button
                    block
                    icon={<PlusOutlined />}
                    onClick={() => {
                      add();
                      setAccreditationCount(accreditationCount + 1);
                    }}
                    type="dashed"
                  >
                    Add external reference
                  </Button>
                </Item>
              </>
            )}
          </Form.List>
        </Item>

        <FinancialDisclosures form={Form} />

        <SelectItem
          label="Publishing status"
          name="published"
        >
          <Select.Option
            key="not_published"
            value="not_published"
          >
            Un-publish
          </Select.Option>
          <Select.Option
            key="published"
            value="published"
          >
            Publish
          </Select.Option>
        </SelectItem>

        <Row>
          <Col
            span={6}
            style={{
              paddingRight: '0.5rem',
              paddingTop: '0.55rem',
              textAlign: 'right'
            }}
          >
            Access restriction:
            <Tooltip title="This collection will only be visible to active members in the selected organization and will not appear in searches or the all collections page.">
              <InfoCircleTwoTone style={{ marginLeft: '0.5rem' }} />
            </Tooltip>
          </Col>
          <SearchInputItem
            aria-label="Organization"
            getMethod={getOrganizations}
            getMethodArgs={[1, 20]}
            name="organization_id"
            paramsMethod={obj => ({
              key: obj.id,
              name: obj.name,
              value: obj.id
            })}
            required
            selectProps={{
              labelInValue: true
            }}
          />
        </Row>
      </InputForm>

      <NewCourseModal
        onCancel={() => setCourseModalOpen(false)}
        onFinish={course => {
          const { courses } = baseForm.getFieldsValue();
          baseForm.setFieldValue('courses', [...courses, course]);
        }}
        onOk={() => setCourseModalOpen(false)}
        open={courseModalOpen}
      />
    </>
  );
};

interface SortableCourseProps {
  id: React.Key;
  name: number;
  onClickMinus: () => void;
}

const SortableCourse: React.FC<SortableCourseProps> = ({
  id,
  name,
  onClickMinus
}: SortableCourseProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    transform: CSS.Transform.toString(transform),
    transition
  };

  const form = useInputFormContext<FormInstanceValues>();

  const [fieldsValue, setFieldsValue] =
    useState<typeof form extends FormInstance<infer X> ? X : never>();

  useEffect(() => {
    setFieldsValue(form.getFieldsValue());
  }, [form]);

  if (!fieldsValue) return null;

  const ceCredits = fieldsValue.courses[name].ce_credits;
  const courseId = fieldsValue.courses[name].id;
  const published = fieldsValue.courses[name].published;
  const shortTitle = fieldsValue.courses[name].short_title;

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <Card
        size="small"
        style={{
          borderColor: !Boolean(published) ? '#FF7F50' : '#303030',
          width: '100%'
        }}
      >
        <CardEntry title="Short title">
          <Link
            target="_blank"
            to={paths.coursePath(courseId)}
          >
            {shortTitle}
          </Link>
        </CardEntry>
        <CardEntry title="Credits">{ceCredits}</CardEntry>
        <CardEntry title="Published">
          {Boolean(published) ? (
            <CheckCircleOutlined />
          ) : (
            <>
              <WarningOutlined style={{ color: '#FF7F50' }} />{' '}
              <small>Course must be published to be added</small>
            </>
          )}
        </CardEntry>
      </Card>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <HolderOutlined
          style={{ margin: '5px' }}
          {...listeners}
        />
        <MinusCircleOutlined
          onClick={onClickMinus}
          style={{ margin: '5px' }}
        />
      </div>
    </div>
  );
};

interface NewCourseModalProps {
  onCancel: NonNullable<ModalProps['onCancel']>;
  onFinish: NonNullable<FormProps<GetCoursesResponse>['onFinish']>;
  onOk: NonNullable<ModalProps['onOk']>;
  open: boolean;
}

const NewCourseModal = ({ onCancel, onFinish, onOk, open }: NewCourseModalProps) => {
  const [form] = useForm();

  return (
    <Modal
      onCancel={onCancel}
      onOk={e => {
        onOk(e);
        form.submit();
      }}
      open={open}
    >
      <Form
        form={form}
        name="course_input"
        onFinish={({ course }: { course: GetCoursesResponse }) => {
          onFinish(course);
        }}
      >
        <InputItem
          hidden
          name="course"
        />
        <SearchInputItem
          filterName="title"
          getMethod={getCourses}
          getMethodArgs={[1, 20]}
          label="Course"
          name=""
          paramsMethod={obj => ({
            name: obj.short_title,
            value: JSON.stringify(obj)
          })}
          selectProps={{
            onChange: (json: string) => {
              form.setFieldValue('course', JSON.parse(json));
            },
            style: { width: '100%' }
          }}
          style={{ marginRight: '5px', width: '90%' }}
        />
      </Form>
    </Modal>
  );
};
