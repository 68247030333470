import { CheckCircleFilled, CloseCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Card, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { getOrganizations, GetOrganizationsResponse } from 'api';
import { StyledTitle } from 'components/cards';
import { ActionsColumn, DataTable } from 'components/tables';
import { Link } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

const columns: ColumnsType<GetOrganizationsResponse> = [
  {
    dataIndex: 'name',
    key: 'name',
    render: (value: GetOrganizationsResponse['name'], record) => (
      <Link to={paths.organizationPath(record.id)}>{value}</Link>
    ),
    title: 'Organization Name'
  },
  {
    children: [
      {
        dataIndex: 'invited_users',
        key: 'invited_users',
        title: 'Invited'
      },
      {
        dataIndex: 'accepted_count',
        key: 'accepted_count',
        title: 'Active'
      },
      {
        dataIndex: 'completed_onboarding',
        key: 'completed_onboarding',
        title: 'Onboarded'
      },
      {
        key: 'onboarded_percentage',
        render: (_value, record) => {
          if (record.invited_users === 0) return '-';
          return `${Math.round((record.completed_onboarding / record.invited_users) * 100)}%`;
        },
        title: 'Onboarded %'
      }
    ],
    dataIndex: 'users',
    title: 'Users'
  },
  {
    key: 'verified_inclusive',
    render: (_value, record) =>
      record.verified_inclusive ? (
        <Tag
          color="success"
          style={{ padding: '0 0.5rem 0 0.45rem' }}
        >
          <CheckCircleFilled /> Verified
        </Tag>
      ) : (
        <Tag
          color="default"
          style={{ padding: '0 0.5rem 0 0.45rem' }}
        >
          <CloseCircleFilled /> Unverified
        </Tag>
      ),
    title: 'VIO Status'
  },
  {
    render: (_value, record) => (
      <ActionsColumn
        editPath={paths.organizationEditPath(record.id.toString())}
        record={record}
        showPath={paths.organizationPath(record.id.toString())}
      />
    ),
    title: 'Actions'
  }
];

export const OrganizationsPage = () => (
  <Card
    bordered={false}
    title={
      <StyledTitle
        buttonsContents={
          <Link
            key="2"
            to={paths.organizationCreatePath}
          >
            <PlusOutlined /> Create Organization
          </Link>
        }
        title="Organizations"
      />
    }
  >
    <DataTable
      columns={columns}
      filters={[
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'network',
          label: 'Network org',
          options: [
            { text: '', value: undefined },
            { text: 'yes', value: true },
            { text: 'no', value: false }
          ]
        },

        {
          defaultValue: true,
          key: 'direct_contract',
          label: 'Direct contract',
          options: [
            { text: 'yes', value: true },
            { text: 'no', value: false }
          ]
        }
      ]}
      getMethod={getOrganizations}
      perPage={15}
    />
  </Card>
);
