import { ColumnsType } from 'antd/es/table';
import { GetLicenseTiersResponse } from 'api';
import { DateFormatter } from 'components/date_formatter';
import { ActionsColumn } from 'components/tables';
import * as paths from 'utils/paths';

export const licenseTierColumns: ColumnsType<GetLicenseTiersResponse> = [
  {
    dataIndex: 'name',
    key: 'name',
    title: 'Name'
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
    render: (value: GetLicenseTiersResponse['created_at']) => <DateFormatter value={value} />,
    title: 'Created at'
  },
  {
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: (value: GetLicenseTiersResponse['updated_at']) => <DateFormatter value={value} />,
    title: 'Updated at'
  },
  {
    render: (_value, record) => (
      <ActionsColumn
        editPath={paths.licenseTierEditPath(record.id)}
        record={record}
        showPath={paths.licenseTierPath(record.id)}
      />
    ),
    title: 'Actions',
    width: '60px'
  }
];
