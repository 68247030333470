import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, DatePickerProps, Divider, Form, FormProps } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { getNetwork, useFetch } from 'api';
import { batchCreateBookmarks } from 'api/bookmarks';
import { CardHeader } from 'components/cards';
import { InputForm } from 'components/forms';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { BookmarkAssignmentForm } from '../../components/forms/bookmark_assignment_form';

const { Item } = Form;

export const BookmarkNetworkAssignmentPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(getNetwork, [id]);
  const [form] = useForm();

  const onFinish: FormProps<{
    bookmarks: {
      end_date?: DatePickerProps['value'];
      resource_id: string;
      resource_type: 'Course' | 'CourseCollection';
      start_date?: DatePickerProps['value'];
    }[];
  }>['onFinish'] = ({ bookmarks }) =>
    batchCreateBookmarks({
      bookmarks: bookmarks.map(bookmark => ({
        ...bookmark,
        assigned_by_id: id,
        assigned_by_type: 'Network'
      }))
    }).then(() => navigate(paths.networkPath(id)));

  return (
    <Card
      title={
        <CardHeader
          title={
            <>
              Assign education to <Link to={paths.networkPath(id)}>{data?.data?.name}</Link>
            </>
          }
        />
      }
    >
      <InputForm
        form={form}
        name="assign-bookmark"
        onFinish={onFinish}
      >
        <Item label="Educations">
          <Form.List name="bookmarks">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name }) => (
                  <div key={key}>
                    <BookmarkAssignmentForm
                      assignedById={id}
                      name={name}
                      page="create"
                      parentForm={form}
                      remove={remove}
                    />

                    <Divider />
                  </div>
                ))}

                <Item>
                  <Button
                    block
                    icon={<PlusOutlined />}
                    onClick={() => add()}
                    type="dashed"
                  >
                    Add Resource
                  </Button>
                </Item>
              </>
            )}
          </Form.List>
        </Item>
      </InputForm>
    </Card>
  );
};
