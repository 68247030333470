import { PathwayCreatePage, PathwayEditPage, PathwayPage, PathwaysPage } from 'pages/pathways';
import { RouteConfig } from 'react-router-config';
import * as paths from 'utils/paths';

export const pathwayRoutes: RouteConfig[] = [
  {
    component: PathwaysPage,
    exact: true,
    path: paths.pathwaysPath
  },
  {
    component: PathwayCreatePage,
    path: paths.pathwayCreatePath
  },
  {
    component: PathwayEditPage,
    exact: true,
    path: paths.pathwayEditPath(':id')
  },
  {
    component: PathwayPage,
    exact: true,
    path: paths.pathwayPath(':id')
  }
];
