import { CommunicationInfoPage, CommunicationInfosPage } from 'pages/communication_infos';
import { RouteConfig } from 'react-router-config';
import * as paths from 'utils/paths';

export const communicationInfoRoutes: RouteConfig[] = [
  {
    component: CommunicationInfosPage,
    exact: true,
    path: paths.communicationInfosPath
  },
  {
    component: CommunicationInfoPage,
    exact: true,
    path: paths.communicationInfoPath(':id')
  }
];
