export const humanReadablePermission = (permission: OrganizationPermission | UserPermission) => (
  <>
    {permissionHelper(permission)} <span style={{ color: 'grey' }}>({permission})</span>
  </>
);

const permissionHelper = (permission: OrganizationPermission | UserPermission) => {
  switch (permission) {
    case 'api.read':
      return 'Can access our partner API';
    case 'evaluations.create':
      return 'Can evaluate accredited courses';
    case 'users.export':
      return 'Can download CSV users data';
  }
};
