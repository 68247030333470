import { FeatureFlagCreatePage, FeatureFlagEditPage, FeatureFlagsPage } from 'pages/feature_flags';
import { RouteConfig } from 'react-router-config';
import * as paths from 'utils/paths';

export const featureFlagRoutes: RouteConfig[] = [
  {
    breadcrumb: 'Feature flags',
    component: FeatureFlagsPage,
    exact: true,
    path: paths.featureFlagsPath
  },
  {
    breadcrumb: 'Create',
    component: FeatureFlagCreatePage,
    path: paths.featureFlagCreatePath
  },
  {
    breadcrumb: 'Edit',
    component: FeatureFlagEditPage,
    path: paths.featureFlagEditPath(':id')
  }
];
