import { Form, FormItemProps, Select, SelectProps, Spin } from 'antd';
import { useFetch } from 'api';
import { useDebounce } from 'components/debounce';

type SearchInputItemProps<T, U> = FormItemProps & {
  className?: string;
  filterName?: string;
  getMethod: (
    page?: string,
    pageSize?: string,
    getParams?: object
  ) => Promise<ApiResponse<T[], IndexPageMeta>>;
  getMethodArgs: [number, number, object?];
  label?: string;
  name?: (number | string)[] | number | string;
  paramsMethod: (obj: T) => { name: string; value: string };
  selectProps?: SelectProps<U>;
};

export const SearchInputItem = <T, U>({
  className,
  filterName = 'name',
  getMethod,
  getMethodArgs,
  label,
  name,
  paramsMethod,
  selectProps = {
    style: { width: '200px' }
  },
  style
}: SearchInputItemProps<T, U>) => {
  const [debouncedValue, setCurValue] = useDebounce<Record<string, string> | undefined>(
    undefined,
    500
  );
  const { data, isLoading } = useFetch(getMethod, [
    `${getMethodArgs[0]}`,
    `${getMethodArgs[1]}`,
    { ...debouncedValue, ...getMethodArgs[2] }
  ]);

  const handleSearch: SelectProps['onSearch'] = value => {
    setCurValue({ [filterName]: value });
  };

  return (
    <Form.Item
      className={className}
      label={label}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      name={name}
      style={style}
    >
      <Select
        allowClear
        notFoundContent={isLoading ? <Spin size="small" /> : null}
        onSearch={handleSearch}
        optionFilterProp="children"
        showSearch
        {...(selectProps.style ? selectProps : { ...selectProps, style: { width: '200px' } })}
      >
        {data?.data?.map(object => {
          const { name, value } = paramsMethod(object);

          return (
            <Select.Option
              key={value}
              value={value}
            >
              {name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};
