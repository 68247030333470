import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Card, Checkbox, Form, FormProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  exportEducationAssessmentsCsvFile,
  getEducations,
  GetEducationsResponse,
  updateEducationBatch,
  UpdateEducationBatchParams
} from 'api';
import { ApiImage } from 'components/api_image';
import { StyledTitle } from 'components/cards';
import { UserLink } from 'components/common';
import { DateFormatter } from 'components/date_formatter';
import { InputForm } from 'components/forms';
import { PdfLink } from 'components/pdf_link';
import { DataTable } from 'components/tables';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { importEducationAssessmentsPath } from 'utils/paths';

import { EditCreditColumn } from './edit_credit_column';

const { Item } = Form;

const educationsColumns: ColumnsType<GetEducationsResponse> = [
  {
    dataIndex: 'course_name',
    key: 'course_name',
    title: 'Course'
  },
  {
    dataIndex: 'organization',
    key: 'organization',
    title: 'Organization'
  },
  {
    dataIndex: 'year',
    key: 'year',
    title: 'Year'
  },
  {
    dataIndex: 'credits',
    key: 'credits',
    render: (value: GetEducationsResponse['credits'], { id }) => (
      <EditCreditColumn
        credit={value}
        id={id}
      />
    ),
    title: 'Credits'
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
    render: (value: GetEducationsResponse['created_at']) => <DateFormatter value={value} />,
    title: 'Created at'
  },
  {
    dataIndex: 'user',
    key: 'user',
    render: (value: GetEducationsResponse['user']) => <UserLink user={value} />,
    title: 'User',
    width: '130px'
  },
  {
    dataIndex: 'image',
    key: 'image',
    render: (value: GetEducationsResponse['image']) => {
      if (value.url === null) return 'No file attached';
      return value.url.endsWith('.pdf') ? <PdfLink url={value.url} /> : <ApiImage image={value} />;
    },
    title: 'File',
    width: '130px'
  },
  {
    dataIndex: 'bipoc_tag',
    key: 'bipoc_tag',
    render: (value: GetEducationsResponse['bipoc_tag'], { id }) => (
      <Item
        initialValue={value}
        name={[id, 'bipoc_tag']}
        style={{ margin: 'auto auto', padding: 0 }}
        valuePropName="checked"
      >
        <Checkbox />
      </Item>
    ),
    title: 'BIPOC'
  },
  {
    dataIndex: 'lgbq_tag',
    key: 'lgbq_tag',
    render: (value: GetEducationsResponse['lgbq_tag'], { id }) => (
      <Item
        initialValue={value}
        name={[id, 'lgbq_tag']}
        style={{ margin: 'auto auto', padding: 0 }}
        valuePropName="checked"
      >
        <Checkbox />
      </Item>
    ),
    title: 'LGBQ'
  },
  {
    dataIndex: 'tgnc_tag',
    key: 'tgnc_tag',
    render: (value: GetEducationsResponse['tgnc_tag'], { id }) => (
      <Item
        initialValue={value}
        name={[id, 'tgnc_tag']}
        style={{ margin: 'auto auto', padding: 0 }}
        valuePropName="checked"
      >
        <Checkbox />
      </Item>
    ),
    title: 'TGNC'
  },
  {
    dataIndex: 'assessed',
    key: 'assessed',
    render: (value: GetEducationsResponse['assessed'], { id }) => (
      <Item
        initialValue={value}
        name={[id, 'assessed']}
        style={{ margin: 'auto auto', padding: 0 }}
        valuePropName="checked"
      >
        <Checkbox />
      </Item>
    ),
    title: 'Assessed'
  }
];

export const EducationBenchmarkPage = () => {
  const [needToReload, setNeedToReload] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const formatDate = (date: Date) =>
    date
      .toLocaleDateString('en-US', {
        month: '2-digit',
        // eslint-disable-next-line sort-keys
        day: '2-digit',
        year: 'numeric'
      })
      .replace(/\//g, '_');

  useEffect(() => {
    setNeedToReload(false);
    window.scrollTo(0, 0);
  }, [needToReload]);

  const onFinish: FormProps<
    Record<string, Pick<GetEducationsResponse, 'assessed' | 'bipoc_tag' | 'lgbq_tag' | 'tgnc_tag'>>
  >['onFinish'] = educations => {
    const body = Object.entries(educations).reduce<UpdateEducationBatchParams['educations']>(
      (body, [id, params]) => {
        body.push({ ...params, id });
        return body;
      },
      []
    );

    return updateEducationBatch({ educations: body }).then(() => {
      setNeedToReload(true);
    });
  };

  const onExport = async () => {
    setIsExporting(true);
    const response = await exportEducationAssessmentsCsvFile();
    const csvContent = new Blob([response as BlobPart], { type: 'text/csv' });

    const csvUrl = window.URL.createObjectURL(csvContent);

    const link = document.createElement('a');
    link.href = csvUrl;
    link.setAttribute('download', `unassesed_educations_export_${formatDate(new Date())}.csv`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(csvUrl);
    setIsExporting(false);
  };

  return (
    <Card
      bordered={false}
      title={
        <StyledTitle
          buttonsContents={[
            <button
              key="1"
              disabled={isExporting}
              onClick={onExport}
              style={{
                appearance: 'none',
                background: 'none',
                border: 'none',
                color: '#fff',
                cursor: 'pointer',
                fontSize: '16px'
              }}
            >
              <DownloadOutlined /> {isExporting ? 'Exporting...' : 'Export CSV'}
            </button>,
            <Link
              key="2"
              to={importEducationAssessmentsPath}
            >
              <UploadOutlined /> Import CSV
            </Link>
          ]}
          title="Education benchmark"
        />
      }
    >
      <InputForm
        name="test"
        onFinish={onFinish}
      >
        <DataTable
          columns={educationsColumns}
          getMethod={getEducations}
          getParams={{
            assessed: false,
            completed_onboarding: true,
            user_entered: true
          }}
          perPage={50}
        />
      </InputForm>
    </Card>
  );
};
