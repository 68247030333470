import {
  BookmarkOrgAssignmentEditPage,
  BookmarkOrgAssignmentPage,
  LicenseCohortCreatePage,
  OrganizationBulkAddPage,
  OrganizationBulkRemovalPage,
  OrganizationCreatePage,
  OrganizationEditPage,
  OrganizationPage,
  OrganizationReportsPage,
  OrganizationsPage,
  OrganizationTransferPage
} from 'pages/organizations';
import { RouteConfig } from 'react-router-config';
import * as paths from 'utils/paths';

export const organizationRoutes: RouteConfig[] = [
  {
    breadcrumb: 'Organizations',
    component: OrganizationsPage,
    exact: true,
    path: paths.organizationsPath
  },
  {
    component: OrganizationCreatePage,
    path: paths.organizationCreatePath
  },
  {
    component: OrganizationTransferPage,
    path: paths.organizationTransferPath()
  },
  {
    component: OrganizationPage,
    exact: true,
    path: paths.organizationPath(':id')
  },
  {
    component: OrganizationEditPage,
    path: paths.organizationEditPath(':id')
  },
  {
    component: OrganizationReportsPage,
    path: paths.organizationReportsPath(':id')
  },
  {
    component: LicenseCohortCreatePage,
    path: paths.licenseCohortCreatePath(':id')
  },
  {
    component: BookmarkOrgAssignmentPage,
    path: paths.bookmarkOrgAssignmentPath(':id')
  },
  {
    component: OrganizationBulkRemovalPage,
    path: paths.organizationBulkRemovalPath(':id')
  },
  {
    component: OrganizationBulkAddPage,
    path: paths.organizationBulkAddPath(':id')
  },
  {
    component: BookmarkOrgAssignmentEditPage,
    path: paths.bookmarkOrgAssignmentEditPath(':orgId', ':id')
  }
];
