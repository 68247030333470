/* eslint-disable jsx-a11y/anchor-is-valid */
import { MailTwoTone } from '@ant-design/icons';
import { Popconfirm, Tooltip } from 'antd';
import { ColumnType } from 'antd/es/table';
import { deleteBookmark, GetBookmarksResponse } from 'api';
import { DateFormatter } from 'components/date_formatter';
import { ActionsColumn } from 'components/tables';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  bookmarkNetworkAssignmentEditPath,
  bookmarkOrgAssignmentEditPath,
  courseCollectionEditPath,
  coursePath,
  userPath
} from '../utils/paths';

export const bookmarksColumns: (
  setRefetchTrigger: Dispatch<SetStateAction<number>>
) => ColumnType<GetBookmarksResponse>[] = (setRefetchTrigger: Dispatch<SetStateAction<number>>) => [
  {
    key: 'resource',
    render: (_value, bookmark: GetBookmarksResponse) =>
      'name' in bookmark.resource ? (
        <Link to={courseCollectionEditPath(bookmark.resource_id)}>{bookmark.resource.name}</Link>
      ) : (
        <Link to={coursePath(bookmark.resource_id)}>{bookmark.resource.short_title}</Link>
      ),
    title: 'Resource'
  },
  {
    dataIndex: 'resource_type',
    key: 'resource_type',
    title: 'Resource type'
  },
  {
    dataIndex: 'start_date',
    key: 'start_date',
    render: (value: GetBookmarksResponse['start_date'], record: GetBookmarksResponse) => {
      const isPast = dayjs().isAfter(dayjs(record.email_send_date ?? record.start_date));
      return (
        <>
          <DateFormatter value={value!} />
          {record.email_send_date !== null && (
            <Tooltip
              title={`Email ${isPast ? 'sent' : 'scheduled'} ${dayjs(record.email_send_date).format(
                'MM/DD/YYYY HH:mm z'
              )}`}
            >
              <MailTwoTone
                style={{ marginLeft: '0.25rem' }}
                twoToneColor="#999"
              />
            </Tooltip>
          )}
        </>
      );
    },
    title: 'Start date'
  },
  {
    dataIndex: 'end_date',
    key: 'end_date',
    render: (value: GetBookmarksResponse['end_date']) => <DateFormatter value={value!} />,
    title: 'End date'
  },
  {
    dataIndex: 'user_name',
    key: 'user_name',
    render: (value: GetBookmarksResponse['user_name'], record: GetBookmarksResponse) =>
      value !== null && record.user_id !== null ? (
        <Link to={userPath(record.user_id)}>{value}</Link>
      ) : (
        'All users'
      ),
    title: 'Assigned to'
  },
  {
    key: 'actions',
    render: (value: GetBookmarksResponse, record: GetBookmarksResponse) => (
      <ActionsColumn
        customItems={[
          {
            key: 'edit',
            label: (
              <Link
                to={
                  value.assigned_by_type === 'Organization'
                    ? bookmarkOrgAssignmentEditPath(value.assigned_by_id, value.id)
                    : bookmarkNetworkAssignmentEditPath(value.assigned_by_id, record.id)
                }
              >
                Edit
              </Link>
            )
          },
          {
            danger: true,
            key: 'delete',
            label: (
              <Popconfirm
                cancelText="No"
                okText="Yes"
                onConfirm={() => {
                  deleteBookmark(record.id).then(() => {
                    toast.success('Bookmark deleted');
                    setRefetchTrigger(prev => prev + 1);
                  });
                }}
                title="Are you sure you want to delete this record?"
              >
                <a>Delete</a>
              </Popconfirm>
            )
          }
        ]}
        record={record}
      />
    ),
    title: 'Actions'
  }
];
