import type { AxiosResponse } from 'axios';

import { instance } from '.';

export interface GetEducationsResponse {
  assessed: boolean;
  assessed_at: null;
  bipoc_tag: boolean;
  course_id: string | null;
  course_name: string;
  created_at: string;
  credits: string;
  id: string;
  image: {
    url: string | null;
  };
  lgbq_tag: boolean;
  organization: string;
  tgnc_tag: boolean;
  updated_at: string;
  user: {
    email: string;
    first_name: string;
    id: string;
    last_name: string;
  };
  year: number;
}

export interface UpdateEducationBatchParams {
  educations: {
    assessed?: boolean;
    bipoc_tag?: boolean;
    id: string;
    lgbq_tag?: boolean;
    tgnc_tag?: boolean;
  }[];
}

export const exportEducationAssessmentsCsvFile = () =>
  instance
    .get<ApiResponse<BlobPart>>('/admin/educations/assessments_export', { responseType: 'blob' })
    .then(res => res.data);

export const getEducations = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetEducationsResponse[], IndexPageMeta>>('/admin/educations', {
      params: {
        ...getParams,
        page,
        per_page: pageSize
      }
    })
    .then(res => res.data);

export const importEducationAssessmentsCsvFile = (params: FormData) =>
  instance
    .post<ImportEducationAssessmentResponse>('/admin/educations/assessments_import', params)
    .then(res => res.data);

export const updateEducationBatch = (params: UpdateEducationBatchParams) =>
  instance.patch<'', AxiosResponse<''>, UpdateEducationBatchParams>(
    '/admin/educations/batch',
    params
  );
