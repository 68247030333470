import { Form, Select, SelectProps } from 'antd';
import { useFetch } from 'api';
import { useState } from 'react';

interface TagsInputItemProps {
  getMethod: (type: string, name: string | null) => Promise<ApiResponse<string[]>>;
  getMethodArgs: [string];
  label: string;
  name: string;
}

export const TagsInputItem = ({ getMethod, getMethodArgs, label, name }: TagsInputItemProps) => {
  const [curValue, setCurValue] = useState<string | null>(null);
  const { data } = useFetch(getMethod, [...getMethodArgs, curValue]);

  const handleSearch: SelectProps['onSearch'] = value => {
    setCurValue(value === '' ? null : value);
  };

  return (
    <Form.Item
      label={label}
      name={name}
    >
      <Select
        allowClear
        mode="tags"
        onSearch={handleSearch}
      >
        {data?.data?.map(name => (
          <Select.Option
            key={name}
            value={name}
          >
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
