import type { AxiosResponse } from 'axios';
import type { RequireAtLeastOne } from 'type-fest';

import { instance } from '.';

interface CreateFeatureFlagParams {
  feature_flag: {
    enabled?: boolean;
    flag_name: string;
  };
}

interface GetFeatureFlagResponse {
  created_at: string;
  enabled: boolean;
  flag_name: string;
  id: string;
  last_flagged_at: string;
  updated_at: string;
}

export interface GetFeatureFlagsResponse {
  created_at: string;
  enabled: boolean;
  flag_name: string;
  id: string;
  last_flagged_at: string;
  updated_at: string;
}

interface UpdateFeatureFlagParams {
  feature_flag: RequireAtLeastOne<{
    enabled: boolean;
    flag_name: string;
  }>;
}

export const createFeatureFlag = (params: CreateFeatureFlagParams) =>
  instance.post<'', AxiosResponse<''>, CreateFeatureFlagParams>('/admin/feature_flags', params);

export const deleteFeatureFlag = (id: string) => instance.delete<''>(`/admin/feature_flags/${id}`);

export const getFeatureFlag = (id: string) =>
  instance
    .get<ApiResponse<GetFeatureFlagResponse>>(`/admin/feature_flags/${id}`)
    .then(res => res.data);

export const getFeatureFlags = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetFeatureFlagsResponse[], IndexPageMeta>>('/admin/feature_flags', {
      params: {
        ...getParams,
        page,
        per_page: pageSize
      }
    })
    .then(res => res.data);

export const updateFeatureFlag = (id: string, params: UpdateFeatureFlagParams) =>
  instance.patch<'', AxiosResponse<''>, UpdateFeatureFlagParams>(
    `/admin/feature_flags/${id}`,
    params
  );
