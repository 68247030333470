import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Popconfirm, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { deleteFeatureFlag, getFeatureFlags, GetFeatureFlagsResponse } from 'api';
import { StyledTitle } from 'components/cards';
import { DateFromNowFormatter } from 'components/date_from_now_formatter';
import { DataTable } from 'components/tables';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Link, useNavigate } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';
dayjs.extend(relativeTime);

export const FeatureFlagsPage = () => {
  const navigate = useNavigate();

  const flagColumns: ColumnsType<GetFeatureFlagsResponse> = [
    {
      dataIndex: 'flag_name',
      key: 'flag_name',
      title: 'Flag name'
    },
    {
      dataIndex: 'enabled',
      key: 'enabled',
      render: (value: GetFeatureFlagsResponse['enabled'], record) => {
        const hasBeenOnAWhile = dayjs(record.last_flagged_at).isBefore(
          dayjs().subtract(14, 'days')
        );
        return (
          <div>
            <Tag
              key={value ? 'flag-true' : ' flag-false'}
              color={value ? 'green' : 'red'}
            >
              {value ? 'Enabled' : 'Disabled'}
            </Tag>
            {value && hasBeenOnAWhile && (
              <div className="delete-flag">
                <em>consider deleting; enabled {'>'} 14 days ago</em>
              </div>
            )}
          </div>
        );
      },
      title: 'Enabled'
    },
    {
      dataIndex: 'last_flagged_at',
      key: 'last_flagged_at',
      render: (value: GetFeatureFlagsResponse['last_flagged_at']) =>
        value ? <DateFromNowFormatter value={value} /> : '--',
      title: 'Last toggled at'
    },
    {
      dataIndex: 'id',
      key: 'actions',
      render: (value: GetFeatureFlagsResponse['id']) => (
        <>
          <Button
            size="small"
            type="default"
          >
            <Link
              to={{
                pathname: paths.featureFlagEditPath(value)
              }}
            >
              Edit
            </Link>
          </Button>
          <Popconfirm
            cancelText="Cancel"
            okText="Yes"
            onConfirm={() => deleteFeatureFlag(value).then(() => navigate(0))}
            title="Are you sure you want to delete this flag?"
          >
            <Button
              className="ant-btn-danger"
              size="small"
              style={{ marginLeft: '0.5rem' }}
            >
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
      title: 'Actions'
    }
  ];

  return (
    <Card
      bordered={false}
      title={
        <StyledTitle
          buttonsContents={[
            <Link
              key="2"
              to={paths.featureFlagCreatePath}
            >
              <PlusOutlined /> Create feature flag
            </Link>
          ]}
          title="Feature flags"
        />
      }
    >
      <Card
        bordered={false}
        title="Feature Flags"
      >
        <DataTable
          columns={flagColumns}
          filters={[
            {
              key: 'enabled',
              label: 'Enabled',
              options: [
                { text: '', value: null },
                { text: 'Yes', value: true },
                { text: 'No', value: false }
              ]
            },
            {
              key: 'flag_name',
              label: 'Name'
            }
          ]}
          getMethod={getFeatureFlags}
          perPage={20}
        />
      </Card>
    </Card>
  );
};
