import { EducationBenchmarkPage, ImportEducationAssessmentsPage } from 'pages/educations';
import { RouteConfig } from 'react-router-config';
import * as paths from 'utils/paths';

export const educationRoutes: RouteConfig[] = [
  {
    component: EducationBenchmarkPage,
    exact: true,
    path: paths.educationBenchmarkPath
  },
  {
    component: ImportEducationAssessmentsPage,
    exact: true,
    path: paths.importEducationAssessmentsPath
  }
];
