import { ArrowRightOutlined } from '@ant-design/icons';
import { Space, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { GetScoresResponse, GetUsersResponse } from 'api';
import { ApiImage } from 'components/api_image';
import { BooleanIcon } from 'components/boolean_icon';
import { UserLink } from 'components/common';
import { DateFormatter } from 'components/date_formatter';
import { ActionsColumn } from 'components/tables';
import { MemberRoleTag } from 'components/tags/member_role_tag';
import { AlignType } from 'rc-table/lib/interface';
import { Link } from 'react-router-dom';
import * as paths from 'utils/paths';

export const scoreColumns: ColumnsType<GetScoresResponse> = [
  {
    dataIndex: 'image',
    key: 'image',
    render: (value: GetScoresResponse['image']) => <ApiImage image={value} />,
    title: 'Image',
    width: 60
  },
  {
    dataIndex: 'score_type',
    key: 'score_type',
    title: 'Community'
  },
  {
    dataIndex: 'score_level',
    key: 'score_level',
    title: 'Level'
  },
  {
    dataIndex: 'total_score',
    key: 'total_score',
    title: 'Total raw score',
    width: '12%'
  },
  {
    dataIndex: 'lived_experience_score',
    key: 'lived_experience_score',
    title: 'Lived exp. score',
    width: '10%'
  },
  {
    dataIndex: 'community_of_interest_score',
    key: 'community_of_interest_score',
    title: 'Com. of. int. score',
    width: '10%'
  },
  {
    dataIndex: 'professional_experience_score',
    key: 'professional_experience_score',
    title: 'Prof. exp. score',
    width: '10%'
  },
  {
    dataIndex: 'education_score',
    key: 'education_score',
    title: 'Education score',
    width: '10%'
  },
  {
    dataIndex: 'non_clinical_experience_score',
    key: 'non_clinical_experience_score',
    title: 'Non-clinical exp. score',
    width: '10%'
  },
  {
    dataIndex: 'earned_at',
    key: 'earned_at',
    render: (value: GetScoresResponse['earned_at']) => (
      <DateFormatter
        format="MM/DD/YYYY"
        hideTimezone
        value={value}
      />
    ),
    title: 'Earned at'
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
    render: (value: GetScoresResponse['created_at']) => (
      <DateFormatter
        format="MM/DD/YYYY"
        hideTimezone
        value={value}
      />
    ),
    title: 'Scored at'
  }
];

export const userColumns: ColumnsType<GetUsersResponse> = [
  {
    key: 'full_name',
    render: (_value, record) => <UserLink user={record} />,
    title: 'Name'
  },
  {
    align: 'center' as AlignType,
    dataIndex: 'status',
    key: 'status',
    render: (value: GetUsersResponse['status']) => {
      let color = 'gray';
      if (value === 'activated') color = 'green';
      if (value === 'deactivated') color = 'red';
      return <Tag color={color}>{value}</Tag>;
    },
    title: 'Status',
    width: '60px'
  },
  {
    align: 'center' as AlignType,
    dataIndex: 'completed_onboarding',
    key: 'completed_onboarding',
    render: (value: GetUsersResponse['completed_onboarding']) => <BooleanIcon value={value} />,
    title: 'Onboarded',
    width: '60px'
  },
  {
    align: 'center' as AlignType,
    dataIndex: 'is_clinical',
    key: 'is_clinical',
    render: (value: GetUsersResponse['is_clinical']) => (
      <Tag color={value ? 'blue' : 'red'}>{value ? 'clinical' : 'non-clinical'}</Tag>
    ),
    title: 'Clinical',
    width: '50px'
  },
  {
    align: 'center' as AlignType,
    key: 'roles',
    render: (_value, record) => (
      <Space direction="vertical">
        <Tag color={record.role === 'admin' ? 'gold' : 'blue'}>{record.role}</Tag>
        {record.member_roles.map(role => (
          <MemberRoleTag
            key={role}
            value={role}
          />
        ))}
      </Space>
    ),
    title: 'Roles',
    width: '60px'
  },
  {
    align: 'center' as AlignType,
    key: 'organization_memberships',
    render: (_memberships, record) => (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '0' }}>
        {record.organization_memberships.map(membership => (
          <ActionsColumn
            buttonText={membership.organization_name}
            customItems={[
              {
                key: `${membership.id}-status`,
                label: (
                  <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <span style={{ width: '20%' }}>Status:</span>
                    <Tag color={membership.status === 'activated' ? 'green' : 'blue'}>
                      {membership.status}
                    </Tag>
                  </div>
                )
              },
              {
                key: `${membership.id}-role`,
                label: (
                  <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <span style={{ width: '20%' }}>Role:</span>
                    <MemberRoleTag value={membership.member_role} />
                  </div>
                )
              },
              {
                key: `${membership.id}-org`,
                label: (
                  <Link to={paths.organizationPath(membership.organization_id)}>
                    <ArrowRightOutlined /> View Organization
                  </Link>
                )
              }
            ]}
            isInline
            record={membership}
          />
        ))}
      </div>
    ),
    title: 'Organizations'
  },
  {
    dataIndex: 'sign_up_source',
    key: 'sign_up_source',
    render: (value: GetUsersResponse['sign_up_source']) => value ?? '--',
    title: 'Sign-up source'
  },
  {
    render: (_value, record) => (
      <ActionsColumn
        customPaths={{ Score: paths.userScoringPath(record.id) }}
        editPath={paths.userEditPath(record.id)}
        record={record}
        showPath={paths.userPath(record.id)}
      />
    ),
    title: 'Actions',
    width: '60px'
  }
];
