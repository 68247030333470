import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { getCourses } from 'api';
import { StyledTitle } from 'components/cards';
import { DataTable } from 'components/tables';
import { Link } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { courseColumns } from './course_components';

export const CoursesPage = () => (
  <Card
    bordered={false}
    title={
      <StyledTitle
        buttonsContents={[
          <Link
            key="1"
            to={paths.coursesImportCsvPath}
          >
            <UploadOutlined /> Import CSV
          </Link>,
          <Link
            key="2"
            to={paths.courseCreatePath}
          >
            <PlusOutlined /> Create Course
          </Link>
        ]}
        title="Courses"
      />
    }
  >
    <DataTable
      columns={courseColumns}
      filters={[
        {
          key: 'published',
          label: 'Published',
          options: [
            { text: '', value: undefined },
            { text: 'True', value: true },
            { text: 'False', value: false }
          ]
        },
        {
          key: 'title',
          label: 'Title'
        }
      ]}
      getMethod={getCourses}
      perPage={15}
    />
  </Card>
);
