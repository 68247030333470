import { Card, FormProps } from 'antd';
import { createCourseCollection, GetCoursesResponse, VendorNames } from 'api';
import { CardHeader } from 'components/cards';
import { useNavigate } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { CourseCollectionForm, CourseCollectionFormProps } from './course_collection_form';

export const CourseCollectionCreatePage = () => {
  const navigate = useNavigate();

  const onFinish: FormProps<{
    authors: string;
    communities: ScoreType[];
    courses: GetCoursesResponse[];
    credits: number;
    description: string;
    external_accreditation_references: {
      vendor_name: VendorNames;
      vendor_resource_id: string;
    }[];
    financial_disclosures: {
      disclosure_text: string;
    }[];
    level: 'advanced' | 'beginner' | 'foundation' | 'intermediate';
    name: string;
    organization_id?: {
      key: string;
      label: string;
      value: string;
    } | null;
    published: 'not_published' | 'pinned' | 'published';
    specialties: string[];
  }>['onFinish'] = ({
    courses,
    external_accreditation_references,
    financial_disclosures,
    organization_id,
    ...values
  }) =>
    createCourseCollection({
      course_collection: {
        course_ids: courses.map(course => course.id),
        external_accreditation_references_attributes: external_accreditation_references,
        financial_disclosures_attributes: financial_disclosures,
        organization_id:
          organization_id === undefined
            ? ' '
            : organization_id === null
            ? null
            : organization_id.value,
        ...values
      }
    }).then(() => navigate(paths.courseCollectionsPath));

  const initialData: CourseCollectionFormProps['initialData'] = {
    data: { courses: [], published: 'published' },
    page: 'create'
  };

  return (
    <Card title={<CardHeader title="Create collection" />}>
      <CourseCollectionForm
        initialData={initialData}
        name="course-collection-create"
        onFinish={onFinish}
      />
    </Card>
  );
};
