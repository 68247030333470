import { Card, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { getNpiRegistryData, GetNpiRegistryDataResponse } from 'api';
import { StyledTitle } from 'components/cards';
import { DataTable } from 'components/tables';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Link } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { NpiRegistryDatumCard } from './npi_registry_datum';

dayjs.extend(advancedFormat);

const columns: ColumnsType<GetNpiRegistryDataResponse> = [
  {
    dataIndex: 'user',
    key: 'user',
    render: ({ first_name, id, last_name }: GetNpiRegistryDataResponse['user']) => (
      <Link to={paths.userPath(id)}>{`${first_name} ${last_name}`}</Link>
    ),
    title: 'User'
  },
  {
    dataIndex: 'user',
    key: 'user',
    render: ({ user_info }: GetNpiRegistryDataResponse['user']) => user_info.npi,
    title: 'NPI (User)'
  },
  {
    dataIndex: 'npi',
    key: 'npi',
    render: (value: GetNpiRegistryDataResponse['npi']) => value,
    title: 'NPI (Registry)'
  },
  {
    dataIndex: 'mismatch_on_fields',
    key: 'mismatch_on_fields',
    render: (value: GetNpiRegistryDataResponse['mismatch_on_fields']) => (
      <span>
        {value.map(v => (
          <Tag
            key={v}
            color="red"
          >
            {v}
          </Tag>
        ))}
      </span>
    ),
    title: 'Mismatch on'
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
    render: (value: GetNpiRegistryDataResponse['created_at']) =>
      value ? dayjs(value).format('MM/DD/YYYY HH:mm:ss ([UTC]ZZ)') : '–',
    title: 'Pulled At'
  }
];

export const MismatchedNpiDataPage = () => {
  const expandRow = (record: GetNpiRegistryDataResponse) => (
    <NpiRegistryDatumCard initialDatum={record} />
  );

  return (
    <Card
      bordered={false}
      title={<StyledTitle title="Mismatched NPI Registry Entries" />}
    >
      <DataTable
        columns={columns}
        expandable={{
          childrenColumnName: 'expand',
          expandedRowRender: record => <p style={{ margin: 0 }}>{expandRow(record)}</p>
        }}
        filters={[
          {
            key: 'mismatch_on_fields[]',
            label: 'Mismatch on',
            options: [
              { text: '', value: undefined },
              { text: 'NPI', value: 'npi' },
              { text: 'First name', value: 'first_name' }
            ]
          }
        ]}
        getMethod={getNpiRegistryData}
        getParams={{ mismatched: true }}
        perPage={15}
      />
    </Card>
  );
};
