import {
  CheckCircleFilled,
  CloseCircleFilled,
  DownloadOutlined,
  QuestionCircleFilled,
  UploadOutlined
} from '@ant-design/icons';
import { Alert, Button, Card, Form, FormProps, Space, Upload } from 'antd';
import { UploadState } from 'antd/es/upload/interface';
import { bulkUpdateUsers } from 'api';
import { CardHeader } from 'components/cards';
import { InputForm } from 'components/forms';
import { DarkModeContext } from 'layouts/app_layout';
import { useContext, useState } from 'react';
import { BodyRow, HeaderRow, Table, TableWrapper } from 'styled_components/tables';
import { normalizeFileFromUpload, toFormData } from 'utils/forms';

const { Item } = Form;

export const BulkUserEditPage = () => {
  const [response, setResponse] = useState<BulkEditUserResponse['data'] | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isDarkMode = useContext(DarkModeContext);

  const onFinish: FormProps<{
    file: UploadState['fileList'];
  }>['onFinish'] = values => {
    const { file } = values;
    setIsSubmitting(true);

    const data = toFormData({
      file: file[0].originFileObj
    });

    setResponse(null);
    return bulkUpdateUsers(data)
      .then(result => {
        setResponse(result.data);
        setIsSubmitting(false);
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  const initialData = {
    send_invite_emails: true
  };

  const onDownloadErrors = () => {
    if (!response || response.errors.length === 0) {
      return;
    }

    const { errors } = response;
    /*
      Extract keys from the first error's line_data (excluding the required 'id')
      So we know what data was updated and to expect in line_data
    */
    const keys = Object.keys(errors[0].line_data).filter(
      key => key !== 'id'
    ) as (keyof (typeof errors)[0]['line_data'])[];
    const csvHeader = `id,error,${keys.join(',')}\n`;

    /* Map the line_data values to those headers */
    const csvRows = errors
      .map(e => {
        const values = keys.map(key => e.line_data[key]);
        return `${e.user_id},${e.message},${values.join(',')}`;
      })
      .join('\n');

    /* Combine header and rows for the CSV */
    const csvContent = 'data:text/csv;charset=utf-8,' + csvHeader + csvRows;

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `bulk_edit_users_errors.csv`);
    document.body.appendChild(link);

    link.click();
  };

  return (
    <Card title={<CardHeader title="Bulk Edit Users" />}>
      <Alert
        description="Any and all columns included will be updated. If you leave a cell blank for a column, the value for the user will be set to null. Only include columns you wish to update. If you don't want to change a value for one user but do for another, keep the current value in the cell for the user who will not be changed."
        message={
          <>
            <strong>Only id is required.</strong> All other columns are optional.
          </>
        }
        showIcon
        type="warning"
      />
      <h1>CSV format:</h1>
      <div style={{ maxWidth: '100%', overflow: 'scroll' }}>
        <TableWrapper>
          <Table className={isDarkMode ? 'dark-theme' : 'light-theme'}>
            <thead>
              <HeaderRow className={isDarkMode ? 'dark-theme' : 'light-theme'}>
                <th>
                  id<strong style={{ fontWeight: '900' }}>*</strong>
                </th>
                <th>first_name</th>
                <th>last_name</th>
                <th>email</th>
                <th>role</th>
                <th>completed_onboarding</th>
                <th>is_clinical</th>
                <th>hubspot_id</th>
                <th>state</th>
                <th>city</th>
                <th>npi</th>
                <th>phone_number</th>
              </HeaderRow>
            </thead>
            <tbody>
              <BodyRow className={isDarkMode ? 'dark-theme' : 'light-theme'}>
                <td>UUID</td>
                <td>string</td> {/* first_name */}
                <td>string</td> {/* last_name */}
                <td>string</td> {/* email */}
                <td>
                  provider
                  <br />
                  admin
                  <br />
                  api_client
                </td>{' '}
                {/* role */}
                <td>boolean</td> {/* completed_onboarding */}
                <td>boolean</td> {/* is_clinical */}
                <td>string</td> {/* hubspot_id */}
                <td>string</td> {/* state */}
                <td>string</td> {/* city */}
                <td>number (10-digits)</td> {/* npi */}
                <td>string</td> {/* phone_number */}
              </BodyRow>
            </tbody>
          </Table>
        </TableWrapper>
      </div>

      <InputForm
        initialValues={initialData}
        name="import-csv"
        onFinish={onFinish}
        submitIsDisabled={isSubmitting}
      >
        <Item
          getValueFromEvent={normalizeFileFromUpload}
          label="CSV file"
          name="file"
          required
          rules={[
            {
              validator: async (_, fileList: UploadState['fileList']) => {
                if (fileList.length < 1) {
                  return Promise.reject(new Error('Upload a file'));
                }
                return true;
              }
            }
          ]}
          valuePropName="fileList"
        >
          <Upload
            beforeUpload={() => false}
            maxCount={1}
            showUploadList={{
              showRemoveIcon: false
            }}
          >
            <Button icon={<UploadOutlined />}>Upload CSV file</Button>
          </Upload>
        </Item>
      </InputForm>

      {response && (
        <Space
          direction="vertical"
          style={{ display: 'flex', margin: '50px' }}
        >
          <Alert
            description={
              <table>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'left', width: '250px' }}>Users</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <CheckCircleFilled style={{ color: isDarkMode ? '#74c700' : '#45b500' }} />{' '}
                      Successfully updated
                    </td>
                    <td>{response.success_count}</td>
                  </tr>
                  <tr>
                    <td>
                      <QuestionCircleFilled style={{ color: isDarkMode ? '#6bb2ff' : '#0057b5' }} />{' '}
                      Not found
                    </td>
                    <td>{response.not_found_count}</td>
                  </tr>
                  <tr>
                    <td>
                      <CloseCircleFilled style={{ color: isDarkMode ? '#ff6b6b' : '#a30000' }} />{' '}
                      Failed
                    </td>
                    <td>{response.failed_count}</td>
                  </tr>
                </tbody>
              </table>
            }
            message={<b>Results</b>}
            type="info"
          />

          {response.errors.length > 0 && (
            <TableWrapper>
              <Table className={isDarkMode ? 'dark-theme' : 'light-theme'}>
                <thead>
                  <HeaderRow className={isDarkMode ? 'dark-theme' : 'light-theme'}>
                    <th>user_id</th>
                    <th>message</th>
                  </HeaderRow>
                </thead>
                <tbody>
                  {response.errors.map((error, i) => (
                    <BodyRow
                      key={i}
                      className={isDarkMode ? 'dark-theme' : 'light-theme'}
                    >
                      <td>{error.user_id}</td>
                      <td>{error.message}</td>
                    </BodyRow>
                  ))}
                </tbody>
              </Table>
              <Button
                onClick={onDownloadErrors}
                style={{ marginTop: '1rem' }}
              >
                <DownloadOutlined /> Download errors
              </Button>
            </TableWrapper>
          )}
        </Space>
      )}
    </Card>
  );
};
