import { Card, FormProps, Spin } from 'antd';
import { getFeatureFlag, updateFeatureFlag, useFetch } from 'api';
import { CardHeader } from 'components/cards';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { FeatureFlagForm } from './feature_flag_form';

export const FeatureFlagEditPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(getFeatureFlag, [id]);

  const navigate = useNavigate();

  const onFinish: FormProps<{
    enabled: boolean;
    flag_name: string;
  }>['onFinish'] = values =>
    updateFeatureFlag(id, { feature_flag: values }).then(() => {
      navigate(paths.featureFlagsPath);
    });

  if (!data?.data) return <Spin />;

  return (
    <Card title={<CardHeader title={<>Edit feature flag</>} />}>
      <FeatureFlagForm
        initialData={data.data}
        name="feature-toggle-edit"
        onFinish={onFinish}
      />
    </Card>
  );
};
