import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
  WarningTwoTone
} from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Form, FormProps, Row, Select, Space, Upload } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { GetPathwayResponse } from 'api';
import { InputForm, InputItem, SelectItem, SwitchItem } from 'components/forms';
import { ResourceSelectionForm } from 'components/forms/resource_selection_form';
import { ChangeEventHandler, useState } from 'react';
import styled from 'styled-components';

interface InitialPathwayDataProps extends Omit<GetPathwayResponse, 'skills'> {
  skills: { name: string }[];
}

interface PathwayFormProps {
  initialData?: InitialPathwayDataProps;
  initialImage: { url: string | null } | null;
  name: string;
  onFinish: NonNullable<FormProps['onFinish']>;
}

const PreviewImage = styled.img`
  max-width: 200px;
  height: auto;
  margin-bottom: 0.5rem;
`;

const { Item } = Form;

export const PathwayForm = ({ initialData, initialImage, name, onFinish }: PathwayFormProps) => {
  const [pathwayName, setPathwayName] = useState(initialData?.name ?? '');
  const [pathwayShortDescription, setPathwayShortDescription] = useState(
    initialData?.short_description ?? ''
  );
  const [form] = useForm();

  const updatePathwayName: ChangeEventHandler<HTMLInputElement> = e => {
    setPathwayName(e.target.value);
  };

  const updatePathwayShortDescription: ChangeEventHandler<HTMLInputElement> = e => {
    setPathwayShortDescription(e.target.value);
  };

  return (
    <InputForm
      form={form}
      initialValues={initialData}
      name={name}
      onFinish={onFinish}
    >
      <SwitchItem
        label="Publish"
        name="published"
      />
      {pathwayName.length > 60 && (
        <div className="center-item">
          <div className="warning-block">
            <WarningTwoTone
              className="warning-icon"
              twoToneColor="orange"
            />
            Pathway name is too long. {pathwayName.length}/60 characters
          </div>
        </div>
      )}
      <InputItem
        label="Name"
        name="name"
        onChange={updatePathwayName}
        required
      />
      {pathwayShortDescription.length > 140 && (
        <div className="center-item">
          <div className="warning-block">
            <WarningTwoTone
              className="warning-icon"
              twoToneColor="orange"
            />
            Pathway short description is too long. {pathwayShortDescription.length}/140 characters.
          </div>
        </div>
      )}
      <InputItem
        label="Short description"
        name="short_description"
        onChange={updatePathwayShortDescription}
        required
        textArea
      />
      <InputItem
        label="Long description"
        name="long_description"
        required
        textArea
      />
      <SelectItem
        label="Level"
        name="level"
        required
      >
        <Select.Option value="foundation">Foundation</Select.Option>
        <Select.Option value="intermediate">Intermediate</Select.Option>
        <Select.Option value="advanced">Advanced</Select.Option>
      </SelectItem>
      <Row>
        <Col
          span={6}
          style={{
            paddingRight: '0.5rem',
            paddingTop: '0.55rem',
            textAlign: 'right'
          }}
        >
          Specialty area(s):
        </Col>
        <Item name="specialty_area">
          <Checkbox.Group options={['mental_health', 'physical_health', 'non_clinical']} />
        </Item>
      </Row>
      <Item label="Skills">
        <Form.List name="skills">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  align="baseline"
                  style={{ display: 'flex', marginBottom: 0 }}
                >
                  <InputItem
                    {...restField}
                    name={[name, 'name']}
                  />
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}

              <Item>
                <Button
                  block
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                  type="dashed"
                >
                  Add skill
                </Button>
              </Item>
            </>
          )}
        </Form.List>
      </Item>
      <Row>
        <Col
          span={6}
          style={{
            paddingRight: '0.5rem',
            paddingTop: '0.55rem',
            textAlign: 'right'
          }}
        >
          Image:
        </Col>
        <Item
          name="image"
          valuePropName="file"
        >
          {/* eslint-disable-next-line @typescript-eslint/prefer-optional-chain */}
          {initialImage !== null && initialImage.url !== null && (
            <PreviewImage
              alt="Pathway preview"
              src={`${process.env.REACT_APP_API_BASE_URL}${initialImage.url}`}
            />
          )}
          <Upload
            beforeUpload={() => false}
            maxCount={1}
            name="file"
            onChange={value => form.setFieldsValue({ image: value })}
            showUploadList={{
              showRemoveIcon: true
            }}
          >
            <Button icon={<UploadOutlined />}>Upload image</Button>
          </Upload>
        </Item>
      </Row>

      <Item label="Resources">
        <Form.List name="resources">
          {(fields, { add, move, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <div key={key}>
                  <ResourceSelectionForm
                    move={move}
                    name={name}
                    page="create"
                    parentForm={form}
                    remove={remove}
                    totalItemLength={fields.length}
                  />
                  <Divider />
                </div>
              ))}

              <Item>
                <Button
                  block
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                  type="dashed"
                >
                  Add Resource
                </Button>
              </Item>
            </>
          )}
        </Form.List>
      </Item>
    </InputForm>
  );
};
