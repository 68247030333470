import { FormItemProps } from 'antd';
import jsonToFormData from 'json-form-data';

export const normalizeFileFromUpload: FormItemProps['getValueFromEvent'] = e => {
  if (Array.isArray(e)) {
    return e;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return e?.fileList;
};

export const toFormData = (data: Parameters<typeof jsonToFormData>[0]) =>
  jsonToFormData(data, {
    mapping: val => {
      // if (val === null || val === undefined) return String(val);
      switch (typeof val) {
        case 'boolean':
          return val ? '1' : '0';
        case 'number':
          return String(val);
        case 'string':
          return val === '' ? '' : val;
        default:
          return val as Blob | string;
      }
    },
    showLeafArrayIndexes: false
  });
