import {
  GetCourseCollectionResponse,
  GetCourseResponse,
  GetOrganizationResponse,
  GetUserResponse
} from 'api';

export const renameAndFillNestedAttributes = (
  params: Record<string, { _destroy: boolean; id: string }[]>,
  ogData?:
    | GetCourseCollectionResponse
    | GetCourseResponse
    | GetOrganizationResponse
    | GetUserResponse
) => {
  // Add _destroy property for missing records
  const modifiedParams = params;

  if (ogData !== undefined) {
    Object.entries(modifiedParams).forEach(([attributeName, values]) => {
      const newValues = values;
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      ogData[attributeName].forEach(({ id }) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        if (!values.find(v => v.id === id)) newValues.push({ _destroy: true, id });
      });
      modifiedParams[attributeName] = newValues;
    });
  }

  // Add _attributes to the params
  return Object.entries(modifiedParams).reduce<Record<string, { _destroy: boolean; id: string }[]>>(
    (newParams, [attributeName, values]) => {
      const newName = `${attributeName}_attributes`;
      newParams[newName] = values;
      return newParams;
    },
    {}
  );
};
