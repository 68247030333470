import { ColumnsType } from 'antd/es/table';
import {
  GetCommunicationInfosOverviewResponse,
  GetLicenseCohortsResponse,
  GetOrganizationLicenseTiersResponse,
  GetOrganizationResponse
} from 'api';
import { DateFormatter } from 'components/date_formatter';

export const communicationInfoOverviewColumns: ColumnsType<
  GetCommunicationInfosOverviewResponse & { key: string }
> = [
  {
    dataIndex: 'email_type',
    key: 'email_type',
    title: 'E-mail type'
  },
  {
    dataIndex: 'sent',
    key: 'sent',
    title: 'Number sent'
  },
  {
    dataIndex: 'delivered',
    key: 'delivered',
    title: 'Number delivered'
  },
  {
    dataIndex: 'opened',
    key: 'opened',
    title: 'Number opened'
  },
  {
    dataIndex: 'clicked',
    key: 'clicked',
    title: 'Number clicked'
  },
  {
    key: 'clicked_percentage',
    render: (_value, record) => {
      if (record.sent === 0) return '-';
      return `${Math.round((record.clicked / record.sent) * 100)}%`;
    },
    title: 'Clicked %'
  }
];

export const licenseCohortColumns: ColumnsType<GetLicenseCohortsResponse> = [
  {
    key: 'license_tier_name',
    render: (_value, record) => record.organization_license_tier.license_tier.name,
    title: 'License Tier'
  },
  {
    dataIndex: 'users_count',
    key: 'users_count',
    title: 'Users count'
  },
  {
    dataIndex: 'start_date',
    key: 'start_date',
    render: (value: GetLicenseCohortsResponse['start_date']) => <DateFormatter value={value} />,
    title: 'Start date'
  }
];

export const organizationOverviewColumns: ColumnsType<
  GetOrganizationResponse['detailed_overview'][0]
> = [
  {
    dataIndex: 'invite_date',
    key: 'invite_date',
    render: (value: GetOrganizationResponse['detailed_overview'][0]['invite_date']) => (
      <DateFormatter value={value} />
    ),
    title: 'Invite Date'
  },
  {
    dataIndex: 'invited_users',
    key: 'invited_users',
    title: 'Invited Users'
  },
  {
    dataIndex: 'active_count',
    key: 'active_count',
    title: 'Active Users'
  },
  {
    dataIndex: 'completed_onboarding',
    key: 'completed_onboarding',
    title: 'Onboarded Users'
  },
  {
    key: 'onboarded_percentage',
    render: (_value, record) => {
      if (record.invited_users === 0) return '-';
      return `${Math.round((record.completed_onboarding / record.invited_users) * 100)}%`;
    },
    title: 'Onboarded %'
  }
];

export const orgLicenseColumns: ColumnsType<GetOrganizationLicenseTiersResponse> = [
  {
    key: 'license_tier_name',
    render: (_value, record) => record.license_tier.name,
    title: 'License Tier'
  },
  {
    dataIndex: 'total_licenses',
    key: 'total_licenses',
    title: 'Total licenses'
  },
  {
    dataIndex: 'available_licenses',
    key: 'available_licenses',
    title: 'Available licenses'
  }
];
