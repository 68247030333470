import { Card } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  getCommunicationInfos,
  getCommunicationInfosOverview,
  GetCommunicationInfosOverviewResponse,
  GetCommunicationInfosResponse
} from 'api';
import { BooleanIcon } from 'components/boolean_icon';
import { StyledTitle } from 'components/cards';
import { UserLink } from 'components/common';
import { DateFormatter } from 'components/date_formatter';
import { ActionsColumn, DataTable } from 'components/tables';
import * as paths from 'utils/paths';

const columns: ColumnsType<GetCommunicationInfosResponse> = [
  {
    dataIndex: 'email_type',
    key: 'email_type',
    title: 'E-mail type',
    width: '15%'
  },
  {
    dataIndex: 'user',
    key: 'user',
    render: (value: GetCommunicationInfosResponse['user']) =>
      value ? <UserLink user={value} /> : null,
    title: 'Addressee'
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: 'Status',
    width: '10%'
  },
  {
    dataIndex: 'email_delivered_at',
    key: 'email_delivered_at',
    render: (value: GetCommunicationInfosResponse['email_delivered_at']) => (
      <DateFormatter value={value} />
    ),
    title: 'Delivered at'
  },
  {
    dataIndex: 'email_opened_at',
    key: 'email_opened_at',
    render: (value: GetCommunicationInfosResponse['email_opened_at']) => (
      <DateFormatter value={value} />
    ),
    title: 'Opened at'
  },
  {
    dataIndex: 'email_link_clicked_at',
    key: 'email_link_clicked_at',
    render: (value: GetCommunicationInfosResponse['email_link_clicked_at']) => (
      <DateFormatter value={value} />
    ),
    title: 'Link clicked at'
  },
  {
    dataIndex: 'sg_machine_open',
    key: 'sg_machine_open',
    render: (value: GetCommunicationInfosResponse['sg_machine_open']) => (
      <BooleanIcon value={value} />
    ),
    title: 'SG Machine Open'
  },
  {
    key: 'actions',
    render: (_value, record) => (
      <ActionsColumn
        record={record}
        showPath={paths.communicationInfoPath(record.id.toString())}
      />
    ),
    title: 'Actions'
  }
];

const overviewColumns: ColumnsType<GetCommunicationInfosOverviewResponse> = [
  {
    dataIndex: 'email_type',
    key: 'email_type',
    title: 'E-mail type'
  },
  {
    dataIndex: 'sent',
    key: 'sent',
    title: 'Number sent'
  },
  {
    dataIndex: 'delivered',
    key: 'delivered',
    title: 'Number delivered'
  },
  {
    dataIndex: 'opened',
    key: 'opened',
    title: 'Number opened'
  },
  {
    dataIndex: 'clicked',
    key: 'clicked',
    title: 'Number clicked'
  },
  {
    key: 'clicked_percentage',
    render: (_value, record) => {
      if (record.sent === 0) return '-';
      return `${Math.round((record.clicked / record.sent) * 100)}%`;
    },
    title: 'Clicked %'
  }
];

export const CommunicationInfosPage = () => (
  <Card
    bordered={false}
    title={<StyledTitle title="Communication info" />}
  >
    <Card
      bordered={false}
      title="Overview"
    >
      <DataTable
        columns={overviewColumns}
        getMethod={getCommunicationInfosOverview}
        pagination={false}
        perPage={15}
      />
    </Card>
    <DataTable
      columns={columns}
      getMethod={getCommunicationInfos}
      perPage={15}
    />
  </Card>
);
