import { PlusOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { getNetworks } from 'api';
import { StyledTitle } from 'components/cards';
import { DataTable } from 'components/tables';
import { Link } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { networkColumns } from './network_components';

export const NetworksPage = () => (
  <Card
    bordered={false}
    title={
      <StyledTitle
        buttonsContents={
          <Link
            key="2"
            to={paths.networkCreatePath}
          >
            <PlusOutlined /> Create Network
          </Link>
        }
        title="Networks"
      />
    }
  >
    <DataTable
      columns={networkColumns}
      getMethod={getNetworks}
      perPage={15}
    />
  </Card>
);
