import { Card, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  getPathway,
  getPathwayCompletions,
  GetPathwayCompletionsResponse,
  GetPathwayResponse,
  useFetch
} from 'api';
import { CardHeader } from 'components/cards';
import { CardEntryList } from 'components/cards/card_entry_list';
import { UserLink } from 'components/common';
import { DateFormatter } from 'components/date_formatter';
import { DataTable } from 'components/tables';
import { useParams } from 'react-router';
import { PathwayImage, PlainCard, PlainList } from 'styles/show_page_styles';
import * as paths from 'utils/paths';

const completionColumns: ColumnsType<GetPathwayCompletionsResponse> = [
  {
    key: 'user',
    render: (_value, record) => <UserLink user={record.user} />,
    title: 'User'
  },
  {
    dataIndex: 'started_at',
    key: 'started_at',
    render: (value: GetPathwayCompletionsResponse['started_at']) => <DateFormatter value={value} />,
    title: 'Started at'
  },
  {
    dataIndex: 'completed_at',
    key: 'completed_at',
    render: (value: GetPathwayCompletionsResponse['completed_at']) => (
      <DateFormatter value={value} />
    ),
    title: 'Completed at'
  },
  {
    dataIndex: 'last_progress_at',
    key: 'last_progress_at',
    render: (value: GetPathwayCompletionsResponse['last_progress_at']) => (
      <DateFormatter value={value} />
    ),
    title: 'Last progress at'
  },
  {
    dataIndex: 'total_completed_resources',
    key: 'total_completed_resources',
    title: 'Total completed resources'
  }
];

export const PathwayPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(getPathway, [id]);

  return (
    <div className="site-card-border-less-wrapper">
      {data?.data && (
        <Card
          headStyle={{ fontWeight: 'bold' }}
          style={{ minHeight: 'calc(100vh - 111px)' }}
          title={
            <CardHeader
              editPath={paths.pathwayEditPath(id)}
              title={`Pathway ${data.data.name}`}
            />
          }
        >
          <CardEntryList
            fieldsMap={[
              { key: 'id', title: 'ID' },
              { key: 'name', title: 'Name' },
              { key: 'short_description', title: 'Short description' },
              { key: 'long_description', title: 'Long description' },
              { key: 'level', title: 'Level' },
              { key: 'hours', title: 'Hours' },
              { key: 'is_accredited', title: 'Is accredited' },
              { key: 'is_new', title: 'Is new' },
              { key: 'is_trending', title: 'Is trending' },
              { key: 'published', title: 'Published' },
              {
                key: 'specialty_area',
                render: (specialty_area: string[]) => (
                  <>
                    {specialty_area.map(specialty => (
                      <Tag key={specialty}>{specialty}</Tag>
                    ))}
                  </>
                ),
                title: 'Specialty area'
              },
              {
                key: 'skills',
                render: (skills: string[]) => (
                  <PlainList>
                    {skills.map(skill => (
                      <li key={skill}>{skill}</li>
                    ))}
                  </PlainList>
                ),
                title: 'Skills'
              },
              { key: 'created_at', title: 'Created at', type: 'date' },
              { key: 'updated_at', title: 'Updated at', type: 'date' },
              {
                key: 'image',
                render: (value: GetPathwayResponse['image']) =>
                  value?.url !== null ? (
                    <PathwayImage
                      alt="Pathway image"
                      src={`${process.env.REACT_APP_API_BASE_URL}${value?.url}`}
                    />
                  ) : (
                    'No image'
                  ),
                title: 'Image'
              }
            ]}
            values={data.data}
          />
          <PlainCard
            bordered={false}
            title="Resources"
          >
            <Table
              columns={[
                {
                  dataIndex: 'name',
                  key: 'name',
                  render: (name: string, record) => (
                    <a
                      href={
                        record.resource_type === 'Course'
                          ? paths.coursePath(record.resource_id)
                          : paths.courseCollectionPath(record.resource_id)
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      {name}
                    </a>
                  ),
                  title: 'Name'
                },
                {
                  dataIndex: 'resource_type',
                  key: 'resource_type',
                  title: 'Resource type'
                },
                {
                  dataIndex: 'position',
                  key: 'position',
                  title: 'Position'
                }
              ]}
              dataSource={data.data.resources}
              pagination={false}
            />
          </PlainCard>
          <PlainCard
            bordered={false}
            title="Completions"
          >
            <DataTable
              columns={completionColumns}
              getMethod={getPathwayCompletions}
              getParams={{
                pathway_id: id
              }}
            />
          </PlainCard>
        </Card>
      )}
    </div>
  );
};
