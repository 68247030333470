import { CheckCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Select, Spin, Table } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import {
  getOrganizationLicenseTiers,
  GetOrganizationLicenseTiersResponse,
  getUser,
  GetUserResponse,
  getUsers,
  GetUsersResponse,
  useFetch
} from 'api';
import { createOrganizationMembership } from 'api/organization_memberships';
import { CardHeader } from 'components/cards';
import { SelectItem } from 'components/forms';
import { Key, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { toast, ToastOptions } from 'react-toastify';

import UserOrganizationMembershipsCell from './components/user_organization_memberships_cell';

const { Item } = Form;

type OrgLicenseType = GetOrganizationLicenseTiersResponse | null;

export const OrganizationBulkAddPage = () => {
  const { id } = useParams<{ id: string }>();
  const [userSearchType, setUserSearchType] = useState<'email' | 'id' | 'npi'>('email');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [userList, setUserList] = useState<(GetUserResponse | GetUsersResponse)[]>([]);

  const [selectedMemberRole, setSelectedMemberRole] = useState<
    'member' | 'network_manager' | 'superuser'
  >('member');

  const [orgLicense, setOrgLicense] = useState<OrgLicenseType>();
  const orgLicensesFetchParams = useMemo(
    () => [undefined, undefined, { organization_id: id }],
    [id]
  ) as [undefined, undefined, { organization_id?: string }];
  const { data: orgLicensesData } = useFetch(getOrganizationLicenseTiers, orgLicensesFetchParams);

  const toastOptions = {
    autoClose: 2000,
    closeOnClick: true,
    draggable: true,
    hideProgressBar: false,
    pauseOnHover: true,
    position: 'bottom-center',
    progress: undefined
  } as ToastOptions;

  const onOrgLicenseTierChange = (orgLicenseTierId: string) => {
    const fullOrgLicenseTier = orgLicensesData?.data?.find(
      orgLicense => orgLicense.id === orgLicenseTierId
    );
    setOrgLicense(fullOrgLicenseTier ?? null);
  };

  const handleSearchTypeChange = (value: string) => {
    setUserSearchType(value as 'email' | 'id' | 'npi');
    setSearchValue('');
  };

  const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleMemberRoleChange = (value: string) => {
    setSelectedMemberRole(value as 'member' | 'network_manager' | 'superuser');
  };

  const handleSearchForUser = () => {
    if (!searchValue) {
      return;
    }
    setIsSearching(true);
    if (userSearchType === 'id') {
      getUser(searchValue.trim()).then(response => {
        if (response.data) {
          const newList = [...userList, ...[response.data]].filter(
            (user, index, self) => index === self.findIndex(t => t.id === user.id)
          );
          setUserList(newList);
          setSearchValue('');
          reportSuccess(1);
        } else {
          reportSuccess(0);
        }
      });
    } else {
      getUsers('1', '50', {
        [userSearchType]: searchValue
      }).then(response => {
        if (response.data && response.data.length > 0) {
          const newList = [...userList, ...response.data].filter(
            (user, index, self) => index === self.findIndex(t => t.id === user.id)
          );
          setUserList(newList);
          setSearchValue('');
          reportSuccess(response.data.length);
        } else {
          reportSuccess(0);
        }
      });
    }
  };

  const reportSuccess = (successNum: number) => {
    if (successNum > 0) {
      toast.success(
        `${successNum} user${successNum > 1 ? 's' : ''} found and added to table.`,
        toastOptions
      );
    } else {
      toast.error(`No users found matching ${userSearchType} ${searchValue}.`, toastOptions);
    }
    setIsSearching(false);
  };

  const handleAddUsersToOrganization = () => {
    if (selectedRowKeys.length === 0 || !orgLicense) {
      return;
    }

    setIsSubmitting(true);
    let successCount = 0;

    const promises = selectedRowKeys.map((userId: Key) =>
      createOrganizationMembership({
        organization_membership: {
          member_role: selectedMemberRole,
          organization_id: id,
          organization_license_tier_id: orgLicense.id,
          status: 'activated',
          user_id: userId.toString()
        }
      }).then(response => {
        if (response.status === 204) {
          successCount += 1;
        }
      })
    );

    Promise.all(promises)
      .then(() => {
        setIsSubmitting(false);
        if (successCount > 0) {
          toast.success(`${successCount} user${successCount > 1 ? 's' : ''} added.`, toastOptions);

          setSearchValue('');
          setUserList([]);
          setSelectedRowKeys([]);
          setSelectedMemberRole('member');
          setUserSearchType('email');
          setOrgLicense(null);
        }
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<GetUserResponse | GetUsersResponse> = {
    onChange: onSelectChange,
    selectedRowKeys
  };

  return (
    <Card title={<CardHeader title="Bulk Add Users" />}>
      <Form>
        <div style={{ display: 'grid', gap: '1rem', gridTemplateColumns: '1fr 1fr 1fr' }}>
          <Item label="Search for user by">
            <Select
              onChange={handleSearchTypeChange}
              value={userSearchType}
            >
              <Select.Option value="email">Email</Select.Option>
              <Select.Option value="npi">NPI</Select.Option>
              <Select.Option value="id">User ID</Select.Option>
            </Select>
          </Item>
          <Item label="Search value">
            <Input
              onChange={handleSearchValueChange}
              value={searchValue}
            />
          </Item>
          <Item>
            <Button
              disabled={isSearching}
              onClick={handleSearchForUser}
              type="primary"
            >
              Search for user(s)
            </Button>
          </Item>
        </div>

        <Table
          columns={[
            {
              key: 'name',
              render: (record: GetUsersResponse) => `${record.first_name} ${record.last_name}`,
              title: 'Name'
            },
            {
              dataIndex: 'email',
              key: 'email',
              title: 'Email'
            },
            {
              dataIndex: 'organization_memberships',
              key: 'organization_memberships',
              render: (_memberships, record) => (
                <UserOrganizationMembershipsCell userId={record.id} />
              ),
              title: 'Organizations'
            }
          ]}
          dataSource={userList}
          pagination={false}
          rowKey={record => record.id}
          rowSelection={rowSelection}
          style={{ marginBottom: 20 }}
        />
        <div style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>
          <div>
            <UnorderedListOutlined /> {userList.length} users found
          </div>
          <div>
            <CheckCircleOutlined /> {selectedRowKeys.length} users selected
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gap: '1rem',
            gridTemplateColumns: '1fr 1fr 1fr',
            marginTop: '1rem'
          }}
        >
          <div />
          <SelectItem
            label="Member role"
            onChange={handleMemberRoleChange}
            required
            selectProps={{
              value: selectedMemberRole
            }}
          >
            <Select.Option value="member">Member</Select.Option>
            <Select.Option value="superuser">Superuser</Select.Option>
            <Select.Option value="network_manager">Network Manager</Select.Option>
          </SelectItem>
          <SelectItem
            help={
              orgLicense && orgLicense.available_licenses < selectedRowKeys.length
                ? 'This will result in creation of a License Cohort.'
                : undefined
            }
            label="License Tier"
            onChange={onOrgLicenseTierChange}
            required
            style={{ marginBottom: 0 }}
            validateStatus={orgLicense?.available_licenses === 0 ? 'warning' : undefined}
          >
            {orgLicensesData?.data?.map(orgLicenseTier => (
              <Select.Option
                key={orgLicenseTier.id}
                value={orgLicenseTier.id}
              >
                <div
                  style={{
                    display: 'flex'
                  }}
                >
                  <div style={{ display: 'inline-block', flex: 1 }}>
                    {orgLicenseTier.license_tier.name}
                  </div>
                  <div
                    style={{
                      color: orgLicenseTier.available_licenses > 0 ? 'green' : 'red',
                      display: 'inline-block'
                    }}
                  >
                    <b>{orgLicenseTier.available_licenses}</b> available licenses
                  </div>
                </div>
              </Select.Option>
            ))}
          </SelectItem>
        </div>
        <div
          style={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end', marginTop: '1rem' }}
        >
          <Button
            disabled={selectedRowKeys.length === 0 || !orgLicense || isSubmitting}
            onClick={handleAddUsersToOrganization}
            size="large"
            type="primary"
          >
            Add users to organization
            {isSubmitting && <Spin />}
          </Button>
        </div>
      </Form>
    </Card>
  );
};
