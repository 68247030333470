import { AxiosResponse } from 'axios';

import { instance } from '.';

export interface GetPathwayResponse {
  created_at: string;
  hours: string;
  id: string;
  image: {
    url: string | null;
  } | null;
  is_accredited: boolean;
  is_new: boolean;
  is_trending: boolean;
  level: string;
  long_description: string;
  name: string;
  published: boolean;
  resources: {
    id: string;
    name: string;
    position: number;
    resource_id: string;
    resource_type: 'Course' | 'CourseCollection';
  }[];
  short_description: string;
  skills: string[];
  specialty_area: string[];
  updated_at: string;
}

export const createPathway = (params: FormData) =>
  instance
    .post<
      ApiResponse<GetPathwayResponse>,
      AxiosResponse<ApiResponse<GetPathwayResponse>>,
      FormData
    >('/admin/pathways', params)
    .then(res => res.data);

export const deletePathway = (id: string) => instance.delete<''>(`/admin/pathways/${id}`);

export const getPathway = (id: string) =>
  instance.get<ApiResponse<GetPathwayResponse>>(`/admin/pathways/${id}`).then(res => res.data);

export const getPathways = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetPathwayResponse[], IndexPageMeta>>('/admin/pathways', {
      params: {
        ...getParams,
        page,
        per_page: pageSize
      }
    })
    .then(res => res.data);

export const updatePathway = (id: string, params: FormData) =>
  instance
    .patch<
      ApiResponse<GetPathwayResponse>,
      AxiosResponse<ApiResponse<GetPathwayResponse>>,
      FormData
    >(`/admin/pathways/${id}`, params)
    .then(res => res.data);
