import {
  CloseCircleOutlined,
  EditOutlined,
  EyeOutlined,
  ReloadOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { Button, Checkbox, InputNumber, Popover } from 'antd';
import { GetUserResponse } from 'api';
import {
  GetNonClinicalExperienceResponse,
  getNonClinicalExperiences,
  updateNonClinicalExperience
} from 'api/non_clinical_experiences';
import { BooleanIcon } from 'components/boolean_icon';
import { DateFormatter } from 'components/date_formatter';
import { DataTable } from 'components/tables';
import { useEffect, useState } from 'react';

interface Props {
  user: GetUserResponse;
}

const NonClinicalExperiencesPanel = ({ user }: Props) => {
  const [updatingNonClinicalExpIds, setUpdatingNonClinicalExpIds] = useState<string[]>([]);
  const [loadedNonClinicalExpRows, setLoadedNonClinicalExpRows] = useState<
    GetNonClinicalExperienceResponse[]
  >([]);
  const [nonClinicalExpRows, setNonClinicalExpRows] = useState<GetNonClinicalExperienceResponse[]>(
    []
  );
  const [clicked, setClicked] = useState<string[]>([]);

  useEffect(() => {
    setNonClinicalExpRows(loadedNonClinicalExpRows);
  }, [loadedNonClinicalExpRows]);

  const updateBipoc = (id: string, value: boolean) => {
    setNonClinicalExpRows(
      nonClinicalExpRows.map(row => (row.id === id ? { ...row, bipoc: value } : row))
    );
  };

  const updateLgbq = (id: string, value: boolean) => {
    setNonClinicalExpRows(
      nonClinicalExpRows.map(row => (row.id === id ? { ...row, lgbq: value } : row))
    );
  };

  const updateTgnc = (id: string, value: boolean) => {
    setNonClinicalExpRows(
      nonClinicalExpRows.map(row => (row.id === id ? { ...row, tgnc: value } : row))
    );
  };

  const updateScore = (id: string, value: string) => {
    setNonClinicalExpRows(
      nonClinicalExpRows.map(row => (row.id === id ? { ...row, score: value } : row))
    );
  };

  const startEditingNonClinicalExpRow = (id: string) => {
    setUpdatingNonClinicalExpIds([...updatingNonClinicalExpIds, id]);
  };

  const cancelEditingNonClinicalExpRow = (id: string) => {
    setUpdatingNonClinicalExpIds(updatingNonClinicalExpIds.filter(i => i !== id));
    setNonClinicalExpRows(
      loadedNonClinicalExpRows.map(original =>
        original.id === id ? original : nonClinicalExpRows.find(row => row.id === id) ?? original
      )
    );
  };

  const saveNonClinicalExpRow = async (id: string) => {
    const row = nonClinicalExpRows.find(row => row.id === id);

    if (!row) {
      return;
    }

    try {
      await updateNonClinicalExperience(id, { non_clinical_experience: row });
      setUpdatingNonClinicalExpIds(updatingNonClinicalExpIds.filter(i => i !== id));
      setLoadedNonClinicalExpRows(
        loadedNonClinicalExpRows.map(original => (original.id === id ? row : original))
      );
    } catch (e) {
      console.error(e);
    }
  };

  const hideDesc = (id: string) => {
    setClicked(clicked.filter(i => i !== id));
  };

  const handleDescClickChange = (open: boolean, id: string) => {
    setClicked(open ? [...clicked, id] : clicked.filter(i => i !== id));
  };

  return (
    <DataTable
      columns={[
        {
          dataIndex: 'category',
          key: 'category',
          title: 'Type'
        },
        {
          dataIndex: 'role',
          key: 'role',
          render: (value, record) => (
            <>
              <div style={{ borderBottom: '1px solid #eee' }}>{value}</div>
              <div style={{ fontSize: '0.75rem' }}>{record.organization}</div>
            </>
          ),
          title: 'Role & Org'
        },
        {
          dataIndex: 'start_year',
          key: 'start_year',
          render: (value: string, record) =>
            record.current ? value : `${value} - ${record.end_year}`,
          title: 'Dates'
        },
        {
          dataIndex: 'description',
          key: 'description',
          render: (value: string, record) =>
            value ? (
              <Popover
                content={
                  <div>
                    <div>{value}</div>
                    <Button
                      icon={<CloseCircleOutlined />}
                      onClick={() => hideDesc(record.id)}
                      style={{ padding: '0' }}
                      type="link"
                    >
                      Close
                    </Button>
                  </div>
                }
                onOpenChange={value => handleDescClickChange(value, record.id)}
                open={clicked.includes(record.id)}
                title="Description"
                trigger="click"
              >
                <Button
                  icon={<EyeOutlined />}
                  style={{ padding: '0' }}
                  type="link"
                >
                  View
                </Button>
              </Popover>
            ) : null,
          title: 'Description'
        },
        {
          dataIndex: 'bipoc',
          key: 'bipoc',
          render: (_value: boolean, record) =>
            updatingNonClinicalExpIds.includes(record.id) ? (
              <Checkbox
                checked={nonClinicalExpRows.find(row => row.id === record.id)?.bipoc ?? false}
                onChange={e => updateBipoc(record.id, e.target.checked)}
              />
            ) : (
              <BooleanIcon
                value={loadedNonClinicalExpRows.find(row => row.id === record.id)?.bipoc ?? false}
              />
            ),
          title: 'BIPOC'
        },
        {
          dataIndex: 'lgbq',
          key: 'lgbq',
          render: (_value: boolean, record) =>
            updatingNonClinicalExpIds.includes(record.id) ? (
              <Checkbox
                checked={nonClinicalExpRows.find(row => row.id === record.id)?.lgbq ?? false}
                onChange={e => updateLgbq(record.id, e.target.checked)}
              />
            ) : (
              <BooleanIcon
                value={loadedNonClinicalExpRows.find(row => row.id === record.id)?.lgbq ?? false}
              />
            ),
          title: 'LGBQ'
        },
        {
          dataIndex: 'tgnc',
          key: 'tgnc',
          render: (_value: boolean, record) =>
            updatingNonClinicalExpIds.includes(record.id) ? (
              <Checkbox
                checked={nonClinicalExpRows.find(row => row.id === record.id)?.tgnc ?? false}
                onChange={e => updateTgnc(record.id, e.target.checked)}
              />
            ) : (
              <BooleanIcon
                value={loadedNonClinicalExpRows.find(row => row.id === record.id)?.tgnc ?? false}
              />
            ),
          title: 'TGNC'
        },
        {
          dataIndex: 'score',
          key: 'score',
          render: (_value: string, record) =>
            updatingNonClinicalExpIds.includes(record.id) ? (
              <InputNumber
                max="10"
                min="0"
                onChange={value => updateScore(record.id, value ?? '0')}
                step="0.1"
                style={{ width: '4rem' }}
                value={nonClinicalExpRows.find(row => row.id === record.id)?.score ?? '0'}
              />
            ) : (
              <span style={{ padding: '0 11px', width: '4rem' }}>
                {loadedNonClinicalExpRows.find(row => row.id === record.id)?.score ?? '0'}
              </span>
            ),
          title: 'Score'
        },
        {
          dataIndex: 'created_at',
          key: 'created_at',
          render: (value: string) => (
            <DateFormatter
              format="MM/DD/YYYY"
              hideTimezone
              value={value}
            />
          ),
          title: 'Created'
        },
        {
          dataIndex: 'updated_at',
          key: 'updated_at',
          render: (value: string) => (
            <DateFormatter
              format="MM/DD/YYYY"
              hideTimezone
              value={value}
            />
          ),
          title: 'Updated'
        },
        {
          key: 'actions',
          render: (_value, record) =>
            updatingNonClinicalExpIds.includes(record.id) ? (
              <div style={{ display: 'grid', gap: '0.25rem' }}>
                <Button
                  block
                  icon={<ReloadOutlined />}
                  onClick={() => cancelEditingNonClinicalExpRow(record.id)}
                  type="dashed"
                >
                  Cancel
                </Button>
                <Button
                  block
                  icon={<SaveOutlined />}
                  onClick={() => saveNonClinicalExpRow(record.id)}
                  type="primary"
                >
                  Save
                </Button>
              </div>
            ) : (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '68px',
                  justifyContent: 'center'
                }}
              >
                <Button
                  block
                  icon={<EditOutlined />}
                  onClick={() => startEditingNonClinicalExpRow(record.id)}
                  type="primary"
                >
                  Edit
                </Button>
              </div>
            ),
          title: 'Actions'
        }
      ]}
      getMethod={getNonClinicalExperiences}
      getParams={{
        user_id: user.id
      }}
      updateDataUpstream={setLoadedNonClinicalExpRows}
    />
  );
};

export default NonClinicalExperiencesPanel;
