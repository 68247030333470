import { instance } from '.';

interface CommunicationInfo {
  created_at: string;
  email_delivered_at: string;
  email_link_clicked_at: string;
  email_opened_at: string;
  email_template_id: string;
  email_type: string;
  id: string;
  sg_machine_open: boolean;
  status: 'cancelled' | 'in_queue' | 'job_error' | 'processed_from_queue' | 'send_error';
  updated_at: string;
  user?: Pick<
    User,
    | 'auth0_id'
    | 'city'
    | 'completed_onboarding'
    | 'created_at'
    | 'email'
    | 'first_name'
    | 'hubspot_id'
    | 'id'
    | 'invite_link'
    | 'is_active'
    | 'is_clinical'
    | 'last_name'
    | 'permissions'
    | 'requires_assessment'
    | 'role'
    | 'state'
    | 'status'
  >;
}

export interface GetCommunicationInfosOverviewResponse {
  clicked: number;
  delivered: number;
  email_type: string;
  opened: number;
  sent: number;
}

interface GetCommunicationInfoResponse extends CommunicationInfo {}

export interface GetCommunicationInfosResponse extends CommunicationInfo {}

export const getCommunicationInfo = (id: string) =>
  instance
    .get<ApiResponse<GetCommunicationInfoResponse>>(`/admin/communication_infos/${id}`)
    .then(res => res.data);

export const getCommunicationInfos = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetCommunicationInfosResponse[], IndexPageMeta>>(
      '/admin/communication_infos',
      {
        params: {
          ...getParams,
          page,
          per_page: pageSize
        }
      }
    )
    .then(res => res.data);

export const getCommunicationInfosOverview = () =>
  instance
    .get<ApiResponse<GetCommunicationInfosOverviewResponse[], IndexPageMeta>>(
      '/admin/communication_infos/overview'
    )
    .then(res => res.data);
