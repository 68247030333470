import { Form, FormItemProps, Switch, SwitchProps } from 'antd';

type SwitchItemProps = FormItemProps & {
  switchProps?: SwitchProps;
};

export const SwitchItem = ({
  required = false,
  rules,
  switchProps,
  ...itemProps
}: SwitchItemProps) => (
  <Form.Item
    required={required}
    rules={
      required ? [{ message: 'This field is required', required: true }, ...(rules ?? [])] : rules
    }
    valuePropName="checked"
    {...itemProps}
  >
    <Switch {...switchProps} />
  </Form.Item>
);
