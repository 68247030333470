import { Card } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { getCourseRegistrations, GetCourseRegistrationsResponse } from 'api';
import { StyledTitle } from 'components/cards';
import { UserLink } from 'components/common';
import { DateFormatter } from 'components/date_formatter';
import { SecondsFormatter } from 'components/seconds_formatter';
import { ActionsColumn, DataTable } from 'components/tables';
import { Link } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

const columns: ColumnsType<GetCourseRegistrationsResponse> = [
  {
    key: 'course',
    render: (_value, record) => (
      <Link to={paths.coursePath(record.course.id)}>{record.course.short_title}</Link>
    ),
    title: 'Course'
  },
  {
    key: 'user',
    render: (_value, record) => <UserLink user={record.user} />,
    title: 'User'
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
    render: (value: GetCourseRegistrationsResponse['created_at']) => (
      <DateFormatter value={value} />
    ),
    title: 'Created at'
  },
  {
    dataIndex: 'completed_at',
    key: 'completed_at',
    render: (value: GetCourseRegistrationsResponse['completed_at']) => (
      <DateFormatter value={value} />
    ),
    title: 'Completed at'
  },
  {
    dataIndex: 'total_seconds_tracked',
    key: 'total_seconds_tracked',
    render: (value: GetCourseRegistrationsResponse['total_seconds_tracked']) => (
      <SecondsFormatter totalSeconds={value} />
    ),
    title: 'Time tracked'
  },
  {
    render: (_value, record) => (
      <ActionsColumn
        record={record}
        showPath={paths.courseRegistrationPath(record.id.toString())}
      />
    ),
    title: 'Actions'
  }
];

export const CourseRegistrationsPage = () => (
  <Card
    bordered={false}
    title={<StyledTitle title="Course Registrations" />}
  >
    <DataTable
      columns={columns}
      getMethod={getCourseRegistrations}
      perPage={20}
    />
  </Card>
);
