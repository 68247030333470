import {
  CourseCollectionCreatePage,
  CourseCollectionEditPage,
  CourseCollectionPage,
  CourseCollectionsPage
} from 'pages/course_collections';
import { RouteConfig } from 'react-router-config';
import * as paths from 'utils/paths';

export const courseCollectionRoutes: RouteConfig[] = [
  {
    breadcrumb: 'Collections',
    component: CourseCollectionsPage,
    exact: true,
    path: paths.courseCollectionsPath
  },
  {
    component: CourseCollectionCreatePage,
    path: paths.courseCollectionCreatePath
  },
  {
    breadcrumb: 'Edit',
    component: CourseCollectionEditPage,
    path: paths.courseCollectionEditPath(':id')
  },
  {
    breadcrumb: 'Collection',
    component: CourseCollectionPage,
    exact: true,
    path: paths.courseCollectionPath(':id')
  }
];
