import { Col, Row } from 'antd';
import { FC, ReactNode } from 'react';

interface CardEntryProps {
  children: ReactNode;
  title: string;
}

export const CardEntry: FC<CardEntryProps> = ({ children, title }) => (
  <Row style={{ marginBottom: '5px' }}>
    <Col flex="1">
      <div style={{ color: '#757575', fontSize: 'large' }}>{title}</div>
    </Col>
    <Col flex="0.1" />
    <Col flex="4">
      <div style={{ fontSize: 'large' }}>{children}</div>
    </Col>
  </Row>
);
