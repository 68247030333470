import { Card, Spin } from 'antd';
import { getBadgeGroup, GetBadgeGroupsResponse, useFetch } from 'api';
import { ApiImage } from 'components/api_image';

interface BadgeGroupExpandedProps {
  data: GetBadgeGroupsResponse;
}

export const BadgeGroupExpanded = ({ data }: BadgeGroupExpandedProps) => {
  const { data: fullData, isLoading } = useFetch(getBadgeGroup, [data.id]);

  if (isLoading || !fullData?.data) return <Spin />;

  const sortedBadges = fullData.data.badge_criteria.sort((a, b) => a.low_score - b.low_score);

  return (
    <Card
      headStyle={{ fontWeight: 'bold' }}
      title="Thresholds"
    >
      <div className="benchmarks-expanded">
        {sortedBadges.map(badge => (
          <div className="single-benchmark">
            <ApiImage image={badge.image} />
            <div className="single-benchmark-level">{badge.score_level}</div>
            <div>
              {badge.low_score} &ndash; {badge.high_score}
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};
