import { Card, DatePicker, DatePickerProps, Form, FormProps, InputNumber, Select } from 'antd';
import { createLicenseCohort, getOrganizationLicenseTiers, useFetch } from 'api';
import { CardHeader } from 'components/cards';
import { InputForm, SelectItem } from 'components/forms';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

export const LicenseCohortCreatePage = () => {
  const { id: organizationId } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const fetchParams = useMemo(
    () => [undefined, undefined, { organization_id: organizationId }],
    [organizationId]
  ) as [undefined, undefined, { organization_id: string }];
  const { data: orgLicenseTiersData } = useFetch(getOrganizationLicenseTiers, fetchParams);

  const onFinish: FormProps<{
    organization_license_tier_id: string;
    start_date?: DatePickerProps['value'];
    users_count: number;
  }>['onFinish'] = values =>
    createLicenseCohort({
      license_cohort: values
    }).then(() => {
      navigate(paths.organizationPath(organizationId));
    });

  return (
    <Card title={<CardHeader title="Create License Cohort" />}>
      <InputForm
        name="license-cohort-create"
        onFinish={onFinish}
      >
        <SelectItem
          label="License Tier"
          name={['organization_license_tier_id']}
          required
        >
          {orgLicenseTiersData?.data?.map(orgLicenseTier => (
            <Select.Option
              key={orgLicenseTier.id}
              value={orgLicenseTier.id}
            >
              <div
                style={{
                  display: 'flex'
                }}
              >
                <div style={{ display: 'inline-block', flex: 1 }}>
                  {orgLicenseTier.license_tier.name}
                </div>
                <div
                  style={{
                    color: orgLicenseTier.available_licenses > 0 ? 'green' : 'red',
                    display: 'inline-block'
                  }}
                >
                  <b>{orgLicenseTier.available_licenses}</b> available licenses
                </div>
              </div>
            </Select.Option>
          ))}
        </SelectItem>

        <Form.Item
          label="Users count"
          name="users_count"
          required
        >
          <InputNumber placeholder="0" />
        </Form.Item>

        <Form.Item
          label="Start date"
          name="start_date"
        >
          <DatePicker />
        </Form.Item>
      </InputForm>
    </Card>
  );
};
