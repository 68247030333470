import { FilePdfOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom-v5-compat';

interface PdfLinkProps {
  url?: string | null;
}
export const PdfLink = ({ url }: PdfLinkProps) => {
  if (url === undefined || url === null) return null;

  const fullUrl = url.startsWith('/') ? `${process.env.REACT_APP_API_BASE_URL}${url}` : url;

  return (
    <>
      <FilePdfOutlined />{' '}
      <Link
        target="_blank"
        to={fullUrl}
      >
        Open file
      </Link>
    </>
  );
};
