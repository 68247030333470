import type { AxiosResponse } from 'axios';
import type { RequireAtLeastOne } from 'type-fest';

import { instance, VendorNames } from '.';

interface CreateCourseParams {
  course: {
    author?: string;
    ce_accrediting_associations?: string[];
    ce_credit_types?: string[];
    ce_credits: string;
    ce_provider?: string;
    content_medium: string;
    description: string;
    external_accreditation_references?: {
      vendor_name: string;
      vendor_resource_id: string;
    }[];
    financial_disclosures?: {
      disclosure_text: string;
    }[];
    format_category: string;
    full_title: string;
    group_training?: boolean;
    hours: string;
    learning_objectives_attributes?: {
      name: string;
    }[];
    lgbq_communities?: string[];
    link: string;
    organization_id: string | null;
    organization_name: string;
    professional_groups?: string[];
    published?: boolean;
    scormcloud_id?: string;
    short_title: string;
    tgnc_communities?: string[];
    year?: string;
  };
}

interface CreateCourseResponse {
  age_groups: [];
  author: string;
  bipoc_communities: string[];
  cc_pillars: [];
  ce_accrediting_associations: string[];
  ce_credit_types: string[];
  ce_credits: string;
  ce_provider: string;
  content_medium: string;
  created_at: string;
  created_by: {
    auth0_id: string;
    city: string | null;
    completed_onboarding: boolean;
    created_at: string;
    email: string;
    first_name: string;
    hubspot_id: string | null;
    id: string;
    invite_link: string;
    is_active: boolean;
    is_clinical: boolean;
    last_name: string;
    permissions: OrganizationPermission[];
    requires_assessment: boolean;
    role: 'admin' | 'provider';
    state: string | null;
    status: 'activated' | 'created' | 'deactivated';
  };
  description: string;
  external_accreditation_references: {
    vendor_name: VendorNames;
    vendor_resource_id: string;
  }[];
  financial_disclosures: {
    disclosure_text: string;
  }[];
  format_category: string;
  full_title: string;
  group_training: boolean;
  hours: string;
  id: string;
  learning_objectives: {
    id: string;
    name: string;
  }[];
  lgbq_communities: string[];
  link: string;
  organization: {
    id: string;
    name: string;
  } | null;
  organization_id?: string | null;
  organization_name: string;
  other_communities: string[];
  platform: string | null;
  professional_groups: string[];
  published: boolean;
  scormcloud_id: string | null;
  short_title: string;
  specialties: string[];
  tgnc_communities: string[];
  topic_area_tags: [];
}

export interface GetCourseResponse {
  age_groups: [];
  author: string;
  bipoc_communities: string[];
  cc_pillars: string[];
  ce_accrediting_associations: string[];
  ce_credit_types: [];
  ce_credits: string;
  ce_provider: string;
  content_medium: string;
  created_at: string;
  created_by?: {
    auth0_id: string;
    city: string | null;
    completed_onboarding: boolean;
    created_at: string;
    email: string;
    first_name: string;
    hubspot_id: string | null;
    id: string;
    invite_link: string;
    is_active: boolean;
    is_clinical: boolean;
    last_name: string;
    permissions: OrganizationPermission[];
    requires_assessment: boolean;
    role: 'admin' | 'provider';
    state: string | null;
    status: 'activated' | 'created' | 'deactivated';
  };
  description: string;
  external_accreditation_references: {
    id: string;
    vendor_name: VendorNames;
    vendor_resource_id: string;
  }[];
  financial_disclosures: {
    id: string;
    vendor_name: VendorNames;
    vendor_resource_id: string;
  }[];
  format_category: string;
  full_title: string;
  group_training: boolean;
  hours: string;
  id: string;
  learning_objectives: {
    id: string;
    name: string;
  }[];
  lgbq_communities: string[];
  link: string;
  organization: {
    id: string;
    name: string;
  } | null;
  organization_id: string;
  organization_name: string;
  other_communities: string[];
  platform: string | null;
  professional_groups: string[];
  published: boolean;
  scormcloud_id: string | null;
  short_title: string;
  specialties: string[];
  tgnc_communities: string[];
  topic_area_tags: [];
  updated_at: string;
  updated_by?: {
    auth0_id: string;
    city: string | null;
    completed_onboarding: boolean;
    created_at: string;
    email: string;
    first_name: string;
    hubspot_id: string | null;
    id: string;
    invite_link: string;
    is_active: boolean;
    is_clinical: boolean;
    last_name: string;
    permissions: OrganizationPermission[];
    requires_assessment: boolean;
    role: 'admin' | 'provider';
    state: string | null;
    status: 'activated' | 'created' | 'deactivated';
  };
  url?: string | null;
  year: number;
}

export interface GetCoursesResponse {
  author: string;
  bipoc_communities: boolean;
  ce_credits: string;
  created_at: string;
  full_title: string;
  hours: string;
  id: string;
  lgbq_communities: boolean;
  link: string;
  organization: {
    id: string;
    name: string;
  } | null;
  organization_id: string;
  other_communities: boolean;
  published: boolean;
  scormcloud_id: string | null;
  short_title: string;
  tgnc_communities: boolean;
  updated_at: string;
  year: number;
}

interface UpdateCourseParams {
  course: RequireAtLeastOne<{
    author: string;
    bipoc_communities: string[];
    ce_accrediting_associations: string[];
    ce_credit_types: string[];
    ce_credits: string;
    ce_provider: string | null;
    content_medium: string;
    description: string;
    external_accreditation_references_attributes: (
      | {
          _destroy: true;
          id: string;
        }
      | {
          id?: string;
          vendor_name: string;
          vendor_resource_id: string;
        }
    )[];
    financial_disclosures_attributes: (
      | {
          _destroy: true;
          id: string;
        }
      | {
          disclosure_text: string;
        }
    )[];
    format_category: string;
    full_title: string;
    group_training: boolean;
    hours: string;
    learning_objectives_attributes: (
      | {
          _destroy: true;
          id: string;
        }
      | {
          id?: string;
          name: string;
        }
    )[];
    lgbq_communities: string[];
    link: string;
    organization_id?: string | null;
    organization_name: string;
    professional_groups: string[];
    published: boolean;
    scormcloud_id: string | null;
    short_title: string;
    tgnc_communities: string[];
    year: number;
  }>;
}

interface UpdateCourseResponse {
  age_groups: string[];
  author: string;
  bipoc_communities: string[];
  cc_pillars: string[];
  ce_accrediting_associations: string[];
  ce_credit_types: string[];
  ce_credits: string;
  ce_provider: string | null;
  content_medium: string;
  created_at: string;
  description: string;
  external_accreditation_references_attributes: {
    id: string;
    vendor_name: VendorNames;
    vendor_resource_id: string;
  }[];
  financial_disclosures_attributes: {
    disclosure_text: string;
    id: string;
  }[];
  format_category: string;
  full_title: string;
  group_training: boolean;
  hours: string;
  id: string;
  learning_objectives: string[];
  lgbq_communities: string[];
  link: string;
  organization: {
    id: string;
    name: string;
  } | null;
  organization_id: string;
  organization_name: string;
  other_communities: string[];
  platform: string | null;
  professional_groups: string[];
  published: boolean;
  scormcloud_id: string | null;
  short_title: string;
  specialties: string[];
  tgnc_communities: string[];
  topic_area_tags: [];
  updated_at: string;
  updated_by?: {
    auth0_id: string;
    city: string | null;
    completed_onboarding: boolean;
    created_at: string;
    email: string;
    first_name: string;
    hubspot_id: string | null;
    id: string;
    invite_link: string;
    is_active: boolean;
    is_clinical: boolean;
    last_name: string;
    permissions: OrganizationPermission[];
    requires_assessment: boolean;
    role: 'admin' | 'provider';
    state: string | null;
    status: 'activated' | 'created' | 'deactivated';
  };
  year: number;
}

export const createCourse = (params: CreateCourseParams) =>
  instance
    .post<
      ApiResponse<CreateCourseResponse>,
      AxiosResponse<ApiResponse<CreateCourseResponse>>,
      CreateCourseParams
    >('/admin/courses', params)
    .then(res => res.data);

export const getCourse = (id: string) =>
  instance.get<ApiResponse<GetCourseResponse>>(`/admin/courses/${id}`).then(res => res.data);

export const getCourses = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetCoursesResponse[], IndexPageMeta>>('/admin/courses', {
      params: {
        ...getParams,
        page,
        per_page: pageSize
      }
    })
    .then(res => res.data);

export const getTags = (type: string, name: string | null) =>
  instance
    .get<ApiResponse<string[]>>('/admin/courses/tags', {
      params: {
        name,
        type
      }
    })
    .then(res => res.data);

export const importCoursesCsvFile = (params: FormData) =>
  instance.post<ApiResponse<ImportError[]>>('/admin/courses/import', params).then(res => res.data);

export const updateCourse = (id: string, params: UpdateCourseParams) =>
  instance
    .patch<
      ApiResponse<UpdateCourseResponse>,
      AxiosResponse<ApiResponse<UpdateCourseResponse>>,
      UpdateCourseParams
    >(`/admin/courses/${id}`, params)
    .then(res => res.data);
