import { ArrowRightOutlined } from '@ant-design/icons';
import { Spin, Tag } from 'antd';
import { getUser, GetUserResponse } from 'api';
import { ActionsColumn } from 'components/tables';
import { MemberRoleTag } from 'components/tags/member_role_tag';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { organizationPath } from 'utils/paths';

interface Props {
  userId: string;
}

const UserOrganizationMembershipsCell = ({ userId }: Props) => {
  const [user, setUser] = useState<GetUserResponse | undefined>();

  useEffect(() => {
    getUser(userId).then(response => {
      setUser(response.data);
    });
  }, [userId]);

  return user !== undefined ? (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0' }}>
      {user.organization_memberships.map(membership => (
        <ActionsColumn
          buttonText={membership.organization.name}
          customItems={[
            {
              key: `${membership.id}-status`,
              label: (
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                  <span style={{ width: '20%' }}>Status:</span>
                  <Tag color={membership.status === 'activated' ? 'green' : 'blue'}>
                    {membership.status}
                  </Tag>
                </div>
              )
            },
            {
              key: `${membership.id}-role`,
              label: (
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                  <span style={{ width: '20%' }}>Role:</span>
                  <MemberRoleTag value={membership.member_role} />
                </div>
              )
            },
            {
              key: `${membership.id}-org`,
              label: (
                <Link to={organizationPath(membership.organization_id)}>
                  <ArrowRightOutlined /> View Organization
                </Link>
              )
            }
          ]}
          isInline
          record={membership}
        />
      ))}
    </div>
  ) : (
    <Spin />
  );
};

export default UserOrganizationMembershipsCell;
