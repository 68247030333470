import { BadgeGroupCreatePage, BadgeGroupPage, BadgeGroupsPage } from 'pages/badge_groups';
import { RouteConfig } from 'react-router-config';
import * as paths from 'utils/paths';

export const badgeGroupRoutes: RouteConfig[] = [
  {
    breadcrumb: 'Benchmark thresholds',
    component: BadgeGroupsPage,
    exact: true,
    path: paths.badgeGroupsPath
  },
  {
    breadcrumb: 'Create',
    component: BadgeGroupCreatePage,
    path: paths.badgeGroupCreatePath
  },
  {
    component: BadgeGroupPage,
    exact: true,
    path: paths.badgeGroupPath(':id')
  }
];
