import { Tag } from 'antd';

interface MemberRoleTagProps {
  value: keyof typeof memberRoleColorMapping;
}

const memberRoleColorMapping = {
  api_client: 'blue',
  member: 'green',
  network_manager: 'purple',
  superuser: 'red'
};

export const MemberRoleTag = ({ value }: MemberRoleTagProps) => (
  <Tag color={memberRoleColorMapping[value]}>{value}</Tag>
);
