import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Alert, Card, Form, FormProps, SelectProps } from 'antd';
import { getOrganizations, getUsers, GetUsersResponse, transferOrganizationMemberships } from 'api';
import { CardHeader } from 'components/cards';
import { InputForm, SearchInputItem } from 'components/forms';
import { DataTable } from 'components/tables';
import { DarkModeContext } from 'layouts/app_layout';
import { AlignType } from 'rc-table/lib/interface';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { organizationsPath, userPath } from 'utils/paths';

const OrganizationSearchInput = styled(SearchInputItem)`
  > div {
    justify-content: center;
  }
`;

const OrgNameContainer = styled.div`
  display: flex;
  gap: 0.25rem;
`;

const RemovedName = styled.s`
  border-radius: 0.25rem;
  border: 1px solid;
  padding: 0 5px;

  &.light {
    color: #ff4d4f;
    background: #fff2f0;
    border-color: #ffccc7;
  }

  &.dark {
    color: #dc4446;
    background: #2c1618;
    border-color: #5b2526;
  }
`;

const AddedName = styled.span`
  border-radius: 0.25rem;
  border: 1px solid;
  padding: 0 5px;

  &.dark {
    color: #6abe39;
    background: #162312;
    border-color: #274916;
  }

  &.light {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
    color: #389e0d;
  }
`;

const TransferAlert = styled(Alert)`
  margin-bottom: 2rem;
`;

interface SelectObject {
  disabled: undefined;
  key: string;
  label: string;
  title: undefined;
  value: string;
}

export const OrganizationTransferPage = () => {
  const name = 'organization_transfer';
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const isDarkMode = useContext(DarkModeContext);

  const [fromOrg, setFromOrg] = useState<string | null>(searchParams.get('from_id'));
  const [toOrg, setToOrg] = useState<string | null>(searchParams.get('to_id'));
  const [toOrgName, setToOrgName] = useState<string | null>(null);

  const onFromOrganizationChange: SelectProps<SelectObject | undefined>['onChange'] = orgId => {
    setFromOrg(orgId?.value ?? null);
    form.setFieldValue([name, 'from_org'], orgId ?? null);
  };

  const onToOrganizationChange: SelectProps<SelectObject | undefined>['onChange'] = orgId => {
    setToOrg(orgId?.value ?? null);
    setToOrgName(orgId?.label ?? null);
    form.setFieldValue([name, 'to_org'], orgId ?? null);
  };

  const handleMigrateUsers: FormProps<{
    organization_transfer: {
      from_org: SelectObject | string;
      to_org: SelectObject | string;
    };
  }>['onFinish'] = _values => {
    if (fromOrg === null || toOrg === null) {
      return;
    }

    transferOrganizationMemberships({
      from_org: fromOrg,
      to_org: toOrg
    }).then(({ data }) => {
      navigate(organizationsPath);
      if (data !== undefined) {
        toast.success(`${data.users_migrated} users have been migrated.`, {
          autoClose: 2000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'bottom-center',
          progress: undefined
        });
      }
    });
  };

  return (
    <Card
      headStyle={{ fontWeight: 'bold' }}
      style={{ minHeight: 'calc(100vh - 111px)' }}
      title={<CardHeader title="Transfer Organization Memberships" />}
    >
      <InputForm
        form={form}
        initialValues={{
          [name]: {
            from_org: fromOrg,
            to_org: toOrg
          }
        }}
        name={name}
        onFinish={handleMigrateUsers}
        submitIsDisabled={fromOrg === null || toOrg === null}
      >
        <OrganizationSearchInput
          getMethod={getOrganizations}
          getMethodArgs={[1, 20, { id: fromOrg !== null ? [fromOrg] : undefined }]}
          label="Migrate from:"
          name={[name, 'from_org']}
          paramsMethod={obj => ({
            key: obj.id,
            name: obj.name,
            value: obj.id
          })}
          required
          selectProps={{
            labelInValue: true,
            onChange: onFromOrganizationChange
          }}
        />
        <OrganizationSearchInput
          getMethod={getOrganizations}
          getMethodArgs={[1, 20, { id: toOrg !== null ? [toOrg] : undefined }]}
          label="Migrate to:"
          name={[name, 'to_org']}
          paramsMethod={obj => ({
            key: obj.id,
            name: obj.name,
            value: obj.id
          })}
          required
          selectProps={{
            labelInValue: true,
            onChange: onToOrganizationChange
          }}
        />
      </InputForm>
      {fromOrg !== null && (
        <>
          <TransferAlert
            description={
              <>
                This form will transfer <strong>ALL</strong> members of one organization to another.{' '}
                <strong>Please preview the results carefully before submitting.</strong>
              </>
            }
            message="Check your selection carefully."
            showIcon
            type="warning"
          />
          <DataTable
            columns={[
              {
                key: 'name',
                render: (_value, record) => (
                  <Link to={userPath(record.id)}>
                    {record.first_name} {record.last_name}
                  </Link>
                ),
                title: 'Name'
              },
              {
                key: 'email',
                render: (_value, record) => record.email,
                title: 'Email'
              },
              {
                align: 'center' as AlignType,
                key: 'organization_memberships',
                render: (user: GetUsersResponse) => {
                  const memberships = user.organization_memberships;
                  return (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                      {memberships.map(membership => {
                        const orgName =
                          membership.organization_id === fromOrg ? (
                            <>
                              <DeleteOutlined
                                style={{
                                  color: isDarkMode ? '#ff4d4f' : '#dc4446'
                                }}
                              />
                              <RemovedName className={isDarkMode ? 'dark' : 'light'}>
                                {membership.organization_name}
                              </RemovedName>
                            </>
                          ) : (
                            membership.organization_name
                          );
                        return <OrgNameContainer>{orgName}</OrgNameContainer>;
                      })}
                      {toOrg !== null && !memberships.find(m => m.organization_id === toOrg) && (
                        <OrgNameContainer>
                          <PlusCircleOutlined
                            style={{
                              color: isDarkMode ? '#6abe39' : '#389e0d'
                            }}
                          />
                          <AddedName className={isDarkMode ? 'dark' : 'light'}>
                            {toOrgName}
                          </AddedName>
                        </OrgNameContainer>
                      )}
                    </div>
                  );
                },
                title: 'Organizations'
              }
            ]}
            getMethod={getUsers}
            getParams={{ organization_id: fromOrg }}
            perPage={15}
          />
        </>
      )}
    </Card>
  );
};
