import { Form, FormItemProps, Select, SelectProps } from 'antd';
import { ReactNode } from 'react';

type SelectItemProps = FormItemProps & {
  children?: ReactNode;
  mode?: 'multiple' | 'tags';
  onChange?: SelectProps['onChange'];
  placeholder?: string;
  selectProps?: SelectProps;
};

export const SelectItem = ({
  children,
  mode,
  onChange,
  placeholder,
  required = false,
  rules,
  selectProps,
  ...itemProps
}: SelectItemProps) => (
  <Form.Item
    required={required}
    rules={
      required ? [{ message: 'This field is required', required: true }, ...(rules ?? [])] : rules
    }
    {...itemProps}
  >
    <Select
      mode={mode}
      {...selectProps}
      onChange={onChange}
      placeholder={placeholder}
    >
      {children}
    </Select>
  </Form.Item>
);
