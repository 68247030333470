import { instance } from '.';

interface GetCourseCollectionCompletionsResponse {
  completed_at: string;
  completed_courses_count: number;
  course_collection: {
    id: string;
    name: string;
  };
  course_collection_id: string;
  created_at: string;
  id: string;
  started_at: string;
  updated_at: string;
  user: {
    email: string;
    first_name: string;
    id: string;
    last_name: string;
  };
  user_id: string;
}

export const getCourseCollectionCompletions = (
  page?: string,
  pageSize?: string,
  getParams?: object
) =>
  instance
    .get<ApiResponse<GetCourseCollectionCompletionsResponse[], IndexPageMeta>>(
      '/admin/course_collection_completions',
      {
        params: {
          ...getParams,
          page,
          per_page: pageSize
        }
      }
    )
    .then(res => res.data);
