import { Card, DatePickerProps, Form, FormProps, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { getOrganization, getUsers, GetUsersResponse, useFetch, VendorNames } from 'api';
import { getBookmark, updateBookmark } from 'api/bookmarks';
import { CardHeader } from 'components/cards';
import { InputForm } from 'components/forms';
import { DataTable } from 'components/tables';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { BookmarkAssignmentForm } from '../../components/forms/bookmark_assignment_form';

const { Item } = Form;

export const BookmarkOrgAssignmentEditPage = () => {
  const navigate = useNavigate();
  const { id, orgId } = useParams<{ id: string; orgId: string }>();
  const { data: orgData } = useFetch(getOrganization, [orgId]);
  const { data } = useFetch(getBookmark, [id]);
  const [form] = useForm();

  const onFinish: FormProps<{
    bookmarks: [
      {
        assigned_by_id: string;
        assigned_by_type: 'Organization';
        created_by_id: string;
        email_send_date: DatePickerProps['value'] | null;
        end_date: DatePickerProps['value'] | null;
        id: string;
        organization_id: string;
        resource:
          | {
              author: string;
              bipoc_communities: boolean;
              ce_credits: string;
              created_at: string;
              full_title: string;
              hours: string;
              id: string;
              lgbq_communities: boolean;
              link: string;
              other_communities: boolean;
              published: boolean;
              scormcloud_id: null;
              short_title: string;
              tgnc_communities: boolean;
              updated_at: string;
              year: number;
            }
          | {
              communities: ScoreType[];
              created_at: string;
              credits: string;
              description: string;
              external_accreditation_references: {
                id: string;
                vendor_name: VendorNames;
                vendor_resource_id: string;
              }[];
              hours: string;
              id: string;
              learning_objectives: string[];
              level: 'advanced' | 'beginner' | 'foundation' | 'intermediate';
              name: string;
              published: 'not_published' | 'pinned' | 'published';
              specialties: string[];
              updated_at: string;
            };
        resource_id: string;
        resource_type: 'Course' | 'CourseCollection';
        start_date: DatePickerProps['value'] | null;
        user_id: null;
      }
    ];
  }>['onFinish'] = ({ bookmarks }) => {
    const { organization_id, ...bookmark } = bookmarks[0];
    updateBookmark(bookmark.id, { bookmark }).then(() => navigate(paths.organizationPath(orgId)));
  };

  if (!data?.data || !orgData) return <Spin />;

  return (
    <Card
      title={
        <CardHeader
          title={
            <>
              Edit assigned education to{' '}
              <Link to={paths.organizationPath(orgId)}>{orgData.data?.name}</Link>
            </>
          }
        />
      }
    >
      <InputForm
        form={form}
        name="assign-bookmark"
        onFinish={onFinish}
      >
        <Item label="Educations">
          <Form.List
            initialValue={[
              {
                ...data.data,
                email_send_date:
                  data.data.email_send_date === null ? null : dayjs(data.data.email_send_date),
                end_date: data.data.end_date === null ? null : dayjs(data.data.end_date),
                start_date: data.data.start_date === null ? null : dayjs(data.data.start_date)
              }
            ]}
            name="bookmarks"
          >
            {fields => (
              <>
                {fields.map(({ key, name }) => (
                  <div key={key}>
                    <BookmarkAssignmentForm
                      assignedById={orgId}
                      name={name}
                      page="edit"
                      parentForm={form}
                    />
                  </div>
                ))}
              </>
            )}
          </Form.List>
        </Item>
        {data.data.user_id !== null && (
          <DataTable
            columns={[
              {
                dataIndex: 'first_name',
                key: 'first_name',
                render: (first_name: GetUsersResponse['first_name'], record: GetUsersResponse) =>
                  `${first_name} ${record.last_name}`,
                title: 'Name'
              },
              {
                dataIndex: 'email',
                key: 'email',
                title: 'Email'
              }
            ]}
            getMethod={getUsers}
            getParams={{
              organization_id: orgId,
              'user_ids[]': [data.data.user_id]
            }}
            pagination={false}
          />
        )}
      </InputForm>
    </Card>
  );
};
