import { FormProps } from 'antd';
import { Store } from 'antd/es/form/interface';
import { InputForm, InputItem, SwitchItem } from 'components/forms';

interface FeatureFlagFormProps {
  initialData?: Store;
  name: string;
  onFinish: NonNullable<FormProps['onFinish']>;
}

export const FeatureFlagForm = ({ initialData, name, onFinish }: FeatureFlagFormProps) => (
  <InputForm
    initialValues={initialData}
    name={name}
    onFinish={onFinish}
  >
    <InputItem
      label="Flag name"
      name="flag_name"
      required
    />
    <SwitchItem
      label="Enabled"
      name="enabled"
    />
  </InputForm>
);
