import { Card, DatePickerProps, FormProps } from 'antd';
import { createUser } from 'api';
import { CardHeader } from 'components/cards';
import { useNavigate } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { UserForm, UserFormProps } from './user_form';

export const UserCreatePage = () => {
  const navigate = useNavigate();

  const onFinish: FormProps<{
    create_hubspot_contact: boolean;
    delayed_invite_date: DatePickerProps['value'] | null;
    email: string;
    first_name?: string;
    hubspot_id?: string;
    is_clinical: boolean;
    last_name?: string;
    organization_memberships: {
      employee_id: string;
      member_role: 'member' | 'network_manager' | 'superuser';
      organization_id: {
        disabled: undefined;
        key: string;
        label: string;
        title: undefined;
        value: string;
      };
      organization_license_tier_id: string;
      status: 'activated' | 'deactivated';
    }[];
    role: 'admin' | 'api_client' | 'provider';
    send_invite_emails: boolean;
    user_info: {
      npi?: string;
    };
  }>['onFinish'] = values => {
    const { organization_memberships, user_info, ...userValues } = values;
    const params = {
      organization_memberships: organization_memberships.map(({ organization_id, ...values }) => ({
        organization_id: organization_id.value,
        ...values
      })),
      user_info_attributes: {
        npi: user_info.npi === '' ? undefined : user_info.npi
      },
      ...userValues
    };

    return createUser({ user: params }).then(() => {
      navigate(paths.usersPath);
    });
  };

  const initialData: UserFormProps['initialData'] = {
    data: {
      create_hubspot_contact: true,
      delayed_invite_date: null,
      is_clinical: true,
      organization_memberships: [{ employee_id: '', member_role: 'member', status: 'activated' }],
      role: 'provider',
      send_invite_emails: true,
      status: 'created'
    },
    page: 'create'
  };

  return (
    <Card title={<CardHeader title="Create user" />}>
      <UserForm
        initialData={initialData}
        name="user-create"
        onFinish={onFinish}
      />
    </Card>
  );
};
