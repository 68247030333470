import { instance } from '.';

interface CourseActivity {
  course_registration_id: string;
  created_at: string;
  id: string;
  payload: string;
  updated_at: string;
}

export interface GetCourseActivitiesResponse extends CourseActivity {}

export const getCourseActivities = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetCourseActivitiesResponse[], IndexPageMeta>>('/admin/course_activities', {
      params: {
        ...getParams,
        page,
        per_page: pageSize
      }
    })
    .then(res => res.data);
