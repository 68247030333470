import { Card, FormProps } from 'antd';
import { createPathway, GetPathwayResponse } from 'api';
import { CardHeader } from 'components/cards';
import { useNavigate } from 'react-router-dom-v5-compat';
import { toFormData } from 'utils/forms';
import * as paths from 'utils/paths';

import { PathwayForm } from './pathway_form';

export interface PathwayFormProps extends Omit<GetPathwayResponse, 'image' | 'skills'> {
  image?: { file: File; fileList: File[] };
  skills: { name: string }[];
}

export const PathwayCreatePage = () => {
  const navigate = useNavigate();

  const onFinish: FormProps<PathwayFormProps>['onFinish'] = ({
    image,
    resources,
    skills,
    ...values
  }) =>
    createPathway(
      toFormData({
        pathway: {
          ...values,
          image: image?.file,
          pathway_resources_attributes: resources.map((resource, index) => ({
            position: index,
            resource_id: resource.resource_id,
            resource_type: resource.resource_type
          })),
          skills: skills.map(skill => skill.name)
        }
      })
    ).then(({ data }) => {
      data?.id !== undefined && navigate(paths.pathwayPath(data.id));
    });

  return (
    <Card title={<CardHeader title="Create pathway" />}>
      <PathwayForm
        initialImage={null}
        name="pathway-create"
        onFinish={onFinish}
      />
    </Card>
  );
};
