/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, Dropdown, Popconfirm, Tag } from 'antd';
import { deleteLicenseTier, getLicenseTier, GetLicenseTierResponse, useFetch } from 'api';
import { CardHeader } from 'components/cards';
import { CardEntryList } from 'components/cards/card_entry_list';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom-v5-compat';
import { humanReadablePermission } from 'utils/licenses';
import { licenseTierEditPath, licenseTiersPath } from 'utils/paths';

export const LicenseTierPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(getLicenseTier, [id]);
  const navigate = useNavigate();

  return (
    <div className="site-card-border-less-wrapper">
      {data?.data && (
        <Card
          headStyle={{ fontWeight: 'bold' }}
          style={{ minHeight: 'calc(100vh - 111px)' }}
          title={
            <CardHeader
              editPath={licenseTierEditPath(data.data.id)}
              title={`License Tier "${data.data.name}"`}
            >
              <Dropdown
                menu={{
                  items: [
                    {
                      danger: true,
                      key: 'delete',
                      label: (
                        <Popconfirm
                          cancelText="Cancel"
                          okText="Yes"
                          onConfirm={() =>
                            deleteLicenseTier(id).then(() => navigate(licenseTiersPath))
                          }
                          title="Are you sure you want to delete this record?"
                        >
                          <a>Delete</a>
                        </Popconfirm>
                      )
                    }
                  ]
                }}
              >
                <Button
                  size="large"
                  style={{}}
                >
                  Actions
                </Button>
              </Dropdown>
            </CardHeader>
          }
        >
          <CardEntryList
            fieldsMap={[
              { key: 'id', title: 'ID' },
              { key: 'name', title: 'Name' },
              {
                key: 'organization_permissions',
                render: (permissions: GetLicenseTierResponse['organization_permissions']) => (
                  <>
                    {permissions.map((permission, i) => (
                      <Tag key={i}>{humanReadablePermission(permission)}</Tag>
                    ))}
                  </>
                ),
                title: 'Organization permissions'
              },
              {
                key: 'user_permissions',
                render: (permissions: GetLicenseTierResponse['user_permissions']) => (
                  <>
                    {permissions.map((permission, i) => (
                      <Tag key={i}>{humanReadablePermission(permission)}</Tag>
                    ))}
                  </>
                ),
                title: 'User permissions'
              },
              { key: 'created_at', title: 'Created at', type: 'date' },
              { key: 'updated_at', title: 'Updated at', type: 'date' }
            ]}
            values={data.data}
          />
        </Card>
      )}
    </div>
  );
};
