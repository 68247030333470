import { useEffect, useState } from 'react';

export const useDebounce = <T,>(defaultValue: T, delay: number) => {
  const [value, setValue] = useState(defaultValue);
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [delay, value]);

  return [debouncedValue, setValue] as const;
};
