import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, FormProps, Select } from 'antd';
import { getLicenseTiers, GetOrganizationResponse, useFetch } from 'api';
import { InputForm, InputItem, SelectItem, SwitchItem } from 'components/forms';
import styled from 'styled-components';

interface OrganizationFormProps {
  initialData?: GetOrganizationResponse;
  name: string;
  onFinish: NonNullable<FormProps['onFinish']>;
}

const SmallText = styled.div`
  font-size: 0.6rem;
  line-height: 1;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

const { Item } = Form;

export const OrganizationForm = ({ initialData, name, onFinish }: OrganizationFormProps) => {
  const { data: licenseTiersData } = useFetch(getLicenseTiers, []);

  return (
    <InputForm
      initialValues={initialData}
      name={name}
      onFinish={onFinish}
    >
      <InputItem
        label="Name"
        name="name"
        required
      />
      <InputItem
        label="SSO connection ID"
        name="sso_connection_id"
      />
      <InputItem
        label="Hubspot company ID"
        name="hubspot_id"
        required
      />
      <InputItem
        label="Referral code"
        name="referral_code"
      />
      <SwitchItem
        label="Direct contract"
        name="direct_contract"
        switchProps={{ defaultChecked: true }}
      />

      <Item
        label={
          <LabelWrapper>
            Email domains<SmallText>Include only the portion after the @ symbol.</SmallText>
          </LabelWrapper>
        }
      >
        <Form.List name="organization_email_domains">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div
                  key={key}
                  style={{
                    alignItems: 'baseline',
                    display: 'flex',
                    gap: '8px'
                  }}
                >
                  <InputItem
                    {...restField}
                    name={[name, 'domain']}
                    required
                    style={{ width: '100%' }}
                  />
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </div>
              ))}

              <Item>
                <Button
                  block
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                  type="dashed"
                >
                  Add email domain
                </Button>
              </Item>
            </>
          )}
        </Form.List>
      </Item>

      <Item label="License Tiers">
        <Form.List name="organization_license_tiers">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div
                  key={key}
                  style={{
                    alignItems: 'baseline',
                    display: 'flex',
                    gap: '8px'
                  }}
                >
                  <SelectItem
                    {...restField}
                    name={[name, 'license_tier_id']}
                    required
                    style={{ width: '100%' }}
                  >
                    {licenseTiersData?.data?.map(licenseTier => (
                      <Select.Option
                        key={licenseTier.id}
                        value={licenseTier.id}
                      >
                        {licenseTier.name}
                      </Select.Option>
                    ))}
                  </SelectItem>

                  <MinusCircleOutlined onClick={() => remove(name)} />
                </div>
              ))}

              <Item>
                <Button
                  block
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                  type="dashed"
                >
                  Add License Tier
                </Button>
              </Item>
            </>
          )}
        </Form.List>
      </Item>

      <SwitchItem
        label="Unlimited license"
        name="unlimited_license"
      />

      <SwitchItem
        label="Network organization"
        name="network"
      />
    </InputForm>
  );
};
