import { instance } from '.';

interface BadgeGroup {
  badge_criteria: {
    high_score: number;
    id: string;
    image: {
      url: string | null;
    };
    low_score: number;
    score_level: string;
  }[];
  end_date: string | null;
  id: string;
  is_active: boolean;
  score_type: ScoreType;
  start_date: string | null;
  updated_at: string;
  updated_by?: {
    email: string;
    first_name: string;
    id: string;
    last_name: string;
  };
}

interface CreateBadgeGroupResponse extends BadgeGroup {}

export interface GetBadgeGroupResponse extends BadgeGroup {}

export interface GetBadgeGroupsResponse
  extends Pick<BadgeGroup, 'end_date' | 'id' | 'is_active' | 'score_type' | 'start_date'> {}

export const createBadgeGroup = (params: FormData) =>
  instance
    .post<ApiResponse<CreateBadgeGroupResponse>>('/admin/badge_criteria_groups', params)
    .then(res => res.data);

export const getBadgeGroup = (id: string) =>
  instance
    .get<ApiResponse<GetBadgeGroupResponse>>(`/admin/badge_criteria_groups/${id}`)
    .then(res => res.data);

export const getBadgeGroups = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetBadgeGroupsResponse[], IndexPageMeta>>('/admin/badge_criteria_groups', {
      params: {
        ...getParams,
        page,
        per_page: pageSize
      }
    })
    .then(res => res.data);
