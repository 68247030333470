import { PlusOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { getBadgeGroups, GetBadgeGroupsResponse } from 'api';
import { BooleanIcon } from 'components/boolean_icon';
import { StyledTitle } from 'components/cards';
import { ActionsColumn, DataTable } from 'components/tables';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Link } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { BadgeGroupExpanded } from './badge_group_expanded';

dayjs.extend(advancedFormat);

const columns: ColumnsType<GetBadgeGroupsResponse> = [
  {
    dataIndex: 'score_type',
    key: 'score_type',
    title: 'Community'
  },
  {
    dataIndex: 'is_active',
    key: 'is_active',
    render: (value: GetBadgeGroupsResponse['is_active']) => <BooleanIcon value={value} />,
    title: 'Active'
  },
  {
    dataIndex: 'start_date',
    key: 'start_date',
    render: (value: GetBadgeGroupsResponse['start_date']) =>
      value !== null ? dayjs(value).format('MM/DD/YYYY HH:mm:ss ([UTC]ZZ)') : '–',
    title: 'Start date',
    width: '25%'
  },
  {
    dataIndex: 'end_date',
    key: 'end_date',
    render: (value: GetBadgeGroupsResponse['end_date']) =>
      value !== null ? dayjs(value).format('MM/DD/YYYY HH:mm:ss ([UTC]ZZ)') : '–',
    title: 'End date',
    width: '25%'
  },
  {
    key: 'actions',
    render: (_value, record) => (
      <ActionsColumn
        record={record}
        showPath={paths.badgeGroupPath(record.id.toString())}
      />
    ),
    title: 'Actions'
  }
];

export const BadgeGroupsPage = () => {
  const expandRow = (record: GetBadgeGroupsResponse) => <BadgeGroupExpanded data={record} />;

  return (
    <Card
      bordered={false}
      title={
        <StyledTitle
          buttonsContents={[
            <Link
              key="2"
              to={paths.badgeGroupCreatePath}
            >
              <PlusOutlined /> Create Benchmark thresholds
            </Link>
          ]}
          title="Benchmark thresholds"
        />
      }
    >
      <DataTable
        columns={columns}
        expandable={{
          childrenColumnName: 'expand',
          expandedRowRender: record => <div style={{ margin: 0 }}>{expandRow(record)}</div>
        }}
        getMethod={getBadgeGroups}
      />
    </Card>
  );
};
