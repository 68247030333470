import AppLayout from 'layouts/app_layout';
import { MismatchedNpiDataPage } from 'pages/npi_registry';
import { TagsPage } from 'pages/tags';
import React from 'react';
import { RouteConfig } from 'react-router-config';
import { Navigate } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { badgeGroupRoutes } from './badge_group_routes';
import { communicationInfoRoutes } from './communication_info_routes';
import { courseCollectionRoutes } from './course_collection_routes';
import { courseRegistrationRoutes } from './course_registration_routes';
import { courseRoutes } from './course_routes';
import { educationRoutes } from './education_routes';
import { featureFlagRoutes } from './feature_flag_routes';
import { licenseTierRoutes } from './license_tier_routes';
import { NetworkRoutes } from './network_routes';
import { organizationRoutes } from './organization_routes';
import { pathwayRoutes } from './pathway_routes';
import { userRoutes } from './user_routes';

export const AdminRoutes: RouteConfig[] = [
  {
    breadcrumb: 'NPI Issues',
    component: MismatchedNpiDataPage,
    exact: true,
    path: paths.mismatchedRegistryDataPath
  },
  {
    breadcrumb: 'Tags',
    component: TagsPage,
    path: paths.tagsPath
  },
  ...badgeGroupRoutes,
  ...communicationInfoRoutes,
  ...courseCollectionRoutes,
  ...courseRoutes,
  ...organizationRoutes,
  ...userRoutes,
  ...courseRegistrationRoutes,
  ...educationRoutes,
  ...licenseTierRoutes,
  ...featureFlagRoutes,
  ...NetworkRoutes,
  ...pathwayRoutes
];

export const routes: RouteConfig[] = [
  {
    component: AppLayout,

    routes: [
      ...AdminRoutes,
      {
        component: () => React.createElement(Navigate, { to: paths.usersPath })
      }
    ]
  }
];
