/* eslint-disable jsx-a11y/anchor-is-valid */
import { LogoutOutlined } from '@ant-design/icons';
import { LogoutOptions } from '@auth0/auth0-react';
import { Popconfirm } from 'antd';
import { Link } from 'react-router-dom-v5-compat';
import { store } from 'store';
import * as paths from 'utils/paths';

interface SignOutProprs {
  logoutAuth0: (options?: LogoutOptions) => void;
}

export const SignOut = ({ logoutAuth0 }: SignOutProprs) => {
  const handleLogout = () => {
    logoutAuth0({
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      logoutParams: {
        returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI
      }
    });
    store.dispatch('signOut');
  };

  return (
    <Link to={paths.homePath}>
      <Popconfirm
        cancelText="No"
        okText="Yes"
        onConfirm={handleLogout}
        title="Are you sure you want to log out?"
      >
        <a>
          <LogoutOutlined /> Log out
        </a>
      </Popconfirm>
    </Link>
  );
};
