/* eslint-disable jsx-a11y/anchor-is-valid */
import { CopyOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Collapse,
  Divider,
  Dropdown,
  message,
  Popconfirm,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  deleteUser,
  getCommunicationInfos,
  GetCommunicationInfosResponse,
  getCourseRatings,
  GetCourseRatingsResponse,
  getCourseRegistrations,
  GetCourseRegistrationsResponse,
  getEducations,
  getNpiRegistryData,
  getScores,
  getUser,
  GetUserResponse,
  sendInviteEmail,
  updateUser,
  UpdateUserParams,
  useFetch
} from 'api';
import { deleteOrganizationMembership } from 'api/organization_memberships';
import { ApiImage } from 'components/api_image';
import { BooleanIcon } from 'components/boolean_icon';
import { CardHeader } from 'components/cards';
import { CardEntryList } from 'components/cards/card_entry_list';
import { DateFormatter } from 'components/date_formatter';
import { PdfLink } from 'components/pdf_link';
import { ActionsColumn, DataTable } from 'components/tables';
import { MemberRoleTag } from 'components/tags/member_role_tag';
import { NpiRegistryDatumCard } from 'pages/npi_registry';
import { PopoverButton } from 'pages/organizations';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom-v5-compat';
import {
  communicationInfoPath,
  coursePath,
  courseRegistrationPath,
  organizationPath,
  userEditPath,
  userScoringPath,
  usersPath
} from 'utils/paths';

import { scoreColumns } from './user_components';
import NonClinicalExperiencesPanel from './userPageComponents/non_clinical_experiences_panel';

const emailsColumns: ColumnsType<GetCommunicationInfosResponse> = [
  {
    dataIndex: 'email_type',
    key: 'email_type',
    title: 'E-mail type'
  },
  {
    dataIndex: 'user',
    key: 'user',
    render: (value: GetCommunicationInfosResponse['user']) => value!.email,
    title: 'Addressee'
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: 'Status'
  },
  {
    dataIndex: 'email_delivered_at',
    key: 'email_delivered_at',
    title: 'Delivered at',
    width: '15%'
  },
  {
    dataIndex: 'email_opened_at',
    key: 'email_opened_at',
    title: 'Opened at',
    width: '15%'
  },
  {
    dataIndex: 'email_link_clicked_at',
    key: 'email_link_clicked_at',
    title: 'Link clicked at',
    width: '15%'
  },
  {
    dataIndex: 'sg_machine_open',
    key: 'sg_machine_open',
    title: 'SG opened at',
    width: '15%'
  },
  {
    key: 'actions',
    render: (_value, record) => (
      <ActionsColumn
        record={record}
        showPath={communicationInfoPath(record.id.toString())}
      />
    ),
    title: 'Actions'
  }
];

const courseRatingColumns: ColumnsType<GetCourseRatingsResponse> = [
  {
    key: 'course',
    render: (_value, record) => (
      <Link to={coursePath(record.course.id)}>{record.course.short_title}</Link>
    ),
    title: 'Course'
  },
  {
    dataIndex: 'rating',
    key: 'rating',
    title: 'Rating'
  },
  {
    dataIndex: 'comment',
    key: 'comment',
    title: 'Comment'
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
    render: (value: GetCourseRatingsResponse['created_at']) => <DateFormatter value={value} />,
    title: 'Created at'
  }
];

const courseRegistrationColumns: ColumnsType<GetCourseRegistrationsResponse> = [
  {
    key: 'course',
    render: (_value, record) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (record.course !== null) {
        return <Link to={coursePath(record.course.id)}>{record.course.short_title}</Link>;
      } else {
        return 'Course not found. This could be because the original course was updated to use a different scormcloud course.';
      }
    },
    title: 'Course'
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
    render: (value: GetCourseRegistrationsResponse['created_at']) => (
      <DateFormatter value={value} />
    ),
    title: 'Created at'
  },
  {
    dataIndex: 'completed_at',
    key: 'completed_at',
    render: (value: GetCourseRegistrationsResponse['completed_at']) => (
      <DateFormatter value={value} />
    ),
    title: 'Completed at'
  },
  {
    dataIndex: 'total_seconds_tracked',
    key: 'total_seconds_tracked',
    title: 'Seconds tracked'
  },
  {
    render: (_value, record) => (
      <ActionsColumn
        record={record}
        showPath={courseRegistrationPath(record.id.toString())}
      />
    ),
    title: 'Actions'
  }
];

const { Panel } = Collapse;

export const UserPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: userData, isLoading, refetch } = useFetch(getUser, [id]);
  const [isUpdating, setIsUpdating] = useState(false);

  const npiGetParams = useMemo(() => ['1', '10', { user_id: id }], [id]) as [
    string,
    string,
    object
  ];
  const { data: npiRegistryData } = useFetch(getNpiRegistryData, npiGetParams);
  const navigate = useNavigate();

  const sendUpdateRequest = (userParams: UpdateUserParams['user']) => {
    if (isUpdating) return;

    setIsUpdating(true);
    updateUser(userData!.data!.id, { user: userParams }).finally(() => {
      refetch();
      setIsUpdating(false);
    });
  };

  const HUBSPOT_USER_BASE_URL = process.env.REACT_APP_HUBSPOT_USER_BASE_URL;

  if (!userData) return <Spin size="large" />;

  return (
    <div className="site-card-border-less-wrapper">
      {userData.data && (
        <Card
          headStyle={{ fontWeight: 'bold' }}
          style={{ minHeight: 'calc(100vh - 111px)' }}
          title={
            <CardHeader
              customPaths={[{ name: 'Score user', path: userScoringPath(userData.data.id) }]}
              editPath={userEditPath(userData.data.id)}
              title={` User ${userData.data.first_name} ${userData.data.last_name}`}
            >
              <Dropdown
                menu={{
                  items: [
                    {
                      key: 'send_manual_invite',
                      label: (
                        <Popconfirm
                          cancelText="Cancel"
                          okText="Send"
                          onConfirm={() => sendInviteEmail(id)}
                          title="This will send a new link to users who cannot access their original invitation
                          link and will replace any previous links provided. To confirm this action click below."
                        >
                          <a>Send invite</a>
                        </Popconfirm>
                      )
                    },
                    {
                      disabled: isUpdating || isLoading,
                      key: 'toggle_onboarding',
                      label: (
                        <Popconfirm
                          cancelText="Cancel"
                          okText="Ok"
                          onConfirm={() =>
                            sendUpdateRequest({
                              completed_onboarding: !(userData.data?.completed_onboarding ?? false)
                            })
                          }
                          title={`This will update this user's onboarding status to ${
                            userData.data.completed_onboarding ? 'FALSE' : 'TRUE'
                          }. To confirm this action click below.`}
                        >
                          <a>Toggle onboarding</a>
                        </Popconfirm>
                      )
                    },
                    {
                      danger: true,
                      disabled: isLoading || isUpdating || userData.data.status === 'created',
                      key: 'change_status',
                      label: (
                        <Popconfirm
                          cancelText="Cancel"
                          okText="Yes"
                          onConfirm={() =>
                            sendUpdateRequest({
                              status:
                                userData.data!.status === 'deactivated'
                                  ? 'activated'
                                  : 'deactivated'
                            })
                          }
                          title={`Are you sure you want to ${
                            userData.data.status === 'deactivated' ? 'activate' : 'deactivate'
                          } this user?`}
                        >
                          <a>
                            {userData.data.status === 'deactivated' ? 'Activate' : 'Deactivate'}{' '}
                          </a>
                        </Popconfirm>
                      )
                    },
                    {
                      danger: true,
                      disabled: isLoading || isUpdating,
                      key: 'delete',
                      label: (
                        <Popconfirm
                          cancelText="Cancel"
                          okText="Yes"
                          onConfirm={() =>
                            deleteUser(userData.data!.id).then(() => navigate(usersPath))
                          }
                          title="Are you sure you want to delete this record?"
                        >
                          <a>Delete</a>
                        </Popconfirm>
                      )
                    }
                  ]
                }}
              >
                <Button
                  size="large"
                  style={{}}
                >
                  Actions
                </Button>
              </Dropdown>
            </CardHeader>
          }
        >
          <CardEntryList
            fieldsMap={[
              { key: 'id', title: 'ID' },
              { key: 'first_name', title: 'First name' },
              { key: 'last_name', title: 'Last name' },
              { key: 'email', title: 'E-mail' },
              { key: 'role', title: 'Role' },
              { key: 'auth0_id', title: 'Auth0 ID' },
              {
                key: 'hubspot_id',
                render: (hubspot_id: GetUserResponse['hubspot_id']) =>
                  Boolean(hubspot_id) ? (
                    <Space>
                      <Tooltip title={hubspot_id}>
                        <Button
                          onClick={() => {
                            navigator.clipboard.writeText(`${hubspot_id!}`);
                            message.success('Copied to clipboard');
                          }}
                          size="small"
                          type="dashed"
                        >
                          <CopyOutlined />
                        </Button>
                      </Tooltip>
                      <Tooltip title={`${HUBSPOT_USER_BASE_URL}${hubspot_id}`}>
                        <a
                          href={`${HUBSPOT_USER_BASE_URL}${hubspot_id}`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {hubspot_id}
                        </a>
                      </Tooltip>
                    </Space>
                  ) : (
                    <span style={{ color: 'grey' }}>–</span>
                  ),
                title: 'Hubspot ID'
              },
              { key: 'is_active', title: 'Is active' },
              { key: 'status', title: 'Status' },
              {
                key: 'status_updated_at',
                title: 'Status updated at',
                type: 'date'
              },
              { key: 'is_clinical', title: 'Is clinical' },
              { key: 'completed_onboarding', title: 'Completed onboarding' },
              { key: 'completed_onboarding_at', title: 'Completed onboarding at', type: 'date' },
              {
                key: 'permissions',
                render: (permissions: GetUserResponse['permissions']) => (
                  <>
                    {permissions.map((permission, i) => (
                      <Tag key={i}>{permission}</Tag>
                    ))}
                  </>
                ),
                title: 'User permissions'
              },
              {
                key: 'professional_group',
                render: (group: GetUserResponse['professional_group']) => group?.name,
                title: 'Professional group'
              },
              {
                key: 'invite_link',
                render: (inviteLink: GetUserResponse['invite_link'], userRecord) =>
                  userRecord.status === 'created' ? (
                    <Space style={{ alignItems: 'flex-start' }}>
                      <Tooltip title={inviteLink}>
                        <Button
                          onClick={() => {
                            navigator.clipboard.writeText(inviteLink);
                            message.success('Copied to clipboard');
                          }}
                          size="small"
                          type="dashed"
                        >
                          <CopyOutlined />
                        </Button>
                      </Tooltip>
                      <div style={{ wordBreak: 'break-all' }}>{inviteLink}</div>
                    </Space>
                  ) : (
                    <span style={{ color: 'grey' }}>–</span>
                  ),
                title: 'Invite Link'
              },
              { key: 'invited_at', title: 'Invited at', type: 'date' },
              { key: 'sign_up_source', title: 'Sign up source' },
              { key: 'created_at', title: 'Created at', type: 'date' },
              { key: 'updated_at', title: 'Updated at', type: 'date' }
            ]}
            values={userData.data}
          />
          <Divider />
          <DataTable
            columns={scoreColumns}
            getMethod={getScores}
            getParams={{
              is_active: true,
              user_info_id: userData.data.user_info.id
            }}
            pagination={false}
            style={{ marginBottom: 20 }}
            title={() => <h2 style={{ margin: '0' }}>Benchmarks summary</h2>}
          />
          <Collapse>
            <Panel
              key="user_info"
              header="User Info"
            >
              <CardEntryList
                fieldsMap={[
                  { key: 'npi', title: 'NPI' },
                  { key: 'phone_number', title: 'Phone number' },
                  { key: 'sms_consent', title: 'SMS consent' },
                  { key: 'languages', title: 'Languages' },
                  { key: 'pronouns', title: 'Pronouns' },
                  { key: 'gender_identity', title: 'Gender identity' },
                  { key: 'sexual_orientation', title: 'Sexual orientation' },
                  { key: 'race_ethnicity', title: 'Race ethnicity' },
                  { key: 'privacy_policy', title: 'Privacy policy' },
                  {
                    key: 'acknowledged_data_validity',
                    title: 'Acknowledged data validity'
                  },
                  { key: 'data_source', title: 'Data source' }
                ]}
                values={userData.data.user_info}
              />
            </Panel>
            <Panel
              key="org_memberships"
              header="Organization Memberships"
              style={{ paddingBottom: 0 }}
            >
              <Table
                bordered
                columns={[
                  {
                    key: 'organization',
                    render: (record: GetUserResponse['organization_memberships'][0]) => (
                      <Link to={organizationPath(record.organization.id)}>
                        {record.organization.name}
                      </Link>
                    ),
                    title: 'Organization'
                  },
                  {
                    key: 'employee_id',
                    render: (record: GetUserResponse['organization_memberships'][0]) =>
                      record.employee_id,
                    title: 'Employee ID'
                  },
                  {
                    key: 'status',
                    render: (record: GetUserResponse['organization_memberships'][0]) => {
                      let color = 'gray';
                      if (record.status === 'activated') color = 'blue';
                      if (record.status === 'deactivated') color = 'red';
                      return <Tag color={color}>{record.status}</Tag>;
                    },
                    title: 'Status'
                  },
                  {
                    key: 'member_role',
                    render: (record: GetUserResponse['organization_memberships'][0]) => (
                      <MemberRoleTag value={record.member_role} />
                    ),
                    title: 'Member role'
                  },
                  {
                    key: 'organization_license_tier',
                    render: (record: GetUserResponse['organization_memberships'][0]) =>
                      record.organization_license_tier !== undefined ? (
                        record.organization_license_tier.license_tier?.name
                      ) : (
                        <span style={{ color: 'grey' }}>none assigned</span>
                      ),
                    title: 'License Tier'
                  },
                  {
                    key: 'created_at',
                    render: (record: GetUserResponse['organization_memberships'][0]) => (
                      <DateFormatter value={record.created_at} />
                    ),
                    title: 'Created at'
                  },
                  {
                    key: 'updated_at',
                    render: (record: GetUserResponse['organization_memberships'][0]) => (
                      <DateFormatter value={record.updated_at} />
                    ),
                    title: 'Updated at'
                  },
                  {
                    key: 'actions',
                    render: (record: GetUserResponse['organization_memberships'][0]) => {
                      const removeFromOrganization = async () => {
                        await deleteOrganizationMembership(record.id);
                        window.location.reload();
                      };

                      return (
                        <ActionsColumn
                          customPaths={{
                            remove: (
                              <Popconfirm
                                cancelText="No"
                                disabled={userData.data?.organization_memberships.length === 1}
                                okText="Yes"
                                onConfirm={removeFromOrganization}
                                title={`Are you sure you want to remove ${userData.data?.first_name} ${userData.data?.last_name} from ${record.organization.name}?`}
                              >
                                <PopoverButton
                                  className={
                                    userData.data?.organization_memberships.length === 1
                                      ? 'disabled'
                                      : ''
                                  }
                                >
                                  Remove from organization
                                </PopoverButton>
                              </Popconfirm>
                            )
                          }}
                          record={record}
                        />
                      );
                    },
                    title: 'Actions'
                  }
                ]}
                dataSource={userData.data.organization_memberships}
                pagination={false}
                rowKey="id"
              />
            </Panel>
            <Panel
              key="educations"
              className="no-padding-panel"
              header="Educations"
            >
              <DataTable
                columns={[
                  {
                    key: 'course_name',
                    render: (education: User['user_info']['educations'][0]) =>
                      education.course_id !== null ? (
                        <Link to={coursePath(education.course_id)}>{education.course_name}</Link>
                      ) : (
                        education.course_name
                      ),
                    title: 'Course name'
                  },
                  {
                    key: 'organization',
                    render: (education: User['user_info']['educations'][0]) =>
                      education.organization,
                    title: 'Organization'
                  },
                  {
                    key: 'year',
                    render: (education: User['user_info']['educations'][0]) => education.year,
                    title: 'Year'
                  },
                  {
                    key: 'credits',
                    render: (education: User['user_info']['educations'][0]) => education.credits,
                    title: 'Credits'
                  },
                  {
                    key: 'bipoc_tag',
                    render: (education: User['user_info']['educations'][0]) => (
                      <BooleanIcon value={Boolean(education.bipoc_tag)} />
                    ),
                    title: 'BIPOC'
                  },
                  {
                    key: 'lgbq_tag',
                    render: (education: User['user_info']['educations'][0]) => (
                      <BooleanIcon value={Boolean(education.lgbq_tag)} />
                    ),
                    title: 'LGBQ'
                  },
                  {
                    key: 'tgnc_tag',
                    render: (education: User['user_info']['educations'][0]) => (
                      <BooleanIcon value={Boolean(education.tgnc_tag)} />
                    ),
                    title: 'TGNC'
                  },
                  {
                    key: 'image',
                    render: (education: User['user_info']['educations'][0]) =>
                      education.image.url !== null ? (
                        education.image.url.endsWith('.pdf') ? (
                          <PdfLink url={education.image.url} />
                        ) : (
                          <ApiImage
                            image={education.image}
                            style={{ height: '50px', width: 'auto' }}
                          />
                        )
                      ) : (
                        'No file attached'
                      ),
                    title: 'Image'
                  },
                  {
                    key: 'course_id',
                    render: (education: User['user_info']['educations'][0]) => (
                      <BooleanIcon value={Boolean(education.course_id)} />
                    ),
                    title: 'Complete'
                  },
                  {
                    key: 'created_at',
                    render: (education: User['user_info']['educations'][0]) => (
                      <DateFormatter
                        format="MM/DD/YYYY hh:mm a"
                        value={education.created_at}
                      />
                    ),
                    title: 'Created at'
                  },
                  {
                    key: 'updated_at',
                    render: (education: User['user_info']['educations'][0]) => (
                      <DateFormatter
                        format="MM/DD/YYYY hh:mm a"
                        value={education.updated_at}
                      />
                    ),
                    title: 'Updated at'
                  }
                ]}
                filters={[
                  {
                    key: 'course_name',
                    label: 'Course name'
                  }
                ]}
                getMethod={getEducations}
                getParams={{
                  user_id: userData.data.id
                }}
                rowKey="id"
              />
            </Panel>
            <Panel
              key="experiences"
              header="Experiences"
            >
              {userData.data.user_info.experiences.map((experience, index) => (
                <Card
                  key={index}
                  title={experience.organization}
                >
                  <CardEntryList
                    fieldsMap={[
                      { key: 'organization', title: 'Organization' },
                      { key: 'job_title', title: 'Job title' },
                      {
                        key: 'average_panel_size',
                        title: 'Average panel size'
                      },
                      { key: 'lgbq_percentage', title: 'LGBQ percentage' },
                      { key: 'bipoc_percentage', title: 'BIPOC percentage' },
                      { key: 'tgnc_percentage', title: 'TGNC percentage' },
                      {
                        key: 'start_date',
                        title: 'Start date',
                        type: 'date'
                      },
                      { key: 'end_date', title: 'End date', type: 'date' },
                      {
                        key: 'created_at',
                        render: (
                          value: GetUserResponse['user_info']['experiences'][0]['created_at']
                        ) => (
                          <DateFormatter
                            format="MM/DD/YYYY hh:mm a"
                            value={value}
                          />
                        ),
                        title: 'Created at',
                        type: 'date'
                      },
                      {
                        key: 'updated_at',
                        render: (
                          value: GetUserResponse['user_info']['experiences'][0]['updated_at']
                        ) => (
                          <DateFormatter
                            format="MM/DD/YYYY hh:mm a"
                            value={value}
                          />
                        ),
                        title: 'Updated at',
                        type: 'date'
                      }
                    ]}
                    values={experience}
                  />
                </Card>
              ))}
            </Panel>
            <Panel
              key="non_clinical_experience"
              header="Non-clinical Experiences"
            >
              <NonClinicalExperiencesPanel user={userData.data} />
            </Panel>
            <Panel
              key="further_experience"
              header="Further Experience"
            >
              {userData.data.user_info.further_experience && (
                <CardEntryList
                  fieldsMap={[
                    { key: 'free_text', title: 'Text' },
                    {
                      key: 'inequality_within_healthcare_recognition',
                      title: 'Inequality within healthcare recognition'
                    },
                    {
                      key: 'cultural_differences_recognition',
                      title: 'Cultural differences recognition'
                    },
                    {
                      key: 'cultural_beliefs_about_health_acceptance',
                      title: 'Cultural beliefs about health acceptance'
                    },
                    {
                      key: 'patients_decisions_advocation',
                      title: 'Patients decisions advocation'
                    }
                  ]}
                  values={userData.data.user_info.further_experience}
                />
              )}
            </Panel>

            <Panel
              key="competences"
              header="Cultural Competences"
            >
              {userData.data.user_info.cultural_competences.map((cult_comp, index) => (
                <Card
                  key={index}
                  title={cult_comp.competence_type}
                >
                  <CardEntryList
                    fieldsMap={[
                      { key: 'competence_type', title: 'Competence type' },
                      {
                        key: 'confidence_level',
                        title: 'Confidence level'
                      },
                      {
                        key: 'formal_education_training',
                        title: 'Formal education training'
                      },
                      {
                        key: 'professional_experience',
                        title: 'Professional experience'
                      },
                      {
                        key: 'community_of_interest',
                        title: 'Community of interest'
                      },
                      {
                        key: 'lived_experience',
                        title: 'Lived experience'
                      }
                    ]}
                    values={cult_comp}
                  />
                </Card>
              ))}
            </Panel>

            <Panel
              key="scores"
              header="Scores History"
            >
              <DataTable
                columns={scoreColumns.filter(col => col.key !== 'earned_at')}
                filters={[
                  {
                    key: 'score_type',
                    label: 'Community',
                    options: [
                      { text: '', value: null },
                      { text: 'bipoc', value: 'bipoc' },
                      { text: 'lgbq', value: 'lgbq' },
                      { text: 'tgnc', value: 'tgnc' }
                    ]
                  }
                ]}
                getMethod={getScores}
                getParams={{
                  is_active: false,
                  user_info_id: userData.data.user_info.id
                }}
              />
            </Panel>
            <Panel
              key="emails"
              header="E-mails History"
            >
              <DataTable
                columns={emailsColumns}
                getMethod={getCommunicationInfos}
                getParams={{
                  user_id: userData.data.id
                }}
              />
            </Panel>
            <Panel
              key="ratings"
              header="Course ratings"
            >
              <DataTable
                columns={courseRatingColumns}
                getMethod={getCourseRatings}
                getParams={{
                  user_id: userData.data.id
                }}
              />
            </Panel>
            <Panel
              key="registrations"
              header="Course registrations"
            >
              <DataTable
                columns={courseRegistrationColumns}
                getMethod={getCourseRegistrations}
                getParams={{
                  user_id: userData.data.id
                }}
              />
            </Panel>
            <Panel
              key="npi_data"
              header="NPI Registry Data"
            >
              {npiRegistryData?.data &&
                npiRegistryData.data.map(datum => (
                  <NpiRegistryDatumCard
                    key={datum.id}
                    initialDatum={datum}
                  />
                ))}
            </Panel>
          </Collapse>
        </Card>
      )}
    </div>
  );
};
