import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Form, FormProps, Space, Upload } from 'antd';
import { UploadState } from 'antd/es/upload/interface';
import { importEducationAssessmentsCsvFile } from 'api';
import { CardHeader } from 'components/cards';
import { InputForm } from 'components/forms';
import { DarkModeContext } from 'layouts/app_layout';
import { useContext, useState } from 'react';
import { BodyRow, HeaderRow, Table, TableWrapper } from 'styled_components/tables';
import { normalizeFileFromUpload, toFormData } from 'utils/forms';

const { Item } = Form;

export const ImportEducationAssessmentsPage = () => {
  const isDarkMode = useContext(DarkModeContext);
  const [response, setResponse] = useState<ImportEducationAssessmentResponse | null>(null);

  const onFinish: FormProps<{
    file: UploadState['fileList'];
  }>['onFinish'] = values => {
    const { file } = values;

    const data = toFormData({
      file: file[0].originFileObj
    });

    setResponse(null);
    return importEducationAssessmentsCsvFile(data)
      .then(result => {
        setResponse(result);
      })
      .catch(() => {});
  };

  const onDownloadErrors = () => {
    if (!response || response.errors.length === 0) {
      return;
    }

    const { errors } = response;

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      `line_number,error,user_id,email,education_id,course_name,organization,year,credits,bipoc,lgbq,tgnc\n` +
      errors
        .map(
          e =>
            `${e.line_number},${e.message},${e.line_data.user_id},${e.line_data.email},${e.line_data.education_id},${e.line_data.course_name},${e.line_data.organization},${e.line_data.year},${e.line_data.credits},${e.line_data.bipoc},${e.line_data.lgbq},${e.line_data.tgnc}`
        )
        .join('\n');
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `bulk_education_assessment_errors.csv`);
    document.body.appendChild(link);

    link.click();
  };

  return (
    <Card title={<CardHeader title="Import Education Assessments CSV file" />}>
      <Alert
        closable
        message="Check your file carefully before uploading. This action cannot be undone."
        showIcon
        type="warning"
      />

      <h1>CSV format:</h1>
      <TableWrapper>
        <Table className={isDarkMode ? 'dark-theme' : 'light-theme'}>
          <thead>
            <HeaderRow className={isDarkMode ? 'dark-theme' : 'light-theme'}>
              <th>user_id</th>
              <th>email</th>
              <th>education_id</th>
              <th>course_name</th>
              <th>organization</th>
              <th>year</th>
              <th>credits</th>
              <th>bipoc</th>
              <th>lgbq</th>
              <th>tgnc</th>
            </HeaderRow>
          </thead>
          <tbody>
            <BodyRow className={isDarkMode ? 'dark-theme' : 'light-theme'}>
              <td>UUID</td>
              <td>string</td>
              <td>UUID</td>
              <td>string</td>
              <td>string</td>
              <td>integer</td>
              <td>
                integer <u>or</u> decimal
              </td>
              <td>
                1 (true) <u>or</u>
                <br />0 (false)
              </td>
              <td>
                1 (true) <u>or</u>
                <br />0 (false)
              </td>
              <td>
                1 (true) <u>or</u>
                <br />0 (false)
              </td>
            </BodyRow>
          </tbody>
        </Table>
      </TableWrapper>

      <InputForm
        name="import-csv"
        onFinish={onFinish}
      >
        <Item
          getValueFromEvent={normalizeFileFromUpload}
          label="CSV file"
          name="file"
          required
          rules={[
            {
              validator: async (_, fileList: UploadState['fileList']) => {
                if (fileList.length < 1) {
                  return Promise.reject(new Error('Upload a file'));
                }
                return true;
              }
            }
          ]}
          valuePropName="fileList"
        >
          <Upload
            beforeUpload={() => false}
            maxCount={1}
            showUploadList={{
              showRemoveIcon: false
            }}
          >
            <Button icon={<UploadOutlined />}>Upload CSV file</Button>
          </Upload>
        </Item>
      </InputForm>

      {response && (
        <Space
          direction="vertical"
          style={{ display: 'flex', margin: '50px' }}
        >
          <Alert
            message={<b>{response.status}</b>}
            type="info"
          />

          {response.info.map((info, i) => (
            <Alert
              key={i}
              description={info}
              type="info"
            />
          ))}

          {response.errors.length > 0 && (
            <TableWrapper>
              <Table className={isDarkMode ? 'dark-theme' : 'light-theme'}>
                <thead>
                  <HeaderRow className={isDarkMode ? 'dark-theme' : 'light-theme'}>
                    <th>line_number</th>
                    <th>message</th>
                    <th>user_id</th>
                    <th>email</th>
                    <th>education_id</th>
                  </HeaderRow>
                </thead>
                <tbody>
                  {response.errors.map((error, i) => (
                    <BodyRow
                      key={i}
                      className={isDarkMode ? 'dark-theme' : 'light-theme'}
                    >
                      <td>{error.line_number}</td>
                      <td>{error.message}</td>
                      <td>{error.line_data.user_id}</td>
                      <td>{error.line_data.email}</td>
                      <td>{error.line_data.education_id}</td>
                    </BodyRow>
                  ))}
                </tbody>
              </Table>
              <Button
                onClick={onDownloadErrors}
                style={{ marginTop: '1rem' }}
              >
                <DownloadOutlined /> Download errors
              </Button>
            </TableWrapper>
          )}
        </Space>
      )}
    </Card>
  );
};
