import { Button, Modal, Select, Spin } from 'antd';
import { getOrganizations, GetOrganizationsResponse } from 'api';
import { addNetworkMember } from 'api/network_members';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const AddOrganizationToNetworkModal = ({ isModalOpen, setIsModalOpen }: Props) => {
  const { id } = useParams<{ id: string }>();
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [organizationData, setOrganizationData] = useState<GetOrganizationsResponse[] | undefined>(
    []
  );
  const [selectedOrganization, setSelectedOrganization] = useState<string | undefined>();

  const refetchOrganizationData = async (searchName: string, pageSize = '20') => {
    if (isFetching) return;
    setIsFetching(true);
    const organizationData = await getOrganizations('1', pageSize, {
      name: searchName ? searchName : undefined,
      network: false
    });
    setOrganizationData(organizationData.data);
    setIsFetching(false);
  };

  const changeSelectedOrganization = (value: string) => {
    setSelectedOrganization(value);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);

    addNetworkMember({
      network_organization_member: {
        network_id: id,
        organization_id: selectedOrganization!
      }
    })
      .then(() => {
        toast.success(`Network member successfully added.`, {
          autoClose: 2000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'bottom-center',
          progress: undefined
        });
        setIsSubmitting(false);
        setSelectedOrganization(undefined);
        setIsModalOpen(false);
      })
      .catch(() => {
        toast.error('Could not add organization as member to this network.', {
          autoClose: 2000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'bottom-center',
          progress: undefined
        });
        setIsSubmitting(false);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // Load some data on initial load
    refetchOrganizationData('', '200');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      footer={[
        <Button
          key="back"
          onClick={handleCancel}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          disabled={selectedOrganization === undefined}
          loading={isSubmitting}
          onClick={handleSubmit}
          type="primary"
        >
          Submit
        </Button>
      ]}
      open={isModalOpen}
      title="Add network member"
    >
      <div style={{ display: 'grid' }}>
        <Select
          allowClear
          aria-required="true"
          filterOption={() => true}
          notFoundContent={isFetching ? <Spin size="small" /> : undefined}
          onChange={changeSelectedOrganization}
          onSearch={name => refetchOrganizationData(name, '20')}
          showSearch
          value={selectedOrganization}
        >
          {organizationData?.map(organization => (
            <Select.Option
              key={organization.id}
              value={organization.id}
            >
              {organization.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    </Modal>
  );
};

export default AddOrganizationToNetworkModal;
