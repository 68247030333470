import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Form, Space } from 'antd';
import { InputItem } from 'components/forms';

interface Props {
  form: typeof Form;
}

export const FinancialDisclosures = ({ form }: Props) => {
  const { Item } = form;
  return (
    <Item label="Financial Disclosures">
      <Form.List name="financial_disclosures">
        {(fields, { add, remove }) => (
          <>
            <Item>
              <Button
                block
                className={`financial-disclosure-btn ${
                  fields.length > 0 ? 'unselected' : 'selected'
                }`}
                icon={fields.length > 0 ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                onClick={() => {
                  if (fields.length > 0) {
                    remove(fields[0].name);
                  }
                }}
                type="dashed"
              >
                Violet evaluates all educators for relevant financial relationships in line with
                best practice. There are no disclosures for this activity.
              </Button>
            </Item>
            <Item>
              <Button
                block
                className={`financial-disclosure-btn ${
                  fields.length > 0 ? 'selected' : 'unselected'
                }`}
                icon={fields.length > 0 ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
                onClick={() => {
                  if (fields.length === 0) {
                    add();
                  }
                }}
                type="dashed"
              >
                Violet evaluates all educators for relevant financial relationships in line with
                best practice. The following disclosures were made and mitigated for this activity.
              </Button>
            </Item>

            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                align="baseline"
                size="large"
                style={{ display: 'flex', marginBottom: 0 }}
              >
                <InputItem
                  {...restField}
                  label="List all disclosures"
                  name={[name, 'disclosure_text']}
                  textArea
                />
              </Space>
            ))}
          </>
        )}
      </Form.List>
    </Item>
  );
};
