import { Card, FormProps, Spin } from 'antd';
import { getUser, updateUser, useFetch } from 'api';
import { CardHeader } from 'components/cards';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom-v5-compat';
import { renameAndFillNestedAttributes } from 'utils/helpers';
import * as paths from 'utils/paths';

import { UserForm, UserFormProps } from './user_form';

export const UserEditPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(getUser, [id]);

  const navigate = useNavigate();

  const onFinish: FormProps<{
    auth0_id: string | null;
    create_hubspot_contact?: boolean;
    email: string;
    first_name: string | null;
    hubspot_id: number | string | null;
    is_clinical: boolean;
    last_name: string | null;
    organization_memberships: {
      created_at: string;
      employee_id: string;
      id: string;
      is_active: boolean;
      member_role: 'member' | 'network_manager' | 'superuser';
      organization_id: {
        label: string;
        value: string;
      };
      organization_license_tier: {
        available_licenses: number;
        created_at: string;
        id: string;
        license_tier: {
          id: string;
          name: string;
        };
        organization: {
          id: string;
          name: string;
        };
        total_licenses: number;
        updated_at: string;
      };
      organization_license_tier_id: string;
      status: 'activated' | 'deactivated';
      updated_at: string;
      user_id: string;
    }[];
    role: 'admin' | 'api_client' | 'provider';
    user_info: {
      npi: string | null;
    };
  }>['onFinish'] = async values => {
    const { auth0_id, email, organization_memberships, user_info, ...userValues } = values;

    const userParams = {
      user_info_attributes: {
        id: data!.data!.user_info.id,
        npi: user_info.npi === '' ? null : user_info.npi
      },
      ...renameAndFillNestedAttributes(
        {
          // @ts-expect-error
          organization_memberships: organization_memberships.map(
            ({ organization_id, ...values }) => ({
              organization_id: organization_id.value,
              ...values
            })
          )
        },
        data?.data
      ),
      auth0_id: auth0_id === '' || auth0_id === null ? null : auth0_id,
      email: email === '' ? null : email,
      ...userValues
    };

    return updateUser(id, { user: userParams }).then(() => {
      navigate(paths.usersPath);
    });
  };

  if (!data?.data) return <Spin />;

  const initialData: UserFormProps['initialData'] = {
    data: {
      ...data.data,
      organization_memberships: data.data.organization_memberships.map(
        ({ organization, ...restValues }) => ({
          ...restValues,
          organization_id: {
            label: organization.name,
            value: organization.id
          }
        })
      )
    },
    page: 'edit'
  };

  return (
    <Card title={<CardHeader title={`Edit user ${data.data.first_name} ${data.data.last_name}`} />}>
      <UserForm
        initialData={initialData}
        name="user-edit"
        onFinish={onFinish}
      />
    </Card>
  );
};
