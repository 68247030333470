import { Button, Col, Popconfirm, Row, Space } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom-v5-compat';

import { StyledTitle } from './styled_title';

interface CardHeaderProps {
  children?: React.ReactNode;
  customPaths?: { name: string; path: string }[];
  editPath?: string;
  onDeleteClick?: () => void;
  title: JSX.Element | string;
}
export const CardHeader: React.FC<CardHeaderProps> = ({
  children,
  customPaths,
  editPath,
  onDeleteClick,
  title
}) => (
  <Row
    align="middle"
    justify="space-around"
  >
    <Col flex="1 1 auto">
      <StyledTitle title={title} />
    </Col>
    <Col flex="0 1 auto">
      <Space size="middle">
        {editPath !== undefined && (
          <Button
            size="large"
            type="primary"
          >
            <Link
              to={{
                pathname: editPath
              }}
            >
              Edit
            </Link>
          </Button>
        )}

        {customPaths?.map(({ name, path }, index) => (
          <Button
            key={index}
            size="large"
            type="primary"
          >
            <Link
              to={{
                pathname: path
              }}
            >
              {name}
            </Link>
          </Button>
        ))}

        {children}

        {onDeleteClick && (
          <Popconfirm
            cancelText="No"
            okText="Yes"
            onConfirm={onDeleteClick}
            title="Are you sure you want to delete this record?"
          >
            <Button
              danger
              size="large"
              type="primary"
            >
              Delete
            </Button>
          </Popconfirm>
        )}
      </Space>
    </Col>
  </Row>
);
