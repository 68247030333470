/* eslint-disable react/no-multi-comp */
import { Form, FormItemProps, InputNumber } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { FormInstance } from 'antd/lib/form/Form';
import { GetUserResponse } from 'api';
import { ApiImage } from 'components/api_image';
import { BooleanIcon } from 'components/boolean_icon';
import { DateFormatter } from 'components/date_formatter';
import { PdfLink } from 'components/pdf_link';
import moment from 'moment';
import React from 'react';

export interface Values {
  bipoc: {
    communityOfInterestScore: number;
    educationScore: number;
    livedExperienceScore: number;
    professionalExperienceScore: number;
    totalScore: number;
  };
  educations?: Record<
    string,
    {
      assessed: boolean;
      bipoc_tag: boolean;
      course_id: string | null;
      credits: string;
      lgbq_tag: boolean;
      tgnc_tag: boolean;
    }
  >;
  lgbq: {
    communityOfInterestScore: number;
    educationScore: number;
    livedExperienceScore: number;
    professionalExperienceScore: number;
    totalScore: number;
  };
  send_email: boolean;
  tgnc: {
    communityOfInterestScore: number;
    educationScore: number;
    livedExperienceScore: number;
    professionalExperienceScore: number;
    totalScore: number;
  };
}

const { Item } = Form;

export const competencesColumns: ColumnsType<
  | { bipoc: boolean; lgbq: boolean; tgnc: boolean; type: 'Community of interest' }
  | { bipoc: number; lgbq: number; tgnc: number; type: 'Confidence' }
> = [
  {
    dataIndex: 'type',
    key: 'type',
    title: '',
    width: '20%'
  },
  {
    dataIndex: 'bipoc',
    key: 'bipoc',
    render: (_value, record) =>
      record.type === 'Community of interest' ? <BooleanIcon value={record.bipoc} /> : record.bipoc,
    title: 'BIPOC'
  },
  {
    dataIndex: 'lgbq',
    key: 'lgbq',
    render: (_value, record) =>
      record.type === 'Community of interest' ? <BooleanIcon value={record.lgbq} /> : record.lgbq,
    title: 'LGBQ'
  },
  {
    dataIndex: 'tgnc',
    key: 'tgnc',
    render: (_value, record) =>
      record.type === 'Community of interest' ? <BooleanIcon value={record.tgnc} /> : record.tgnc,
    title: 'TGNC'
  }
];

export const educationsColumns: (
  form: FormInstance<Values>
) => ColumnsType<GetUserResponse['user_info']['educations'][0]> = form => [
  {
    dataIndex: 'course_name',
    key: 'course_name',
    title: 'Course'
  },
  {
    dataIndex: 'organization',
    key: 'organization',
    title: 'Organization'
  },
  {
    dataIndex: 'year',
    key: 'year',
    title: 'Year'
  },
  {
    dataIndex: 'credits',
    key: 'credits',
    title: 'Credits'
  },
  {
    dataIndex: 'bipoc_tag',
    key: 'bipoc_tag',
    render: (_value, record) => (
      <Item
        name={['educations', record.id, 'bipoc_tag']}
        style={{ margin: 'auto auto', padding: 0 }}
        valuePropName="checked"
      >
        <Checkbox
          disabled={record.course_id !== ''}
          onChange={() => setEducationScore(form)}
        />
      </Item>
    ),
    title: 'BIPOC'
  },
  {
    dataIndex: 'lgbq_tag',
    key: 'lgbq_tag',
    render: (_value, record) => (
      <Item
        name={['educations', record.id, 'lgbq_tag']}
        style={{ margin: 'auto auto', padding: 0 }}
        valuePropName="checked"
      >
        <Checkbox
          disabled={record.course_id !== ''}
          onChange={() => setEducationScore(form)}
          style={{ margin: 'auto auto' }}
        />
      </Item>
    ),
    title: 'LGBQ'
  },
  {
    dataIndex: 'tgnc_tag',
    key: 'tgnc_tag',
    render: (_value, record) => (
      <Item
        name={['educations', record.id, 'tgnc_tag']}
        style={{ margin: 'auto auto', padding: 0 }}
        valuePropName="checked"
      >
        <Checkbox
          disabled={record.course_id !== ''}
          onChange={() => setEducationScore(form)}
        />
      </Item>
    ),
    title: 'TGNC'
  },
  {
    dataIndex: 'assessed',
    key: 'assessed',
    render: (_value, record) => (
      <Item
        name={['educations', record.id, 'assessed']}
        style={{ margin: 'auto auto', padding: 0 }}
        valuePropName="checked"
      >
        <Checkbox
          disabled={record.course_id !== ''}
          onChange={() => setEducationScore(form)}
        />
      </Item>
    ),
    title: 'Assessed'
  },
  {
    dataIndex: 'image',
    key: 'image',
    render: (value: GetUserResponse['user_info']['educations'][0]['image']) => {
      if (value.url === null) return 'No file attached';
      return Boolean(value.url.endsWith('.pdf')) ? (
        <PdfLink url={value.url} />
      ) : (
        <ApiImage image={value} />
      );
    },
    title: 'File',
    width: '130px'
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
    render: (value: GetUserResponse['user_info']['educations'][0]['created_at'], record) => (
      <>
        <DateFormatter
          format="MM/DD/YYYY hh:mm a"
          value={value}
        />
        {/* Invisible fields to read them from form state */}
        <Item
          name={['educations', record.id, 'credits']}
          noStyle
        >
          <div />
        </Item>
        <Item
          name={['educations', record.id, 'course_id']}
          noStyle
        >
          <div />
        </Item>
      </>
    ),
    title: 'Created at',
    type: 'date'
  },
  {
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: (date: GetUserResponse['user_info']['educations'][0]['updated_at']) => (
      <DateFormatter
        format="MM/DD/YYYY hh:mm a"
        value={date}
      />
    ),
    title: 'Updated at',
    type: 'date'
  }
];

export const evalEducationScore = (
  educations: Pick<
    GetUserResponse['user_info']['educations'][0],
    'assessed' | 'bipoc_tag' | 'credits' | 'lgbq_tag' | 'tgnc_tag'
  >[]
) => {
  const sum = {
    bipoc: 0,
    lgbq: 0,
    tgnc: 0
  };

  educations
    .filter(education => education.assessed)
    .forEach(education => {
      const credits = parseFloat(education.credits);
      if (credits >= 0.25 && credits <= 2) {
        if (education.bipoc_tag) sum.bipoc += credits;
        if (education.lgbq_tag) sum.lgbq += credits;
        if (education.tgnc_tag) sum.tgnc += credits;
      } else {
        let tagsCount = 0;
        if (education.bipoc_tag) tagsCount += 1;
        if (education.lgbq_tag) tagsCount += 1;
        if (education.tgnc_tag) tagsCount += 1;

        const communityValue = credits / tagsCount;
        if (education.bipoc_tag) sum.bipoc += communityValue;
        if (education.lgbq_tag) sum.lgbq += communityValue;
        if (education.tgnc_tag) sum.tgnc += communityValue;
      }
    });

  sum.bipoc = Math.floor(Math.min(sum.bipoc, 40) * 10) / 10;
  sum.lgbq = Math.floor(Math.min(sum.lgbq, 40) * 10) / 10;
  sum.tgnc = Math.floor(Math.min(sum.tgnc, 40) * 10) / 10;

  return sum;
};

export const livedExpColumns = [
  {
    dataIndex: 'type',
    key: 'type',
    title: '',
    width: '20%'
  },
  {
    dataIndex: 'bipoc',
    key: 'bipoc',
    render: (value: boolean) => <BooleanIcon value={value} />,
    title: 'BIPOC'
  },
  {
    dataIndex: 'lgbq',
    key: 'lgbq',
    render: (value: boolean) => <BooleanIcon value={value} />,
    title: 'LGBQ'
  },
  {
    dataIndex: 'tgnc',
    key: 'tgnc',
    render: (value: boolean) => <BooleanIcon value={value} />,
    title: 'TGNC'
  }
];

const calcAndSetTotalScore = (score_type: ScoreType, form: FormInstance<Values>) => {
  const values = form.getFieldsValue();
  const sum = Object.values(values[score_type]).reduce((sum, val) => sum + (val ? val : 0), 0);
  form.setFields([{ name: [score_type, 'totalScore'], value: sum.toString() }]);
};

const setEducationScore = (form: FormInstance<Values>) => {
  const values = form.getFieldsValue();
  const { educations: educationFields } = values;

  const parsedEducations = Object.values(educationFields!).map(educationField => {
    const field = educationField;

    return {
      assessed: field.assessed,
      bipoc_tag: field.bipoc_tag,
      credits: field.credits,
      lgbq_tag: field.lgbq_tag,
      tgnc_tag: field.tgnc_tag
    };
  });

  const sum = evalEducationScore(parsedEducations);
  Array.of('bipoc', 'lgbq', 'tgnc').forEach((score_type: string) => {
    const type = score_type as ScoreType;
    form.setFields([
      {
        name: [type, 'educationScore'],
        value: sum[type]
      }
    ]);
    calcAndSetTotalScore(type, form);
  });
};

const ScoreItem = ({
  form,
  name,
  ...props
}: FormItemProps<Values> & { form: FormInstance<Values> }) => (
  <Item
    name={name}
    {...props}
  >
    <InputNumber
      min={0}
      // max={10}
      onChange={() =>
        calcAndSetTotalScore(name![0 as keyof typeof name] as keyof typeof name, form)
      }
    />
  </Item>
);

interface ScoreItemsProps {
  form: FormInstance<Values>;
  itemStyle?: React.CSSProperties;
  scoreName: string;
}

export const profExpColumns: ColumnsType<GetUserResponse['user_info']['experiences'][0]> = [
  {
    dataIndex: 'job_title',
    key: 'job_title',
    title: 'Job'
  },
  {
    dataIndex: 'organization',
    key: 'organization',
    title: 'Organization'
  },
  {
    key: 'duration',
    render: (_value, record) => {
      const endDate = record.end_date ? moment(record.end_date) : moment();
      const months = endDate.diff(moment(record.start_date), 'months');
      const fullYears = Math.floor(months / 12);
      const leftMonths = months % 12;
      return `${fullYears} years ${leftMonths} months`;
    },
    title: 'Duration'
  },
  {
    dataIndex: 'average_panel_size',
    key: 'average_panel_size',
    title: 'Average panel size'
  },
  {
    dataIndex: 'bipoc_percentage',
    key: 'bipoc_percentage',
    title: 'BIPOC %'
  },
  {
    dataIndex: 'lgbq_percentage',
    key: 'lgbq_percentage',
    title: 'LGBQ %'
  },
  {
    dataIndex: 'tgnc_percentage',
    key: 'tgnc_percentage',
    title: 'TGNC %'
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
    render: (value: GetUserResponse['user_info']['experiences'][0]['created_at']) => (
      <DateFormatter
        format="MM/DD/YYYY hh:mm a"
        value={value}
      />
    ),
    title: 'Created at'
  },
  {
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: (value: GetUserResponse['user_info']['experiences'][0]['updated_at']) => (
      <DateFormatter
        format="MM/DD/YYYY hh:mm a"
        value={value}
      />
    ),
    title: 'Updated at'
  }
];

export const ScoreItems = ({ form, itemStyle, scoreName }: ScoreItemsProps) => (
  <>
    {['bipoc', 'lgbq', 'tgnc'].map(scoreType => (
      <ScoreItem
        key={`${scoreName}-${scoreType}`}
        form={form}
        label={`${scoreType.toUpperCase()} score`}
        // @ts-expect-error
        name={[scoreType, scoreName]}
        style={itemStyle}
      />
    ))}
  </>
);
