import { FormProps, Select } from 'antd';
import { GetLicenseTierResponse } from 'api';
import { InputForm, InputItem, SelectItem } from 'components/forms';
import { humanReadablePermission } from 'utils/licenses';

interface LicenseTierFormProps {
  initialData?: GetLicenseTierResponse;
  name: string;
  onFinish: NonNullable<FormProps['onFinish']>;
}

export const LicenseTierForm = ({ initialData, name, onFinish }: LicenseTierFormProps) => (
  <InputForm
    initialValues={initialData}
    name={name}
    onFinish={onFinish}
  >
    <InputItem
      label="Name"
      name="name"
      required
    />
    <SelectItem
      label="Organization permissions"
      name="organization_permissions"
      selectProps={{ mode: 'multiple' }}
    >
      <Select.Option value="users.export">{humanReadablePermission('users.export')}</Select.Option>
      <Select.Option value="api.read">{humanReadablePermission('api.read')}</Select.Option>
    </SelectItem>

    <SelectItem
      label="User permissions"
      name="user_permissions"
      selectProps={{ mode: 'multiple' }}
    >
      <Select.Option value="evaluations.create">
        {humanReadablePermission('evaluations.create')}
      </Select.Option>
    </SelectItem>
  </InputForm>
);
