import { FormInstance } from 'antd/es/form/Form';
import { createContext, useContext } from 'react';

export const InputFormContext = createContext<FormInstance | null>(null);

export const useInputFormContext = <T,>() => {
  const form = useContext(InputFormContext);

  if (!form) {
    throw new Error('Missing FormContextProvider in its parent.');
  }
  return form as FormInstance<T>;
};
