import { instance } from 'api';
import type { AxiosResponse } from 'axios';
import type { RequireAtLeastOne } from 'type-fest';

interface NpiRegistryData {
  api_error_messages: string[];
  city: string | null;
  country_code: string | null;
  created_at: string;
  credential_text: string | null;
  deactivation_date: string | null;
  deactivation_reason_code: string | null;
  enumeration_date: string | null;
  first_name: string | null;
  gender_code: string | null;
  id: string;
  is_active: boolean;
  is_matched: boolean;
  is_successful: boolean;
  last_name: string | null;
  last_updated: string | null;
  mismatch_on_fields: string[];
  npi: string;
  other_credential_text: string | null;
  state: string | null;
  taxonomies: {
    code: string;
    description: string;
    id: string;
    is_primary: boolean;
    license_number: string;
    state_code: string;
  }[];
  updated_at: string;
  user: Pick<
    User,
    | 'city'
    | 'completed_onboarding'
    | 'email'
    | 'first_name'
    | 'id'
    | 'last_name'
    | 'state'
    | 'status'
  > & {
    user_info: Pick<
      User['user_info'],
      | 'acknowledged_data_validity'
      | 'data_source'
      | 'gender_identity'
      | 'id'
      | 'languages'
      | 'npi'
      | 'phone_number'
      | 'privacy_policy'
      | 'pronouns'
      | 'race_ethnicity'
      | 'sexual_orientation'
      | 'sms_consent'
    >;
  };
}

export interface GetNpiRegistryDataResponse extends NpiRegistryData {}

interface UpdateNpiRegistryDatumParams {
  npi_registry_datum: RequireAtLeastOne<{
    is_matched: boolean;
    is_successful: boolean;
  }>;
}

interface UpdateNpiRegistryDatumResponse extends NpiRegistryData {}

export const getNpiRegistryData = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetNpiRegistryDataResponse[], IndexPageMeta>>('/admin/npi_registry_data', {
      params: {
        ...getParams,
        page,
        per_page: pageSize
      }
    })
    .then(res => res.data);

export const updateNpiRegistryDatum = (id: string, params: UpdateNpiRegistryDatumParams) =>
  instance
    .patch<
      ApiResponse<UpdateNpiRegistryDatumResponse>,
      AxiosResponse<ApiResponse<UpdateNpiRegistryDatumResponse>>,
      UpdateNpiRegistryDatumParams
    >(`/admin/npi_registry_data/${id}`, params)
    .then(res => res.data);
