/* eslint-disable react/no-multi-comp */
import { Card, Form, FormProps, InputNumber, Space, Spin, Switch, Table } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import {
  createScores,
  CreateScoresParams,
  getScores,
  getUser,
  GetUserResponse,
  updateEducationBatch,
  UpdateEducationBatchParams,
  useFetch
} from 'api';
import { CardHeader } from 'components/cards';
import { CardEntryList } from 'components/cards/card_entry_list';
import { InputForm } from 'components/forms';
import { DataTable } from 'components/tables';
import moment from 'moment';
import React from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom-v5-compat';
import { userPath } from 'utils/paths';

import {
  competencesColumns,
  educationsColumns,
  evalEducationScore,
  livedExpColumns,
  profExpColumns,
  ScoreItems,
  Values
} from './scoring_components';
import { scoreColumns } from './user_components';

const { Item } = Form;

const HorizontalSpace: React.FC<{
  children: JSX.Element | JSX.Element[] | string;
}> = ({ children }) => <div style={{ display: 'flex', flexDirection: 'row' }}>{children}</div>;

const transposeLivedExpTable = (data: GetUserResponse['user_info']['cultural_competences']) => {
  const defaultResult = { bipoc: false, lgbq: false, tgnc: false, type: '' };
  const result = [
    { ...defaultResult, type: 'Self' },
    { ...defaultResult, type: 'Family' },
    { ...defaultResult, type: 'Other' }
  ];
  data.forEach(row => {
    const foundSelf = (row.lived_experience ?? []).find(val => val === 'self') !== undefined;
    const foundFamily = (row.lived_experience ?? []).find(val => val === 'family') !== undefined;
    const foundOther = (row.lived_experience ?? []).find(val => val === 'other') !== undefined;

    if (foundSelf) result[0][row.competence_type] = true;
    if (foundFamily) result[1][row.competence_type] = true;
    if (foundOther) result[2][row.competence_type] = true;
  });
  return result;
};

const transposeCultCompTable = (data: GetUserResponse['user_info']['cultural_competences']) => {
  const result = [
    { bipoc: false, lgbq: false, tgnc: false, type: 'Community of interest' as const },
    { bipoc: 0, lgbq: 0, tgnc: 0, type: 'Confidence' as const }
  ];

  data.forEach(row => {
    const coi = row.community_of_interest;

    if (coi) result[0][row.competence_type] = true;
    result[1][row.competence_type] = row.confidence_level;
  });

  return result;
};

const validLivedExpValues = {
  bipoc: [
    'Native American or Alaska Native',
    'Asian',
    'Black or African American',
    'Hispanic or Latinx',
    'Native Hawaiian or Pacific Islander',
    'Middle Eastern or North African'
  ],
  lgbq: ['Gay', 'Lesbian', 'Bisexual', 'Pansexual', 'Queer', 'Asexual'],
  tgnc: ['Transgender woman', 'Transgender man', 'Non-binary', 'Genderqueer', 'Agender', 'Intersex']
};

const evalLivedExpSelf = (
  competence: GetUserResponse['user_info']['cultural_competences'][0],
  userInfo: GetUserResponse['user_info']
) => {
  if ((competence.lived_experience ?? []).some(val => val === 'self')) return 10;
  const type = competence.competence_type;
  const userInfoKey = {
    bipoc: 'race_ethnicity',
    lgbq: 'sexual_orientation',
    tgnc: 'gender_identity'
  }[type] as 'gender_identity' | 'race_ethnicity' | 'sexual_orientation';
  const values = userInfo[userInfoKey];
  if (values.some(val => validLivedExpValues[type].includes(val))) return 10;

  return 0;
};

const evalScorings = (data: GetUserResponse) => {
  const mixin = () => ({
    bipoc: 0,
    lgbq: 0,
    tgnc: 0
  });

  const { user_info } = data;
  const { cultural_competences, educations, experiences } = user_info;

  const livedExperienceScore = mixin();
  const communityOfInterestScore = mixin();
  const professionalExperienceScore = mixin();
  const educationScore = evalEducationScore(educations);
  const totalScore = mixin();

  cultural_competences.forEach(competence => {
    const type = competence.competence_type;
    const livedExp = competence.lived_experience;
    let expScore = 0;

    expScore += evalLivedExpSelf(competence, user_info);
    if ((livedExp ?? []).some(val => val === 'family')) expScore += 8;
    if ((livedExp ?? []).some(val => val === 'other')) expScore += 5;
    expScore = Math.round(Math.min(expScore, 10));
    livedExperienceScore[type] = expScore;

    let coiScore = competence.confidence_level / 2;
    if (competence.community_of_interest) coiScore += 5;
    coiScore = Math.round(Math.min(coiScore, 10));
    communityOfInterestScore[type] = coiScore;
  });

  experiences.forEach(experience => {
    const endDate = experience.end_date ? moment(experience.end_date) : moment();
    const years = endDate.diff(moment(experience.start_date), 'days') / 365.25;

    professionalExperienceScore['bipoc'] += Math.min(
      Math.floor((experience.bipoc_percentage * years) / 1.4) / 10,
      40
    );
    professionalExperienceScore['lgbq'] += Math.min(
      Math.floor((experience.lgbq_percentage * years) / 1.05) / 10,
      40
    );
    professionalExperienceScore['tgnc'] += Math.min(
      Math.floor((experience.tgnc_percentage * years) / 1.006) / 10,
      40
    );
  });

  Array.of('bipoc', 'lgbq', 'tgnc').forEach((type: string) => {
    const key = type as 'bipoc' | 'lgbq' | 'tgnc';
    professionalExperienceScore[key] = Math.round(
      Math.min(professionalExperienceScore[key] > 0 ? professionalExperienceScore[key] + 2 : 0, 40)
    );

    totalScore[key] =
      livedExperienceScore[key] +
      communityOfInterestScore[key] +
      professionalExperienceScore[key] +
      educationScore[key];
  });

  return {
    communityOfInterestScore,
    educationScore,
    livedExperienceScore,
    professionalExperienceScore,
    totalScore
  };
};

export const UserScoringPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(getUser, [id]);
  const [form] = useForm<Values>();
  const navigate = useNavigate();

  const onFinish: FormProps<Values>['onFinish'] = async values => {
    if (!data?.data) return;

    const scoringValues: CreateScoresParams = {
      scores: [
        {
          community_of_interest_score: values.bipoc.communityOfInterestScore,
          education_score: values.bipoc.educationScore,
          lived_experience_score: values.bipoc.livedExperienceScore,
          professional_experience_score: values.bipoc.professionalExperienceScore,
          score_type: 'bipoc',
          total_score: values.bipoc.totalScore
        },
        {
          community_of_interest_score: values.lgbq.communityOfInterestScore,
          education_score: values.lgbq.educationScore,
          lived_experience_score: values.lgbq.livedExperienceScore,
          professional_experience_score: values.lgbq.professionalExperienceScore,
          score_type: 'lgbq',
          total_score: values.lgbq.totalScore
        },
        {
          community_of_interest_score: values.tgnc.communityOfInterestScore,
          education_score: values.tgnc.educationScore,
          lived_experience_score: values.tgnc.livedExperienceScore,
          professional_experience_score: values.tgnc.professionalExperienceScore,
          score_type: 'tgnc',
          total_score: values.tgnc.totalScore
        }
      ],
      send_email: values.send_email,
      user_info_id: data.data.user_info.id
    };

    const educationBody = Object.entries(values.educations ?? {}).reduce<
      UpdateEducationBatchParams['educations']
    >((body, [id, { assessed, bipoc_tag, course_id, lgbq_tag, tgnc_tag }]) => {
      if (course_id === null) body.push({ assessed, bipoc_tag, id, lgbq_tag, tgnc_tag });
      return body;
    }, []);

    if (educationBody.length > 0) await updateEducationBatch({ educations: educationBody });

    return createScores(data.data.user_info.id, scoringValues).then(() =>
      navigate(userPath(data.data!.id))
    );
  };

  if (!data?.data) return <Spin />;

  const {
    communityOfInterestScore,
    educationScore,
    livedExperienceScore,
    professionalExperienceScore,
    totalScore
  } = evalScorings(data.data);

  const mixin = (type: 'bipoc' | 'lgbq' | 'tgnc') => ({
    communityOfInterestScore: communityOfInterestScore[type],
    educationScore: educationScore[type],
    livedExperienceScore: livedExperienceScore[type],
    professionalExperienceScore: professionalExperienceScore[type],
    totalScore: totalScore[type]
  });

  const initialValues = {
    bipoc: { ...mixin('bipoc') },
    educations: data.data.user_info.educations.reduce<
      Record<
        string,
        Pick<
          GetUserResponse['user_info']['educations'][0],
          'assessed' | 'bipoc_tag' | 'course_id' | 'credits' | 'lgbq_tag' | 'tgnc_tag'
        >
      >
    >((result, education) => {
      result[education.id] = {
        assessed: education.assessed,
        bipoc_tag: education.bipoc_tag,
        course_id: education.course_id,
        credits: education.credits,
        lgbq_tag: education.lgbq_tag,
        tgnc_tag: education.tgnc_tag
      };
      return result;
    }, {}),
    lgbq: { ...mixin('lgbq') },
    tgnc: { ...mixin('tgnc') }
  };

  return (
    <div className="site-card-border-less-wrapper">
      <Card
        title={<CardHeader title={`Score user ${data.data.first_name} ${data.data.last_name}`} />}
      >
        <Card
          bordered={false}
          title="Current scores"
        >
          <DataTable
            columns={scoreColumns}
            getMethod={getScores}
            getParams={{
              is_active: true,
              user_info_id: data.data.user_info.id
            }}
            pagination={false}
            style={{ marginBottom: 20 }}
          />
        </Card>

        <InputForm
          form={form}
          initialValues={initialValues}
          layout="vertical"
          name="user-scoring"
          onFinish={onFinish}
        >
          <Card
            key="livedExp"
            bordered={false}
            title="Lived experience"
          >
            <Table
              bordered
              columns={livedExpColumns}
              dataSource={transposeLivedExpTable(data.data.user_info.cultural_competences)}
              pagination={false}
              rowKey="type"
              style={{ display: 'inline-block', width: '50%' }}
            />
            <CardEntryList
              fieldsMap={[
                { key: 'race_ethnicity', title: 'Race ethnicity' },
                { key: 'sexual_orientation', title: 'Sexual orientation' },
                { key: 'gender_identity', title: 'Gender identity' }
              ]}
              values={data.data.user_info}
            />

            <Item
              style={{ marginTop: '10px' }}
              wrapperCol={{
                offset: 1,
                span: 15
              }}
            >
              <HorizontalSpace>
                <ScoreItems
                  form={form}
                  itemStyle={{ marginRight: 10 }}
                  scoreName="livedExperienceScore"
                />
              </HorizontalSpace>
            </Item>
          </Card>

          <Card
            key="com_int"
            bordered={false}
            title="Community of Interest"
          >
            <Table
              bordered
              columns={competencesColumns}
              dataSource={transposeCultCompTable(data.data.user_info.cultural_competences)}
              pagination={false}
              rowKey="type"
              style={{ display: 'inline-block', width: '50%' }}
            />
            <Item
              style={{ marginTop: '10px' }}
              wrapperCol={{
                offset: 1,
                span: 15
              }}
            >
              <HorizontalSpace>
                <ScoreItems
                  form={form}
                  itemStyle={{ marginRight: 10 }}
                  scoreName="communityOfInterestScore"
                />
              </HorizontalSpace>
            </Item>
          </Card>

          <Card
            key="prof_exp"
            bordered={false}
            title="Professional experience"
          >
            <Table
              bordered
              columns={profExpColumns}
              dataSource={data.data.user_info.experiences}
              pagination={false}
              rowKey="id"
            />
            <Item
              style={{ marginTop: '10px' }}
              wrapperCol={{
                offset: 1,
                span: 15
              }}
            >
              <HorizontalSpace>
                <ScoreItems
                  form={form}
                  itemStyle={{ marginRight: 10 }}
                  scoreName="professionalExperienceScore"
                />
              </HorizontalSpace>
            </Item>
          </Card>

          <Card
            bordered={false}
            title="Education"
          >
            <Table
              bordered
              columns={educationsColumns(form)}
              dataSource={data.data.user_info.educations.sort((a, b) =>
                a.created_at < b.created_at ? -1 : 1
              )}
              pagination={false}
              rowKey="id"
            />

            <Item
              style={{ marginTop: '10px' }}
              wrapperCol={{
                offset: 1,
                span: 15
              }}
            >
              <HorizontalSpace>
                <ScoreItems
                  form={form}
                  itemStyle={{ marginRight: 10 }}
                  scoreName="educationScore"
                />
              </HorizontalSpace>
            </Item>
          </Card>

          <Card
            key="tot_score"
            bordered={false}
            title="Total score"
          >
            <Item
              style={{ marginTop: '10px' }}
              wrapperCol={{
                offset: 1,
                span: 15
              }}
            >
              <Space style={{ display: 'flex', flexDirection: 'row' }}>
                <Item
                  label="BIPOC score"
                  name={['bipoc', 'totalScore']}
                >
                  <InputNumber />
                </Item>
                <Item
                  label="LGBQ score"
                  name={['lgbq', 'totalScore']}
                >
                  <InputNumber />
                </Item>
                <Item
                  label="TGNC score"
                  name={['tgnc', 'totalScore']}
                >
                  <InputNumber />
                </Item>
              </Space>
            </Item>

            <Item
              initialValue
              label="Send email"
              name="send_email"
              valuePropName="checked"
            >
              <Switch defaultChecked />
            </Item>
          </Card>
        </InputForm>
      </Card>
    </div>
  );
};
