/* eslint-disable react/no-multi-comp */
import { UploadOutlined } from '@ant-design/icons';
import { Button, Card, Form, FormProps, Upload } from 'antd';
import { UploadState } from 'antd/es/upload/interface';
import { importCoursesCsvFile } from 'api';
import { CardHeader } from 'components/cards';
import { InputForm } from 'components/forms';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { normalizeFileFromUpload, toFormData } from 'utils/forms';
import * as paths from 'utils/paths';

const { Item } = Form;

const ErrorMessage = ({ error }: { error: ImportError }) => (
  <>
    <div>
      On line
      {error.line_number} of file:
    </div>
    <div>{error.message}</div>
  </>
);

export const CoursesImportCsvPage = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState<ImportError[]>([]);

  const onFinish: FormProps<{ file: UploadState['fileList'] }>['onFinish'] = values => {
    const file = values.file[0].originFileObj;

    const data = toFormData({ file });

    return importCoursesCsvFile(data)
      .then(result => {
        if (result.data && result.data.length > 0) setErrors(result.data);
        else navigate(paths.coursesPath);
      })
      .catch(() => {});
  };

  return (
    <Card title={<CardHeader title="Import CSV file" />}>
      <h1>Column headers should be (in no particular order):</h1>
      <p>
        Published to WebApp,Full Title,Short Title,Author,Publishing Organization,Hosting
        Platform,Description,Link,Content Medium,Format Category,Group
        Training,Specialties,Professional Group(s),BIPOC Communities,LGBQ Communities,TGNC
        Communities,Other Communities Tags,Topic Area Tags,Age Group,Year,CC Pillar(s),CE
        Provider,Accrediting Association,Time (Hours),Credits,CE Credit Type,Price,Learning
        Objectives,Scormcloud ID
      </p>

      <InputForm
        name="import-csv"
        onFinish={onFinish}
      >
        <Item
          getValueFromEvent={normalizeFileFromUpload}
          label="CSV file"
          name="file"
          required
          rules={[
            {
              validator: async (_, fileList: UploadState['fileList']) => {
                if (fileList.length < 1) {
                  return Promise.reject(new Error('Upload a file'));
                }
                return true;
              }
            }
          ]}
          valuePropName="fileList"
        >
          <Upload
            beforeUpload={() => false}
            maxCount={1}
            showUploadList={{
              showRemoveIcon: false
            }}
          >
            <Button icon={<UploadOutlined />}>Upload CSV file</Button>
          </Upload>
        </Item>
      </InputForm>
      <div style={{ color: 'red', marginLeft: '50px' }}>
        {errors.length > 0 &&
          errors.map((error, index) => (
            <ErrorMessage
              key={index}
              error={error}
            />
          ))}
      </div>
    </Card>
  );
};
