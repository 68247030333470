import type { AxiosResponse } from 'axios';
import type { RequireAtLeastOne } from 'type-fest';

import { instance } from '.';

export interface GetCourseCollectionResponse {
  authors: string;
  communities: ScoreType[];
  courses: {
    author: string;
    bipoc_communities: boolean;
    ce_credits: string;
    created_at: string;
    full_title: string;
    hours: string;
    id: string;
    lgbq_communities: boolean;
    link: string;
    other_communities: boolean;
    published: boolean;
    scormcloud_id: string | null;
    short_title: string;
    tgnc_communities: boolean;
    updated_at: string;
    year: number;
  }[];
  created_at: string;
  credits: string;
  description: string;
  external_accreditation_references: {
    id: string;
    vendor_name: VendorNames;
    vendor_resource_id: string;
  }[];
  financial_disclosures: {
    disclosure_text: string;
    id: string;
  }[];
  hours: string;
  id: string;
  learning_objectives: string[];
  level: 'advanced' | 'beginner' | 'foundation' | 'intermediate';
  name: string;
  organization: {
    id: string;
    name: string;
  } | null;
  organization_id: string;
  published: 'not_published' | 'pinned' | 'published';
  specialties: string[];
  updated_at: string;
}

interface CreateCourseCollectionParams {
  course_collection: {
    authors: string;
    communities: ScoreType[];
    course_ids: string[];
    credits: number;
    description: string;
    external_accreditation_references_attributes: {
      id?: string;
      vendor_name: VendorNames;
      vendor_resource_id: string;
    }[];
    financial_disclosures_attributes: {
      disclosure_text: string;
      id?: string;
    }[];
    level: 'advanced' | 'beginner' | 'foundation' | 'intermediate';
    name: string;
    organization_id: string | null;
    published: 'not_published' | 'pinned' | 'published';
    specialties: string[];
  };
}

interface CreateCourseCollectionResponse {
  authors: string;
  communities: ScoreType[];
  created_at: string;
  credits: string;
  description: string;
  external_accreditation_references: {
    vendor_name: VendorNames;
    vendor_resource_id: string;
  }[];
  financial_disclosures_attributes?: {
    disclosure_text: string;
  }[];
  hours: string;
  id: string;
  learning_objectives: [];
  level: 'advanced' | 'beginner' | 'foundation' | 'intermediate';
  name: string;
  organization: {
    id: string;
    name: string;
  } | null;
  organization_id: string;
  published: 'not_published' | 'pinned' | 'published';
  specialties: string[];
  updated_at: string;
}

export interface GetCourseCollectionsResponse {
  authors: string;
  communities: ScoreType[];
  created_at: string;
  credits: string;
  description: string;
  external_accreditation_references: {
    id: string;
    vendor_name: VendorNames;
    vendor_resource_id: string;
  }[];
  hours: string;
  id: string;
  learning_objectives: string[];
  level: 'advanced' | 'beginner' | 'foundation' | 'intermediate';
  name: string;
  organization: {
    id: string;
    name: string;
  } | null;
  organization_id: string;
  published: 'not_published' | 'pinned' | 'published';
  specialties: string[];
  updated_at: string;
}

export interface UpdateCourseCollectionParams {
  course_collection: RequireAtLeastOne<{
    authors: string;
    communities: ScoreType[];
    course_ids: string[];
    credits: number | string | null;
    description: string;
    external_accreditation_references_attributes: (
      | {
          _destroy: true;
          id: string;
        }
      | {
          id?: string;
          vendor_name: VendorNames;
          vendor_resource_id: string;
        }
    )[];
    financial_disclosures_attributes: (
      | {
          _destroy: true;
          id: string;
        }
      | {
          disclosure_text: string;
          id?: string;
        }
    )[];
    level: 'advanced' | 'beginner' | 'foundation' | 'intermediate' | null;
    name: string;
    organization: {
      id: string;
      name: string;
    } | null;
    organization_id: string;
    published: 'not_published' | 'pinned' | 'published';
    specialties: string[];
  }>;
}

export type VendorNames =
  | 'NBCC'
  | 'nysed_mental_health_counseling'
  | 'nysed_psychology'
  | 'nysed_social_work'
  | 'weitzman_institute';

export const createCourseCollection = (params: CreateCourseCollectionParams) =>
  instance
    .post<
      ApiResponse<CreateCourseCollectionResponse>,
      AxiosResponse<ApiResponse<CreateCourseCollectionResponse>>,
      CreateCourseCollectionParams
    >('/admin/course_collections', params)
    .then(res => res.data);

export const getCourseCollection = (id: string) =>
  instance
    .get<ApiResponse<GetCourseCollectionResponse>>(`/admin/course_collections/${id}`)
    .then(res => res.data);

export const getCourseCollections = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetCourseCollectionsResponse[], IndexPageMeta>>('/admin/course_collections', {
      params: {
        ...getParams,
        page,
        per_page: pageSize
      }
    })
    .then(res => res.data);

export const updateCourseCollection = (id: string, params: UpdateCourseCollectionParams) =>
  instance.patch<'', AxiosResponse<''>, UpdateCourseCollectionParams>(
    `/admin/course_collections/${id}`,
    params
  );
