import { Card, FormProps } from 'antd';
import { createCourse } from 'api';
import { CardHeader } from 'components/cards';
import { useNavigate } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { CourseForm } from './course_form';

export const CourseCreatePage = () => {
  const navigate = useNavigate();

  const onFinish: FormProps<{
    author?: string;
    bipoc_communities?: string[];
    ce_accrediting_associations?: string[];
    ce_credit_types?: string[];
    ce_credits: string;
    ce_provider?: string;
    content_medium: string;
    description: string;
    external_accreditation_references?: {
      vendor_name: string;
      vendor_resource_id: string;
    }[];
    financial_disclosures?: {
      disclosure_text: string;
    }[];
    format_category: string;
    full_title: string;
    group_training?: boolean;
    hours: string;
    learning_objectives?: {
      name: string;
    }[];
    lgbq_communities?: string[];
    link: string;
    organization_id?: {
      key: string;
      label: string;
      value: string;
    } | null;
    organization_name: string;
    professional_groups?: string[];
    published?: boolean;
    scormcloud_id?: string;
    short_title: string;
    tgnc_communities?: string[];
    year?: string;
  }>['onFinish'] = ({ financial_disclosures, learning_objectives, organization_id, ...values }) =>
    createCourse({
      course: {
        ...values,
        financial_disclosures,
        learning_objectives_attributes: learning_objectives,
        organization_id:
          organization_id === undefined
            ? ' '
            : organization_id === null
            ? null
            : organization_id.value
      }
    }).then(({ data }) => {
      data?.id !== undefined && navigate(paths.coursePath(data.id));
    });

  return (
    <Card title={<CardHeader title="Create course" />}>
      <CourseForm
        name="course-create"
        onFinish={onFinish}
      />
    </Card>
  );
};
