import { Card, Table, Tag } from 'antd';
import {
  getCourseCollection,
  getCourseCollectionCompletions,
  GetCourseCollectionResponse,
  useFetch
} from 'api';
import { CardHeader } from 'components/cards';
import { CardEntryList } from 'components/cards/card_entry_list';
import { UserLink } from 'components/common';
import { DataTable } from 'components/tables';
import { courseColumns } from 'pages/courses/course_components';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { PlainCard, PlainList } from 'styles/show_page_styles';
import * as paths from 'utils/paths';

import { CollectionPublishedTag } from './course_collection_components';

export const CourseCollectionPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(getCourseCollection, [id]);

  return (
    <div className="site-card-border-less-wrapper">
      {data?.data && (
        <Card
          headStyle={{ fontWeight: 'bold' }}
          style={{ minHeight: 'calc(100vh - 111px)' }}
          title={
            <CardHeader
              editPath={paths.courseCollectionEditPath(id)}
              title={`Collection ${data.data.name}`}
            />
          }
        >
          <CardEntryList
            fieldsMap={[
              { key: 'id', title: 'ID' },
              { key: 'name', title: 'Name' },
              {
                key: 'published',
                render: (published: GetCourseCollectionResponse['published']) => (
                  <CollectionPublishedTag publishedStatus={published} />
                ),
                title: 'Published status'
              },
              {
                key: 'organization',
                render: (org: GetCourseCollectionResponse['organization']) =>
                  org !== null ? <Link to={paths.organizationPath(org.id)}>{org.name}</Link> : '--',
                title: 'Access restriction'
              },
              {
                key: 'authors',
                title: 'Author(s)'
              },
              { key: 'description', title: 'Description' },
              {
                key: 'level',
                title: 'Level'
              },
              {
                key: 'specialties',
                render: (specialties: GetCourseCollectionResponse['specialties']) => (
                  <>
                    {specialties.map(specialty => (
                      <Tag key={specialty}>{specialty}</Tag>
                    ))}
                  </>
                ),
                title: 'Specialties'
              },
              {
                key: 'learning_objectives',
                render: (
                  learning_objectives: GetCourseCollectionResponse['learning_objectives']
                ) => (
                  <PlainList>
                    {learning_objectives.map((o, i) => (
                      <li key={i}>{o}</li>
                    ))}
                  </PlainList>
                ),
                title: 'Learning objectives'
              },
              {
                key: 'hours',
                title: 'Hours'
              },
              { key: 'credits', title: 'Credits' },
              {
                key: 'communities',
                render: (communities: GetCourseCollectionResponse['communities']) => {
                  const colorList = {
                    bipoc: 'blue',
                    lgbq: 'purple',
                    tgnc: 'pink'
                  };
                  const tagList = communities.map(community => (
                    <Tag color={colorList[community]}>{community}</Tag>
                  ));
                  return tagList;
                },
                title: 'Communities'
              },
              { key: 'created_at', title: 'Created at', type: 'date' },
              { key: 'updated_at', title: 'Updated at', type: 'date' }
            ]}
            values={data.data as Required<typeof data.data>}
          />
          <PlainCard
            bordered={false}
            title="Financial disclosures"
          >
            <Table
              columns={[
                {
                  dataIndex: 'disclosure_text',
                  key: 'disclosure_text',
                  title: 'Disclosure'
                }
              ]}
              dataSource={data.data.financial_disclosures}
              pagination={false}
            />
          </PlainCard>
          <PlainCard
            bordered={false}
            title="External accreditation references"
          >
            <Table
              columns={[
                {
                  dataIndex: 'vendor_name',
                  key: 'vendor_name',
                  title: 'Vendor name'
                },
                {
                  dataIndex: 'vendor_resource_id',
                  key: 'vendor_resource_id',
                  title: 'Vendor resource ID'
                }
              ]}
              dataSource={data.data.external_accreditation_references}
              pagination={false}
            />
          </PlainCard>
          <PlainCard
            bordered={false}
            title="Courses"
          >
            <Table
              columns={courseColumns}
              dataSource={data.data.courses}
              pagination={false}
            />
          </PlainCard>
          <PlainCard
            bordered={false}
            title="Completions"
          >
            <DataTable
              columns={[
                {
                  dataIndex: 'user_id',
                  key: 'user_id',
                  render: (_user_id: string, record) => <UserLink user={record.user} />,
                  title: 'User'
                },
                {
                  dataIndex: 'started_at',
                  key: 'started_at',
                  sorter: true,
                  title: 'Started at'
                },
                {
                  dataIndex: 'completed_at',
                  key: 'completed_at',
                  sorter: true,
                  title: 'Completed at'
                },
                {
                  dataIndex: 'completed_courses_count',
                  key: 'completed_courses_count',
                  render: (completed_courses_count: number) => completed_courses_count || 0,
                  title: 'Completed courses count'
                }
              ]}
              getMethod={getCourseCollectionCompletions}
              getParams={{ course_collection_id: id }}
            />
          </PlainCard>
        </Card>
      )}
    </div>
  );
};
