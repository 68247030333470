import { Button, Card, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { getOrganization, useFetch } from 'api';
import { StyledTitle } from 'components/cards';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router';

interface ReportRows {
  id: string;
  title: string;
  url: string;
}

export const OrganizationReportsPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(getOrganization, [id]);
  const [reportRows, setReportRows] = useState<ReportRows[]>([]);
  const [, setCookie] = useCookies(['admin-auth-token']);

  const goToUserApp = (url: string) => () => {
    const token = localStorage.getItem('adminToken');
    const date = new Date();
    // expire: 1 day * 24 hrs * 60 mins * 60 secs * 1000 ms
    date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
    setCookie('admin-auth-token', token, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: date,
      path: '/'
    });

    window.location.href = `${process.env.REACT_APP_BASE_URL}/${url}`;
  };

  const reportColumns: ColumnsType<ReportRows> = [
    {
      dataIndex: 'title',
      key: 'title',
      title: 'Report Name',
      width: '40%'
    },
    {
      dataIndex: 'url',
      key: 'url',
      render: (value: ReportRows['url']) => (
        <Button
          onClick={goToUserApp(value)}
          type="primary"
        >
          View
        </Button>
      ),
      title: 'Actions'
    }
  ];

  useEffect(() => {
    if (!data || reportRows.length > 0) {
      return;
    }
    const rows: ReportRows[] = [];
    if (data.data?.network === true) {
      rows.push({
        id: 'network-overview',
        title: 'Network Overview',
        url: `reports/network/${data.data.id}`
      });
    }
    if (data.data !== undefined && !data.data.network) {
      rows.push({
        id: 'inclusivity-scorecard',
        title: 'Inclusivity Scorecard',
        url: `reports/provider/${data.data.id}/scorecard`
      });
    }
    setReportRows(rows);
  }, [data, reportRows]);

  return (
    <Card
      bordered={false}
      title={<StyledTitle title={`${data?.data?.name} Reports`} />}
    >
      <Table
        bordered
        columns={reportColumns}
        dataSource={reportRows}
        pagination={false}
        rowKey="id"
        style={{ width: '100%' }}
      />
    </Card>
  );
};
