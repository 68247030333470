import type { AxiosResponse } from 'axios';
import type { RequireAtLeastOne } from 'type-fest';

import { instance } from '.';

interface CreateNetworkParams {
  network: {
    name: string;
    organization_manager_ids?: string[];
    organization_member_ids?: string[];
  };
}

export interface GetNetworkAnalyticsResponse {
  data: {
    id: string;
    total_onboarded_providers: number;
    total_organization_members: number;
    total_providers: number;
  };
}

export interface GetNetworkResponse {
  created_at: string;
  id: string;
  name: string;
  organization_managers: {
    created_at: string;
    id: string;
    name: string;
  }[];
  organization_members: {
    created_at: string;
    direct_contract: boolean;
    id: string;
    name: string;
  }[];
  total_onboarded_providers: number;
  total_organization_members: number;
  total_providers: number;
  updated_at: string;
}

export interface GetNetworksResponse {
  created_at: string;
  id: string;
  name: string;
  total_organization_members: number;
  updated_at: string;
}

interface UpdateNetworkParams {
  network: RequireAtLeastOne<{
    name: string;
    organization_manager_ids: string[];
    organization_member_ids: string[];
  }>;
}

export const createNetwork = (params: CreateNetworkParams) =>
  instance.post<'', AxiosResponse<''>, CreateNetworkParams>('/admin/networks', params);

export const deleteNetwork = (id: string) => instance.delete<''>(`/admin/networks/${id}`);

export const getNetwork = (id: string) =>
  instance.get<ApiResponse<GetNetworkResponse>>(`/admin/networks/${id}`).then(res => res.data);

export const getNetworkAnalytics = (id: string) =>
  instance
    .get<GetNetworkAnalyticsResponse>(`/admin/networks/${id}/analytics`)
    .then(res => res.data);

export const getNetworks = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetNetworksResponse[], IndexPageMeta>>('/admin/networks', {
      params: {
        ...getParams,
        page,
        per_page: pageSize
      }
    })
    .then(res => res.data);

export const updateNetwork = (id: string, params: UpdateNetworkParams) =>
  instance.patch<'', AxiosResponse<''>, UpdateNetworkParams>(`/admin/networks/${id}`, params);
