import { NetworkCreatePage, NetworkEditPage, NetworkPage, NetworksPage } from 'pages/networks';
import { BookmarkNetworkAssignmentEditPage } from 'pages/networks/bookmark_network_assignment_edit_page';
import { BookmarkNetworkAssignmentPage } from 'pages/networks/bookmark_network_assignment_page';
import { RouteConfig } from 'react-router-config';
import * as paths from 'utils/paths';

export const NetworkRoutes: RouteConfig[] = [
  {
    breadcrumb: 'Networks',
    component: NetworksPage,
    exact: true,
    path: paths.networksPath
  },
  {
    component: NetworkCreatePage,
    path: paths.networkCreatePath
  },
  {
    component: NetworkPage,
    exact: true,
    path: paths.networkPath(':id')
  },
  {
    component: NetworkEditPage,
    path: paths.networkEditPath(':id')
  },
  {
    component: BookmarkNetworkAssignmentPage,
    path: paths.bookmarkNetworkAssignmentPath(':id')
  },
  {
    component: BookmarkNetworkAssignmentEditPage,
    path: paths.bookmarkNetworkAssignmentEditPath(':networkId', ':id')
  }
];
