import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

interface BooleanIconProps {
  value: boolean;
}
export const BooleanIcon = ({ value }: BooleanIconProps) =>
  Boolean(value) ? (
    <CheckOutlined style={{ color: 'green' }} />
  ) : (
    <CloseOutlined style={{ color: 'red' }} />
  );
