import { Card } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { deleteTag, GetTagResponse, getTagsFull } from 'api';
import { StyledTitle } from 'components/cards';
import { DataTable } from 'components/tables';

const columns: ColumnsType<GetTagResponse> = [
  {
    dataIndex: 'type',
    key: 'type',
    title: 'Type'
  },
  {
    dataIndex: 'name',
    key: 'name',
    title: 'Name'
  }
];

export const TagsPage = () => (
  <Card
    bordered={false}
    title={<StyledTitle title="Course tags" />}
  >
    <DataTable
      batchActions={[
        {
          label: 'Delete selected',
          onClick: ids => Promise.all(ids.map(id => deleteTag(id)))
        }
      ]}
      columns={columns}
      filters={[
        {
          key: 'type',
          label: 'Type',
          options: [
            { text: '', value: undefined },
            { text: 'Bipoc communities', value: 'bipoc_communities' },
            { text: 'Lgbq communities', value: 'lgbq_communities' },
            { text: 'Tgnc communities', value: 'tgnc_communities' },
            { text: 'Other communities', value: 'other_communities' },
            { text: 'Topic area tags', value: 'topic_area_tags' },
            {
              text: 'CE accrediting associations',
              value: 'ce_accrediting_associations'
            },
            { text: 'Specialties', value: 'specialties' },
            { text: 'Professional groups', value: 'professional_groups' }
          ]
        },
        {
          key: 'name',
          label: 'Name'
        }
      ]}
      getMethod={getTagsFull}
      perPage={50}
    />
  </Card>
);
